import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAuth, signInAnonymously } from "firebase/auth";

const initialState = {
  user: {
    username: null,
    email: null,
    uid: null,
    displayName: null,
    photoURL: null,
  },
  anonymousUser: null,
  status: "idle",
  error: null,
  isInitialPageload: false,
};

export const signInAnon = createAsyncThunk(
  "user/signInAnon",
  async (_, { rejectWithValue }) => {
    const auth = getAuth();
    try {
      const userCredential = await signInAnonymously(auth);
      return userCredential.user;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    //sign in related
    loginPending: (state) => {
      state.status = "loading";
      state.error = null;
    },
    loginFulfilled: (state, action) => {
      state.isInitialPageload = true;
      state.user = {
        ...state.user,
        uid: action.payload.uid,
        email: action.payload.email,
        displayName: action.payload.displayName,
        photoURL: action.payload.photoURL,
        username: action.payload.username,
      };
      state.status = "succeeded";
      state.error = null;
    },
    loginRejected: (state, action) => {
      state.user = state.anonymousUser; // Revert to anonymous user
      state.status = "failed";
      state.error = action.payload;
    },
    setIsInitialPageload: (state, action) => {
      state.isInitialPageload = action.payload;
    },
    // logout: (state) => {
    //     state.user = null;
    //     state.status = 'idle';
    //     state.error = null;
    // },
    logout: (state) => {
      state.user = state.anonymousUser; // Revert to anonymous user
      state.status = "idle";
      state.error = null;
    },
    //signup related
    signUpPending: (state) => {
      state.status = "loading";
    },
    signUpFulfilled: (state, action) => {
      state.user = {
        ...state.user,
        uid: action.payload.uid,
        email: action.payload.email,
        displayName: action.payload.displayName,
        photoURL: action.payload.photoURL,
        username: action.payload.username,
      };
      state.status = "succeeded";
    },
    signUpRejected: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    updateUserInfo: (state, action) => {
      const { username, uid } = action.payload;
      state.user = { ...state.user, username, uid };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInAnon.pending, (state) => {
        state.status = "loading";
      })
      .addCase(signInAnon.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
        state.anonymousUser = action.payload; // Store the anonymous user
      })
      .addCase(signInAnon.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const {
  loginPending,
  loginFulfilled,
  loginRejected,
  setIsInitialPageload,
  logout,
  signUpPending,
  signUpFulfilled,
  signUpRejected,
  updateUserInfo,
} = userSlice.actions;
export default userSlice.reducer;
