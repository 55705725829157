import React, { createContext, useState, useEffect, useContext } from "react";
import { getAuth, onAuthStateChanged, signInAnonymously } from "firebase/auth";
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(undefined);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        // If no user is authenticated, sign in anonymously
        signInAnonymously(auth)
          .catch((error) => {
            console.error("Error signing in anonymously:", error);
          })
          .then((userCredential) => {
            console.log("Signed in anonymously. uid:", userCredential.user.uid);
            setUser(userCredential.user);
          });
      }
    });

    return () => unsubscribe();
  }, [auth]);

  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext(AuthContext);
