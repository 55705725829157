import React from "react";
import { useContext } from "react";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AuthContext } from "../authContext";
import { deleteFromList, fetchList } from "../../redux/listsSlice";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Button } from "../ui/button";
import CircularProgress from "@mui/material/CircularProgress";

const ListRow = ({ listName, list, layout }) => {
  const { username } = useSelector((state) => state.user.user);
  const user = useContext(AuthContext);
  const navigate = useNavigate();
  const params = useParams();
  const type = params.type;

  const isFetching = useSelector((state) => state.lists.isFetching);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(isFetching);
  }, [isFetching]);

  const handleCardClick = (type, docId) => {
    let path;
    switch (type) {
      case "movies":
        path = `/MovieDetail/${docId}`;
        break;
      case "tvs":
        path = `/TVDetail/${docId}`;
        break;
    }
    navigate(path);
  };
  const handleListClick = () => {
    const op = listName.toLowerCase();
    navigate(`/user/${encodeURIComponent(username)}/${user.uid}/${type}/${op}`);
  };

  // may need in future versions
  // const handleDelete = async (doc, listName) => {
  //   await dispatch(deleteFromList({ doc, listName }));
  //   await dispatch(fetchLikeTvs(user));
  //   await dispatch(fetchDislikeTvs(user));
  //   await dispatch(fetchWishlistTvs(user));
  // };

  return (
    <div className="list-row-page-wrapper">
      <div className="flex flex-row items-end justify-between border-b-2 border-orange-500 mx-4 p-0 leading-none">
        <div className="flex items-end font-rubik-sans font-medium text-black">
          {listName.toUpperCase()}
        </div>
        <Button
          className="flex items-end text-[10px] p-0 font-rubik-sans font-medium text-black"
          onClick={handleListClick}
          variant="ghost"
        >
          <div className="text-[12px]">
            View More
            <ArrowForwardIcon
              sx={{ height: "14px", width: "14px", marginBottom: "2px" }}
            />
          </div>
        </Button>
      </div>
      <div
        className={
          layout === "row"
            ? "flex flex-row items-center justify-start p-3"
            : "flex flex-wrap items-start justify-start"
        }
      >
        {loading ? (
          <div className="flex justify-center w-full p-6">
            <CircularProgress style={{ color: "#FFA500" }} />
          </div>
        ) : list?.length > 0 ? (
          list
            ?.slice(-4)
            .reverse()
            .map((card) => (
              <div
                key={card.id}
                className="flex items-center font-rubik-sans font-medium w-24 m-1"
              >
                {card?.cover_photo_url && (
                  <img
                    onClick={() => handleCardClick(card?.type, card?.id)}
                    src={card?.cover_photo_url}
                    alt={card?.name}
                    className="rounded-lg"
                  />
                )}
              </div>
            ))
        ) : (
          <div className="flex flex-col items-center justify-center w-full p-6 text-rubik-sans text-gray-600 text-[10px] font-semibold min-h-[120px] m-1">
            <div>
              You currently don't have any{" "}
              {listName.endsWith("s")
                ? listName.toLowerCase().slice(0, -1) + "d"
                : listName.toLowerCase()}{" "}
              listings.
            </div>

            <div>
              View{" "}
              <Button
                className="text-orange-500 font-semibold underline underline-offset-1 text-[10px] p-0"
                onClick={() => navigate("/")}
                variant="ghost"
              >
                Homepage
              </Button>{" "}
              to add more.
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ListRow;
