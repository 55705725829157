import React, { useState, useEffect, useRef } from "react";
import CarouselCard from "./CarouselCard";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Onboarding from "src/components/Onboarding/Onboarding";
import { Button } from "../ui/button";
import { useDispatch, useSelector } from "react-redux";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  handleRefreshClick,
  fetchRecommendations,
  setIsPaywallLoading,
  setOnboardingType,
} from "src/redux/onboardingSlice";
import "./Carousel.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MOVIE_TYPE } from "src/const";
import { useAuthContext } from "../authContext";

function LeftArrow(props) {
  const { style, onClick } = props;
  const [backgroundColor, setBackgroundColor] = useState("#FFFFFF");

  const handleClick = (e) => {
    setBackgroundColor("#F78F20");
    setTimeout(() => {
      setBackgroundColor("#FFFFFF");
    }, 1000);
    onClick();
  };

  return (
    <div
      style={{
        ...style,
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        display: "flex",
        opacity: onClick ? 1 : 0,
        pointerEvents: onClick ? "auto" : "none",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        fontSize: "20px",
        color: "#000000",
        fontWeight: "900",
        zIndex: "1",
        position: "absolute",
        top: "50%",
        left: 0,
        transform: "translateY(-50%)",
        transition: "background-color 0.3s ease, opacity 0.2s ease",
        backgroundColor: backgroundColor,
      }}
      onClick={handleClick}
    >
      <ArrowBackIcon fontSize="medium" />
    </div>
  );
}

function RightArrow(props) {
  const { style, onClick } = props;
  const [backgroundColor, setBackgroundColor] = useState("#FFFFFF");

  const handleClick = (e) => {
    setBackgroundColor("#F78F20");
    setTimeout(() => {
      setBackgroundColor("#FFFFFF");
    }, 500);
    onClick();
  };

  return (
    <div
      style={{
        ...style,
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        display: "flex",
        opacity: onClick ? 1 : 0,
        pointerEvents: onClick ? "auto" : "none",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        fontSize: "20px",
        color: "#000000",
        fontWeight: "900",
        zIndex: "1",
        position: "absolute",
        top: "50%",
        right: 0,
        transform: "translateY(-50%)",
        transition: "background-color 0.3s ease, opacity 0.2s ease",
        backgroundColor: backgroundColor,
      }}
      onClick={handleClick}
    >
      <ArrowForwardIcon fontSize="medium" />
    </div>
  );
}

const RecommendationCarousel = () => {
  const navigate = useNavigate();
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isRefreshClicked, setIsRefreshClicked] = useState(false);
  const slider = useRef();
  const user = useAuthContext();

  const {
    movieRecommendations,
    tvRecommendations,
    onboardingType,
    isPaywallLoading,
    pageNumber,
    movies,
    tvs,
  } = useSelector((state) => state.onboarding);
  const dispatch = useDispatch();

  const defaultRecommendations = [
    {
      favID: 0,
      name: "Dune: Part Two",
      imageUrl:
        "https://www.themoviedb.org/t/p/original/8b8R8l88Qje9dn9OE8PY05Nxl1X.jpg",
      average_rating: " ",
    },
    {
      favID: 1,
      name: "Poor Things",
      imageUrl:
        "https://www.themoviedb.org/t/p/original/jV3c2fsBNCJgcesxdNM9O0lwwdT.jpg",
      average_rating: " ",
    },
    {
      favID: 2,
      name: "Dune",
      imageUrl:
        "https://www.themoviedb.org/t/p/original/d5NXSklXo0qyIYkgV94XAgMIckC.jpg",
      average_rating: " ",
    },
    {
      favID: 3,
      name: "Argylle",
      imageUrl:
        "https://www.themoviedb.org/t/p/original/siduVKgOnABO4WH4lOwPQwaGwJp.jpg",
      average_rating: " ",
    },
  ];

  const settings = {
    dots: false,
    speed: 200,
    slidesToShow: 5.7,
    slidesToScroll: 4,
    touchThreshold: 20,
    draggable: true,
    lazyLoad: false,
    infinite: false,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,
    responsive: [
      {
        breakpoint: 1900,
        settings: {
          slidesToShow: 4.7,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 2,
          lazyLoad: false,
          nextArrow: <></>,
          prevArrow: <></>,
        },
      },
    ],
  };

  const handleCardClick = (docID) => {
    onboardingType === MOVIE_TYPE
      ? navigate(`/MovieDetail/${docID}`)
      : navigate(`/TVDetail/${docID}`);
  };

  const handleRecRefresh = async () => {
    setIsRefreshClicked(!isRefreshClicked);
    const newPageNumber = pageNumber + 1;
    dispatch(handleRefreshClick(newPageNumber));
    const transformedItems = (onboardingType === MOVIE_TYPE ? movies : tvs).map(
      (item) => ({
        id: item.id,
        comment: item.text,
      })
    );
    setLoading(true);
    dispatch(setIsPaywallLoading(true));
    await dispatch(
      fetchRecommendations({
        favs: transformedItems,
        type: onboardingType,
        page: newPageNumber,
        limit: 8,
      })
    );
    setLoading(false);
    dispatch(setIsPaywallLoading(false));
  };

  useEffect(() => {
    if (onboardingType === MOVIE_TYPE && movieRecommendations?.length > 0) {
      setCards(movieRecommendations);
    } else if (onboardingType === "TV" && tvRecommendations?.length > 0) {
      setCards(tvRecommendations);
    } else {
      setCards(defaultRecommendations);
      handleRecRefresh();
    }
  }, [onboardingType, movieRecommendations, tvRecommendations]);

  return (
    <div
      style={{
        width: "100%",
        overflowX: "hidden",
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "0px",
          marginTop: "0px",
          marginRight: "2vw",
          padding: "10px",
          position: "relative",
          zIndex: "10",
        }}
        className="bg-orange-100 p-5 "
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <h2 className="carousel-header">Just for you</h2>
        </div>
        <div className="flex flex-row items-center">
          <Button
            disabled={isPaywallLoading}
            onClick={() => dispatch(setOnboardingType(MOVIE_TYPE))}
            className={`h-0 px-3 py-2.5 mr-2.5 text-xs rounded-2xl bg-gray-300 text-black transform ${
              onboardingType === MOVIE_TYPE
                ? "bg-orange-700 text-white scale-110 uppercase"
                : "scale-100"
            } hover:bg-orange-700 transition duration-300 mobile:text-xs mobile:px-3 mobile:mr-2 tablet:px-5 tablet:py-3 laptop:text-base`}
            style={{ boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)" }}
          >
            MOVIES
          </Button>
          <Button
            disabled={isPaywallLoading}
            onClick={() => dispatch(setOnboardingType("TV"))}
            className={`h-0 px-3 py-2.5 text-xs rounded-2xl bg-gray-300 text-black transform ${
              onboardingType === "TV"
                ? "bg-orange-700 text-white scale-110 uppercase "
                : "scale-100"
            } hover:bg-orange-700 transition duration-300 mobile:text-xs mobile:px-3 tablet:px-5 tablet:py-3 laptop:text-base`}
            style={{ boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)" }}
          >
            TV SHOWS
          </Button>
        </div>
      </div>
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <Slider key={cards.length} ref={slider} {...settings}>
            {cards.map((card, index) => (
              <div key={index}>
                <CarouselCard
                  {...card}
                  onClick={() => handleCardClick(card.id)}
                  isLocked={
                    onboardingType === MOVIE_TYPE
                      ? movieRecommendations?.length === 0
                      : tvRecommendations?.length === 0
                  }
                  onboardingType={onboardingType}
                  type="recommend"
                  isRefreshClicked={isRefreshClicked}
                />
              </div>
            ))}
          </Slider>
        </div>
        {onboardingType && (
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20 ">
            <Onboarding />
          </div>
        )}
      </div>
      {onboardingType &&
        ((onboardingType === MOVIE_TYPE && movieRecommendations.length > 0) ||
          (onboardingType === "TV" && tvRecommendations.length > 0)) && (
          <div className="text-center">
            <button
              className="bg-orange-500 w-3/5 rounded-full font-bold text-2xl tracking-widest p-0.5 shadow-md my-2"
              disabled={loading}
              onClick={handleRecRefresh}
            >
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress
                    style={{
                      color: "#FFF",
                      height: "30px",
                      width: "30px",
                      marginRight: "8px",
                    }}
                  />
                </div>
              ) : (
                <span>Reroll</span>
              )}
            </button>
          </div>
        )}
    </div>
  );
};

export default RecommendationCarousel;
