import React, { useEffect, useState } from 'react';
import { restaurantSearch } from '../firebase/fireBaseInit';
import useUserLocation from '../hooks/useUserLocation';
import { fetchAddressFromCoords } from '../contexts/coordToAddress';

const TestRestaurantSearch = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const userLocation = useUserLocation();
  const [address, setAddress] = useState(userLocation ? `${userLocation.latitude},${userLocation.longitude}` : '');

  const fetchData = async () => {
    try {
      const result = await restaurantSearch({
        searchbar: 'restaurant',
        location: address
      });
      console.log("result is returned here: ",result);
      setData(result.data);
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    if (userLocation) {
      const { latitude, longitude } = userLocation;
      
      fetchAddressFromCoords(latitude, longitude)
        .then(response => {
          if (response && response.address) {
            setAddress(response.address);
          } else {
            console.error("Failed to fetch address from coords:", response);
          }
        })
        .catch(error => {
          console.error("Error fetching address from coords:", error);
        });
    }
  }, [userLocation]);

  useEffect(() => {
    if (address) {
      fetchData();
    }
  }, [address]);

  return (
    <div>
      <h1>Test Restaurant Search Results</h1>

      {error && (
        <div>
          <h2>Error</h2>
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </div>
      )}

      {data && (
        <div>
          <h2>Data</h2>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default TestRestaurantSearch;
