import React, { useState, useContext, useEffect, useMemo } from "react";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import "@fontsource/rubik/400.css";
import "@fontsource/rubik/500.css";
import IMDBLogo from "../../logos/IMDB-L.png";
import LetterboxdLogo from "../../logos/letterboxd-L.png";
import RottenTomatoesLogo from "../../logos/tomatoes-L.png";
import RottenTomatoesAudienceLogo from "../../logos/tomatoes_audience-L.png";
import StarRatingDisplayMovie from "./StarRatingDisplayMovie";
import { AuthContext } from "../authContext";
import BookmarkDropdown from "./BookmarkDropdown";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "src/components/ui/card";
import { Badge } from "src/components/ui/badge";
import { ThumbDownOutlined, ThumbUpAltOutlined } from "@mui/icons-material";
import { addLike } from "../../redux/listsSlice";
import RatingBar from "./RatingBar";
import UserActionButtons from "./UserActionButtons";

const ListingCard = ({
  docID,
  title,
  categories = [],
  description,
  imageUrl,
  year,
  duration,
  type,
  ratings,
  seasons,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isInBookmarks, setIsInBookmarks] = useState(false);
  const dispatch = useDispatch();
  const user = useContext(AuthContext);
  const navigate = useNavigate();

  const imdbRating = ratings?.IMDB?.average_rating;
  const rottenTomatoesMeter = ratings?.RottenTomatoe?.tomatometer;
  const rottenTomatoesAudience = ratings?.RottenTomatoe?.audience_rating;
  const letterboxdRating = ratings?.Letterboxd?.average_rating;
  const averageScore = ratings?.Average?.average_rating;
  const total = ratings?.Average?.total_reviews;
  const bookmarks = useSelector((state) => state.bookmarks.bookmarks);

  useEffect(() => {
    const isInBookmarks = bookmarks.includes(docID);
    setIsInBookmarks(isInBookmarks);
  }, [bookmarks]);

  const mappedCategories = useMemo(() => {
    if (categories && categories.length > 0) {
      return categories?.map((label) => (
        <Badge className="mr-2 rounded-xl" variant="outline" key={label}>
          {label}
        </Badge>
      ));
    }
  }, [categories]);

  const addToListHandler = (op) => {
    dispatch(
      addLike({
        user,
        docID,
        title,
        description,
        imageUrl,
        year,
        type,
        ratings,
        categories,
        op,
      })
    );
  };

  const handleCardClick = (id) => {
    let path;
    if (type === "movies") {
      path = `/MovieDetail/${id}`;
    } else if (type === "tvs" || type === "TVs") {
      path = `/TVDetail/${id}`;
    }
    navigate(path);
  };

  const getDurationString = (duration) => {
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;
    return `${hours}h ${minutes}m`;
  };

  const authContext = useContext(AuthContext);
  const isAnonymous = authContext && authContext.isAnonymous;
  const uid = authContext?.uid;
  const isAuthenticated = uid && !isAnonymous;

  const handleBookmarkClick = () => {
    if (isAuthenticated) {
      setIsDropdownOpen((prevState) => !prevState);
    } else {
      alert("Please sign in to bookmark!");
      window.location.href = "http://cribit.xyz/Signup";
    }
  };

  const renderOverview = () => {
    if (!description) return "";

    const characterLimit = 250;
    const trimmedText = description.slice(0, characterLimit);

    if (description.length > characterLimit) {
      const lastSpaceIndex = trimmedText.lastIndexOf(" ");
      return trimmedText.slice(0, lastSpaceIndex) + "...";
    }

    return trimmedText;
  };

  const renderRating = (rating, type = "default") => {
    if (rating !== null && rating !== undefined) {
      if (type === "IMDB") {
        return (
          <>
            <span>{rating}</span>
            <span className="text-gray-500">/10</span>
          </>
        );
      }
      if (type === "letterboxd") {
        return (
          <>
            <span>{rating}</span>
            <span className="text-gray-500">/5</span>
          </>
        );
      } else if (type === "RottenTomatoes") {
        return <span>{rating}%</span>;
      } else {
        return (
          <>
            <span>{rating}</span>
            <span className="text-gray-500">%</span>
          </>
        );
      }
    }
    return <span>N/A</span>;
  };
  return (
    <Card className="flex max-w-full m-auto relative w-[970px] p-[24px] transition-transform duration-300 ease-in-out">
      {imageUrl && (
        <div className="rounded-[10px]">
          <img
            className="min-w-[240px] max-w-[240px] h-full object-cover rounded-[10px]"
            src={imageUrl}
            alt="Card"
            onMouseEnter={(e) => {
              e.target.style.transform = "scale(1.05)";
            }}
            onMouseLeave={(e) => {
              e.target.style.transform = "scale(1)";
            }}
            onClick={() => {handleCardClick(docID)}}
          />
        </div>
      )}
      <div className="w-full">
        <div className="flex flex-col justify-between p-4">
          <CardHeader className="grid grid-cols-[1fr_110px] items-baseline w-full">
            <div className="space-y-1 max-w-[95%]">
              <CardTitle
                className="m-0 text-[32px] cursor-pointer"
                onClick={() => {
                  handleCardClick(docID);
                }}
              >
                {title}
              </CardTitle>
              {type === "movies" ? (
                <CardDescription className="text-gray-500 text-base mt-2">
                  {year === "N/A" || year === "" ? "" : `${year} · `}

                  {getDurationString(duration)}
                </CardDescription>
              ) : (
                <CardDescription className="text-gray-500 text-base mt-2">
                  {year === "N/A" || year === "" ? "" : `${year} · `}
                  {`${seasons} ${seasons > 1 ? "seasons" : "season"}`}
                </CardDescription>
              )}
              <div className="flex flex-wrap items-center">
                {type === "movies" ? (
                  <Badge className="mr-2 bg-white text-black border-orange-700">
                    Movie
                  </Badge>
                ) : (
                  <Badge className="mr-2 bg-white text-black border-orange-700">
                    TV
                  </Badge>
                )}
                {mappedCategories}
              </div>
            </div>
            <UserActionButtons id={docID} title={title} type={type} />
          </CardHeader>
          <CardContent>
            <p className="font-normal mb-2 text-base">{renderOverview()}</p>
            <RatingBar avgRating={averageScore} />

            <div className="flex flex-wrap items-center">
              <img
                src={IMDBLogo}
                alt="IMDB"
                className="w-[50px] h-[40px] mr-4"
              />
              {renderRating(imdbRating, "IMDB")}
              <span className="mx-5 text-gray-500 text-2xl">|</span>
              <img
                src={LetterboxdLogo}
                alt="Letterboxd"
                className="w-[50px] h-[40px] mr-4"
              />
              {renderRating(letterboxdRating, "letterboxd")}
              <span className="mx-5 text-gray-500 text-2xl">|</span>
              <div className="flex items-center">
                <img
                  src={RottenTomatoesLogo}
                  alt="Rottentomatoes"
                  className="w-[40px] h-[40px] mr-4"
                />
                {renderRating(rottenTomatoesMeter, "Rottentomatoes")}
                <span className="mr-2"></span>
              </div>
              <div className="flex items-center">
                <img
                  src={RottenTomatoesAudienceLogo}
                  alt="Rottentomatoesaudience"
                  className="w-[40px] h-[40px] mr-4"
                />
                {renderRating(rottenTomatoesAudience, "Rottentomatoes")}
              </div>
            </div>
          </CardContent>
        </div>
      </div>
    </Card>
  );
};

export default ListingCard;
