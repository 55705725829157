import React, { useEffect, useState } from "react";
import { getDoc, doc, db } from "../../firebase/fireBaseInit"; // Ensure db is imported correctly

const CrewList = ({ directorIds, actorIds }) => {
  const [directors, setDirectors] = useState([]);
  const [actors, setActors] = useState([]);

  useEffect(() => {
    const fetchCrew = async () => {
      try {
        let directorNames = [];
        let actorNames = [];

        for (let id of directorIds) {
          const docRef = doc(db, "people", id.toString());
          const docSnapshot = await getDoc(docRef);
          if (docSnapshot.exists()) {
            directorNames.push(docSnapshot.data().name);
          }
        }

        for (let id of actorIds) {
          const docRef = doc(db, "people", id.toString());
          const docSnapshot = await getDoc(docRef);
          if (docSnapshot.exists()) {
            actorNames.push(docSnapshot.data().name);
          }
        }

        setDirectors(directorNames);
        setActors(actorNames);
      } catch (error) {
        console.error("Error fetching crew", error);
      }
    };

    if (directorIds && actorIds) {
      fetchCrew();
    }
  }, [directorIds, actorIds]);

  const chunkArray = (arr, chunkSize) => {
    const results = [];
    while (arr.length) {
      results.push(arr.splice(0, chunkSize));
    }
    return results;
  };

  const textStyle = {
    fontFamily: "Rubik",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "2",
    color: "#472A00"
  };

  return (
    <div className="w-full">
      <div style={textStyle}>
        <span className="font-bold">Director: </span>
        {directors.map((director, index) => (
          <span key={index}>{director}</span>
        ))}
      </div>
      <div style={textStyle}>
        <span className="font-bold">Cast: </span>
        {actors.slice(0, 10).map(
          (
            actor,
            index // Slice actors array to display maximum of 10 names
          ) => (
            <span key={index}>
              {actor}
              {index < 9 && ", "}
            </span>
          )
        )}
      </div>
    </div>
  );
};

export default CrewList;
