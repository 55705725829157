import React, { useState, useEffect } from 'react';
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import {Button} from '../ui/button';
import { Collapse, Paper, List, ListItem, Box, Typography, Chip, Select, MenuItem } from "@mui/material";
import { useContext } from 'react';
import LocationContext from '../../contexts/LocationContext';
import { useNavigate } from 'react-router-dom';
import { MOVIE_TYPE, TV_TYPE } from 'src/const';

const CenteredSearch = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "0px",
  backgroundColor: '#FFDFB1'
}));

const CenteredFilters = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "0px",
}));

const SearchContainer = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "30px",
  backgroundColor: 'white',
  borderColor: "#FFDFB1",
  borderWidth: "1px",
  borderStyle: "solid",
  marginLeft: "7.5%",
  marginRight: "7.5%",
  width: "85%",
  marginBottom: '2vh',
  maxWidth: 'auto',
  boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)'
}));

const Search = styled("div")(({ theme }) => ({
  borderRadius: "30px",
  backgroundColor: 'white',
  marginLeft: "0",
  marginRight: "0",
  width: "100%",
  height: '50px', // Set the height of the search bar
  display: "flex",
  flexDirection: "row", // Change to row layout
  alignItems: "center", // Vertically align items
}));

const SearchContent = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 2),
    paddingLeft: `calc(${theme.spacing(2)})`,  // Adjust this value to position the placeholder text
    paddingRight: theme.spacing(2),
    transition: theme.transitions.create('width'),
    width: '100%',
    textAlign: 'left',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      '&:focus': {
        width: '100%',
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 24px)',
      fontSize: '11px',

    }
  },
  flexGrow: 1,
}));


const DropdownPaper = styled(Paper)({
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  borderBottomLeftRadius: '30px',
  borderBottomRightRadius: '30px',
  marginTop: '-3px',
  borderColor: 'transparent'
});

const CustomSelect = styled(Select)(({ theme }) => ({
  borderTopLeftRadius: '30px', // Rounded corners on the left side
  borderBottomLeftRadius: '30px', // Rounded corners on the left side
  backgroundColor: 'transparent', // Make the background transparent
  width: 'auto',  // Allows dropdown width to adjust dynamically
  minWidth: '100px', // Min width to ensure wide enough
  height: '50px', // Match the height of the search bar
  borderLeft: 'none', // Remove the border from the left side
  borderTop: 'none', // Remove the border from the top side
  borderBottom: 'none', // Remove the border from the bottom side
  borderRight: '1px solid #FFDFB1', // Add a border to the right side
  "& .MuiSelect-root": {
    color: "#FF9500", // Font color to match the search bar border color
    width: '100%', // Adjust the width as needed
    height: '100%', // Match the height of the search bar
    display: 'flex',
    alignItems: 'center', // Vertically align the content
    justifyContent: 'center', // Horizontally center the content
    fontFamily: 'Rubik', // Adjust font family as needed
    fontWeight: '500', // Adjust font weight as needed
    fontSize: '18px', // Adjust font size as needed
    outline: 'none', // Remove the outline on focus
    textAlign: 'center', // Center-align the text horizontally
    paddingLeft: '8px', // Add left padding to shift the text to the right
  },
  "& .MuiSvgIcon-root": { color: "#FF9500" },
  "&:hover": {
    backgroundColor: 'transparent',
  },
}));

const FilterSelect = styled(Select)(({ theme }) => ({
  borderRadius: '25px',
  backgroundColor: 'rgba(224, 224, 224, 0.3)', // Make the background transparent
  width: 'auto',  // Allows dropdown width to adjust dynamically
  minWidth: '110px', // Min width to ensure wide enough
  height: '40px', // Match the height of the search bar
  borderLeft: 'none', // Remove the border from the left side
  borderTop: 'none', // Remove the border from the top side
  borderBottom: 'none', // Remove the border from the bottom side
  marginRight: '2vw',
  "& .MuiSelect-root": {
    color: "#E0E0E0", // Font color to match the search bar border color
    width: '100%', // Adjust the width as needed
    height: '100%', // Match the height of the search bar
    display: 'flex',
    alignItems: 'center', // Vertically align the content
    justifyContent: 'center', // Horizontally center the content
    fontFamily: 'Rubik', // Adjust font family as needed
    fontWeight: '500', // Adjust font weight as needed
    fontSize: '18px', // Adjust font size as needed
    outline: 'none', // Remove the outline on focus
    textAlign: 'center', // Center-align the text horizontally
    paddingLeft: '8px', // Add left padding to shift the text to the right
  },
  "& .MuiSvgIcon-root": { color: "rgba(0, 0, 0, 0.5)" },
  "&:hover": {
    backgroundColor: 'transparent',
  },
}));

function SearchBarListing({ query, category, ...props }) {
  const tvCheck = category===TV_TYPE || window.location.pathname === '/tvs' || (window.location.href && window.location.href.substring(window.location.href.length - 3) === 'TVs');
  const [inputValue, setInputValue] = useState(query || '');
  const [selectedCategory, setSelectedCategory] = useState(tvCheck ? 'TVs' : 'Movies'); // Default category
  const [sortBy, setSortBy] = useState('');
  const [genre, setGenre] = useState('');
  const [award, setAward] = useState('');
  const userLocation = useContext(LocationContext);
  const [options, setOptions] = useState([]);
  const [placeholderIndex, setPlaceholderIndex] = useState(0);

  const navigate = useNavigate();

  const handleSortByChange = (event) => {
    setSortBy(event.target.value);
  }

  const handleGenreChange = (event) => {
    setGenre(event.target.value);
  }

  const handleAwardChange = (event) => {
    setAward(event.target.value);
  }

  const handleInputChange = (event) => {
    window.scrollTo(0, 0);
    setInputValue(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    navigate(event.target.value === 'Movies' ? '/movies' : '/tvs');
  };

  const handleSubmit = (event) => {
    event.preventDefault();  // Prevent the default form submission behavior
    handleSearchClick();    // Call the search function
  };

  const handleSearchClick = () => {
    // if (inputValue && userLocation) {
    //   navigate(`/searchResults?query=${inputValue}&lat=${userLocation.latitude}&lng=${userLocation.longitude}&category=${selectedCategory}`);
    // removed location:
    if (inputValue) {
      const unifiedCategory = selectedCategory === 'Movies' ? MOVIE_TYPE : TV_TYPE; // TODO: reconsile selectedCategory to use MOVIE_TYPE and TV_TYPE
      navigate(`/searchResults?query=${inputValue}&category=${unifiedCategory}`);
    }
  };

  const placeholderTexts = selectedCategory === 'Movies'
    ? ["Type movie titles here", "Try searching \"90s rom coms\"", "Find your favorite movies"]
    : ["Type TV show titles here", "Try searching \"classic sitcoms\"", "Find your favorite TV shows"];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setPlaceholderIndex(prevIndex => (prevIndex + 1) % placeholderTexts.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [selectedCategory, placeholderTexts.length]);

  const enableSortAndFilters = false;

  return (
    <div>
      <CenteredSearch>
      <SearchContainer >
        
          <Search {...props}>
          <SearchContent >
            {/* Category selection dropdown */}
              <CustomSelect
                value={selectedCategory}
                onChange={handleCategoryChange}
              style={{ fontFamily: 'Rubik', fontWeight: '500', fontSize: '15px', color: '#FF9500', }}
              >
              {/* <MenuItem value="Restaurant" 
              style={{ color: '#FF9500', fontWeight: '500', fontFamily: 'Rubik' }}>Restaurant</MenuItem> */}
              <MenuItem value="Movies" 
              style={{ color: '#FF9500', fontWeight: '500', fontFamily: 'Rubik' }}>Movies</MenuItem>
              <MenuItem value="TVs" 
              style={{ color: '#FF9500', fontWeight: '500', fontFamily: 'Rubik' }}>TVs</MenuItem>
              </CustomSelect>
              <form onSubmit={handleSubmit} style={{ margin: 0, display: 'flex', flexGrow: 1 }}>
                <StyledInputBase
                  inputProps={{ "aria-label": "search", placeholder: placeholderTexts[placeholderIndex] }}
                  value={inputValue}
                  onChange={handleInputChange}
                  style={{ fontFamily: 'Rubik', fontWeight: '500', fontSize: '15px' }}
                />
            <Button className="mt-0.5 mb-0.5"
              type="submit"
              variant="contained"
            ><SearchIcon style={{fill: '#BDBDBD', fontSize: '28'}} /></Button>
            

              </form>
            </SearchContent>
          </Search>
          <Collapse in={Boolean(inputValue && options.length)}>
            <DropdownPaper elevation={3}>
              <List>
                {options.map((option, index) => (
                  <ListItem key={index} button onClick={() => setInputValue(option.name)}>
                    <Box display="flex" justifyContent="flex-start" alignItems="center">
                      <Box display="flex" alignItems="center">
                        <Typography variant="body1" sx={{ fontFamily: 'Rubik', fontWeight: '500', fontSize: '20px' }}>
                          {option.name}
                        </Typography>
                        <Chip label={option.category} size="small" sx={{ marginLeft: '8px', fontFamily: 'Rubik', fontWeight: '500' }} />
                      </Box>
                    </Box>
                  </ListItem>
                ))}
              </List>
            </DropdownPaper>
          </Collapse>
        </SearchContainer>
      </CenteredSearch>
      {enableSortAndFilters && (
        <CenteredFilters>
      {/* Sort By... */}
          <FilterSelect
            value={sortBy}
            onChange={handleSortByChange}
        style={{ fontFamily: 'Rubik', fontWeight: '400', fontSize: '15px', color: 'rgba(0, 0, 0, 0.5)',}}
            displayEmpty
          >
            {!sortBy && (
              <MenuItem value="" disabled style={{ display: 'none' }}>Sort By</MenuItem>
            )}
        <MenuItem value="likes" 
        style={{ fontFamily: 'Rubik', fontWeight: '400', color: 'rgba(0, 0, 0, 0.5)', }}># of Likes</MenuItem>
        <MenuItem value="ratings" 
        style={{ fontFamily: 'Rubik', fontWeight: '400', color: 'rgba(0, 0, 0, 0.5)', }}># of Ratings</MenuItem>
        <MenuItem value="avg" 
        style={{ fontFamily: 'Rubik', fontWeight: '400', color: 'rgba(0, 0, 0, 0.5)', }}>Average Rating</MenuItem>
          </FilterSelect>

      {/* Genres */}
          <FilterSelect
            value={genre}
            onChange={handleGenreChange}
        style={{ fontFamily: 'Rubik', fontWeight: '400', fontSize: '15px', color: 'rgba(0, 0, 0, 0.5)', }}
            displayEmpty
          >
            {!genre && (
              <MenuItem value="" disabled style={{ display: 'none' }}>Genre</MenuItem>
            )}
        <MenuItem value="1" 
        style={{ fontFamily: 'Rubik', fontWeight: '400', color: 'rgba(0, 0, 0, 0.5)', }}>Placeholder 1</MenuItem>
        <MenuItem value="2" 
        style={{ fontFamily: 'Rubik', fontWeight: '400', color: 'rgba(0, 0, 0, 0.5)', }}>Placeholder 2</MenuItem>
          </FilterSelect>

      {/* Awards */}
          <FilterSelect
            value={award}
            onChange={handleAwardChange}
        style={{ fontFamily: 'Rubik', fontWeight: '400', fontSize: '15px', color: 'rgba(0, 0, 0, 0.5)', }}
            displayEmpty
          >
            {!award && (
              <MenuItem value="" disabled style={{ display: 'none' }}>Award</MenuItem>
            )}
        <MenuItem value="oscars" 
        style={{ fontFamily: 'Rubik', fontWeight: '400', color: 'rgba(0, 0, 0, 0.5)' }}>Oscars</MenuItem>
        <MenuItem value="golden globe" 
        style={{ fontFamily: 'Rubik', fontWeight: '400', color: 'rgba(0, 0, 0, 0.5)' }}>Golden Globe</MenuItem>
        <MenuItem value="emmys" 
        style={{ fontFamily: 'Rubik', fontWeight: '400', color: 'rgba(0, 0, 0, 0.5)' }}>Emmys</MenuItem>
          </FilterSelect>
        </CenteredFilters>
      )}
    </div>
  );
}

export default SearchBarListing;
