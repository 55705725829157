import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ListingCard from './ListingCard';
import '@fontsource/rubik/400.css';
import '@fontsource/rubik/500.css';
import { AuthContext } from '../authContext';
import { fetchBookmarks } from '../../redux/bookmarksSlice';

const RestaurantListing = ({ onCardClick }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const dispatch = useDispatch();
  const user = useContext(AuthContext);

  // Retrieve restaurants from Redux store
  const cards = useSelector(state => state.restaurants.restaurants);
  const selectedCategory = useSelector(state => state.restaurants.selectedCategory);
  const [filteredCards, setFilteredCards] = useState([]);

  useEffect(() => {
    const filtered = cards.filter(card => {
        return !selectedCategory || (card.categories && card.categories.includes(selectedCategory));
    });
    
    setFilteredCards(filtered);
  }, [cards, selectedCategory]);

  useEffect(() => {
    if (user) {
      dispatch(fetchBookmarks(user));
    }
  }, [dispatch, user]);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const cardHeight = document.querySelector('.listing-card')?.offsetHeight || 200;
    const newSlide = Math.floor(scrollPosition / cardHeight);
    setCurrentSlide(newSlide % filteredCards.length);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [filteredCards]);

  return (
    <div style={{ width: '100%', overflow: 'hidden', position: 'relative' }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}>
        {filteredCards.map((card, index) => (
          <div 
            className="listing-card" 
            key={index} 
            style={{ margin: '8px' }}
            onClick={() => onCardClick(card.docID)}
          >
            <ListingCard
              docId={card.docID}
              title={card.title}
              imageUrl={card.imageUrl}
              distance={card.distance}
              ratings={card.ratings}
              categories={card.categories} 
              onTitleClick={() => onCardClick(card.docID)} 
              type={card.type}
              priceRange={card.priceRange}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default RestaurantListing;
