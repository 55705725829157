import {
  createSlice,
  createAsyncThunk,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import {
  addBookmark,
  deleteBookmark,
  getBookmarkContent,
} from "../firebase/fireBaseInit";
import { TV_TYPE } from "src/const";

const listsSlice = createSlice({
  name: "lists",
  initialState: {
    like_tvs: [],
    dislike_tvs: [],
    wishlist_tvs: [],
    seen_tvs: [],
    favorite_tvs: [],

    like_movies: [],
    dislike_movies: [],
    wishlist_movies: [],
    seen_movies: [],
    favorite_movies: [],

    isFetching: false,
  },
  reducers: {
    likedMovies: (state, action) => {
      state.movieList.titles = [...state.movieList.titles, action.payload];
      state.movieList.count = state.movieList.titles.length;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchList.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchList.fulfilled, (state, action) => {
        const { listName, data } = action.payload;
        state[listName] = data;
        state.isFetching = false;
      })
      .addCase(fetchList.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(deleteAllFromLikeTvs.fulfilled, (state, action) => {
        state.like_tvs = [];
      });
  },
});

export const fetchList = createAsyncThunk(
  "lists/fetchList",
  async ({ user, listName }) => {
    try {
      if (user) {
        const response = await getBookmarkContent({
          uid: user,
          listName: listName,
        });

        return { listName, data: response?.data };
      }
    } catch (error) {
      console.error(error);
      return isRejectedWithValue(error);
    }
  }
);

export const addLike = createAsyncThunk(
  "lists/addLike",
  async ({ user, docID, type, op }) => {
    try {
      if (user && type == "TVs") {
        const response = await addBookmark({
          id: docID,
          type: TV_TYPE,
          op,
        });
        if (response.data === false) alert("This title exists in another list");
      }
    } catch (error) {
      console.error(error);
    }
  }
);

export const deleteFromList = createAsyncThunk(
  "lists/deleteFromList",
  async ({ doc, listName }) => {
    let type;
    if (doc.type === "TVs") type = TV_TYPE;
    listName = listName.toLowerCase();

    try {
      await deleteBookmark({
        type,
        id: doc.id,
        listType: listName,
      });
      return { id: doc.id, listType: listName };
    } catch (error) {
      console.error("Firebase function call failed:", error);
    }
  }
);

export const deleteAllFromLikeTvs = createAsyncThunk(
  "lists/deleteAllFromLikeTvs",
  async (docID) => {
    try {
      await deleteBookmark({
        type: TV_TYPE,
        id: docID,
        listType: "like",
      });
    } catch (error) {
      console.error(error);
    }
  }
);

export const { likedMovies, updateLikeTvs } = listsSlice.actions;
export default listsSlice.reducer;
