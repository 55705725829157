import React from "react";
import SignUpForm from "../components/forms/SignUpForm"; // Import SignInForm

const Signup = () => {
  return (
    <div>
      <SignUpForm />
    </div>
  );
};

export default Signup;
