import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  db,
  collection,
  getDocs,
  query,
  limit,
  startAfter,
  movieSearch,
  where,
} from "../firebase/fireBaseInit";

const PAGE_SIZE = 20;

export const fetchMovies = createAsyncThunk(
  "moviesListing/fetchMovies",
  async (_, { getState, dispatch, rejectWithValue }) => {
    try {
      const { movieCategories, movieLastDoc } = getState().moviesListing;
      let fetchedData = [];
      let movieLastDocument = null;

      const moviesCollection = collection(db, "movies");
      let moviesQuery;

      if (
        getState().moviesListing.movieCategories !== movieCategories ||
        !movieLastDoc
      ) {
        // Reset movies if the category has changed or if starting a new fetch cycle
        dispatch(resetMovies());
      }

      if (movieCategories) {
        // Query to fetch Movies shows based on movieCategories
        moviesQuery = movieLastDoc
          ? query(
              moviesCollection,
              where("genres", "array-contains", movieCategories),
              startAfter(movieLastDoc),
              limit(PAGE_SIZE)
            )
          : query(
              moviesCollection,
              where("genres", "array-contains", movieCategories),
              limit(PAGE_SIZE)
            );
      } else {
        // Query to fetch all Movies shows
        moviesQuery = movieLastDoc
          ? query(moviesCollection, startAfter(movieLastDoc), limit(PAGE_SIZE))
          : query(moviesCollection, limit(PAGE_SIZE));
      }

      const querySnapshot = await getDocs(moviesQuery);

      fetchedData = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const data = doc.data();
          const ratingsCollectionRef = collection(
            db,
            "movies",
            doc.id,
            "ratings"
          );
          const ratingsSnapshot = await getDocs(ratingsCollectionRef);
          const ratingsData = {};
          ratingsSnapshot.forEach((ratingDoc) => {
            ratingsData[ratingDoc.id] = ratingDoc.data();
          });

          return {
            title: data.name,
            description: data.overview,
            imageUrl: data.cover_photo_url,
            movieCategories: data.genres || {},
            duration: data.duration,
            year: data.release_date ? data.release_date.split("-")[0] : "N/A",
            docID: doc.id,
            ratings: ratingsData || {},
            type: data.type,
          };
        })
      );

      if (fetchedData.length > 0) {
        movieLastDocument = querySnapshot.docs[querySnapshot.docs.length - 1];
      }

      return { movieCards: fetchedData, movieLastDoc: movieLastDocument };
    } catch (error) {
      console.error("Error fetching Movies:", error);
      return rejectWithValue(error.message);
    }
  }
);

export const streamMovieSearchResults = createAsyncThunk(
  "moviesListing/streamMovieSearchResults",
  async (query, { rejectWithValue, dispatch }) => {
    dispatch(resetMovies());

    try {
      if (!query) {
        return [];
      }
      const result = await movieSearch({ searchbar: query, page: 1 });

      if (!result.data || !Array.isArray(result.data)) {
        return [];
      }

      await Promise.all(
        result.data.map(async (movie) => {
          const ratingsCollectionRef = collection(
            db,
            "movies",
            movie.id,
            "ratings"
          );
          const ratingsSnapshot = await getDocs(ratingsCollectionRef);
          const ratingsData = {};
          ratingsSnapshot.forEach((doc) => {
            ratingsData[doc.id] = doc.data();
          });

          const transformedMovie = {
            ...movie,
            ratings: ratingsData,
          };

          dispatch(addMovie(transformedMovie));
        })
      );
      return [];
    } catch (error) {
      console.error(`Error fetching Movies shows: ${error.message}`);
      return rejectWithValue(error.message);
    }
  }
);

const moviesSlice = createSlice({
  name: "moviesListing",
  initialState: {
    movieCards: [],
    movieCategories: null,
    movieType: "",
    movieLastDoc: null,
    status: "idle",
    error: null,
    bookmarkedMovies: [],
    filter: {
      sortBy: "Recommended",
    },
    movies: [],
    movieIsLoading: false,
  },
  reducers: {
    setMovieCategories: (state, action) => {
      state.movieCategories = action.payload;
      state.movieCards = [];
      state.movieLastDoc = null;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    movieAdded: (state, action) => {
      state.movies.push(action.payload);
    },
    movieUpdated: (state, action) => {
      const { id, title, description } = action.payload;
      const existingMovie = state.movies.find((movie) => movie.id === id);
      if (existingMovie) {
        existingMovie.title = title;
        existingMovie.description = description;
      }
    },
    movieDeleted: (state, action) => {
      const id = action.payload;
      state.movies = state.movies.filter((movie) => movie.id !== id);
    },
    setLoading: (state, action) => {
      state.movieIsLoading = action.payload;
    },
    setMovie: (state, action) => {
      state.movieCards = action.payload;
    },
    resetMovies: (state) => {
      state.movieCards = [];
      state.movieLastDoc = null;
    },
    addMovie: (state, action) => {
      state.movieCards.push(action.payload);
    },
    updateMovieRatings: (state, action) => {
      const { listingId, ratings } = action.payload;
      const movie = state.movieCards.find((m) => m.id === listingId);
      if (movie) {
        movie.ratings = ratings;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // Existing handlers for fetchMovies
      .addCase(fetchMovies.pending, (state) => {
        state.status = "loading";
        state.movieIsLoading = true;
      })
      .addCase(fetchMovies.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.movieCards = state.movieCards.concat(action.payload.movieCards);
        state.movieLastDoc = action.payload.movieLastDoc;
        state.movieType = action.payload.movieType;
        state.movieIsLoading = false;
      })
      .addCase(fetchMovies.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.movieIsLoading = false;
      })
      .addCase(streamMovieSearchResults.pending, (state) => {
        state.status = "loading";
        state.movieIsLoading = true;
      })
      .addCase(streamMovieSearchResults.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.movieIsLoading = false;
      })
      .addCase(streamMovieSearchResults.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  setMovieCategories,
  setFilter,
  moviesAdded,
  moviesUpdated,
  moviesDeleted,
  setLoading,
  setMovies,
  resetMovies,
  addMovie,
  updateMovieRatings,
} = moviesSlice.actions;

export default moviesSlice.reducer;
