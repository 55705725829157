import React, { useEffect, useRef, useState } from 'react';

const LocationAutocomplete = ({ onPlaceSelected, inputComponent: InputComponent = 'input', ...props }) => {
  const [query, setQuery] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current && window.google && window.google.maps) {
      const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current);

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        console.log("Selected place:", place);
        if (place && place.formatted_address) {
          setQuery(place.formatted_address);
          if (onPlaceSelected) {
            onPlaceSelected(place.formatted_address);
          }
        }
      });
    
    }
  }, [onPlaceSelected]);

  return (
    <div>
      <InputComponent 
        ref={inputRef}
        type="text"
        value={query}
        placeholder="Select your location"
        onChange={e => {
          setQuery(e.target.value);
          console.log("LocationAutocomplete query state:", query);
          console.log("Query changed:", e.target.value);
        }}
        {...props} // Spread any additional props to the input element
      />
    </div>
  );
};



export default LocationAutocomplete;
