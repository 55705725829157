import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import ListingRestaurant from "./pages/ListingRestaurant";
import ListPage from "./pages/ListPage";
import Reviews from "./pages/Reviews";
import Bookmarks from "./pages/Bookmarks";
import Home from "./pages/Home";
import SignUp from "./pages/Signup";
import ResetConfirmation from "./components/forms/ConfirmReset";
import ForgotPasswordForm from "./components/forms/ForgotPassword";
import ResetPassword from "./components/forms/ResetPassword";
import SignUpFormInfo from "./components/forms/SignUpFormInfo";
import User from "./pages/User";
import UserSettings from "./pages/UserSettings";
import { AuthProvider, useAuthContext } from "./components/authContext";
import TestLocationPage from "./pages/TestLocationPage";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "./components/authContext";
import SearchResults from "./pages/SearchResults";
import TestMovieSearch from "./pages/TestMovieSearch";
import { CarouselProvider } from "./contexts/CarouselContext";
import { ModalProvider } from "./components/listing/ModalContext";
import TestRestaurantSearch from "./pages/TestRestaurantSearch";
import TestSignIn from "./pages/TestSignIn";
import Error from "./pages/Error";
import SignIn from "./pages/SignIn";
import UserList from "./pages/UserList";
import LandingPage from "./pages/LandingPage";
import DesktopNav from "./components/nav/DesktopNav";
import DetailPage from "./pages/DetailPage";
import ChangeUsername from "./components/forms/ChangeUsername";
import MobileNav from "./components/nav/MobileNav";
import Footer from "./components/Footer";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { setIsInitialPageload, updateUserInfo } from "./redux/userSlice";
import { useDispatch } from "react-redux";
import { MOVIE_TYPE, TV_TYPE } from "./const";
import { ThemeProvider } from "@mui/material";
import cribitTheme from "./theme";

const PrivateRoutes = () => {
  const authContext = useContext(AuthContext);
  const isAnonymous = authContext && authContext.isAnonymous;
  const uid = authContext?.uid;
  const isAuthenticated = uid && !isAnonymous;

  console.log("isAuthen???", isAuthenticated, isAnonymous);

  return isAuthenticated ? <Outlet /> : <Navigate to="/Signup" />;
};

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const auth = getAuth();
  const { currentUser } = auth || {};
  const { uid } = currentUser || {};
  const dispatch = useDispatch();

  // on page reload, get current user, and update redux username and uid state
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const { displayName, uid } = user;
        console.log("User is signed in:", displayName, uid);
        dispatch(updateUserInfo({ username: displayName, uid }));
        dispatch(setIsInitialPageload(true));
      } else {
        console.log("No user is signed in");
        dispatch(updateUserInfo({ username: null, uid }));
      }
    });

    // Clean up the subscription
    return () => unsubscribe();
  }, [auth, dispatch]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ModalProvider>
      <CarouselProvider>
        <AuthProvider>
          <ThemeProvider theme={cribitTheme}>
          <Router>
            <Routes>
              <Route
                path="/welcome/:intro?/:select?/:type?"
                element={<LandingPage />}
              />
              <Route
                element={
                  <>
                    {isMobile ? <MobileNav /> : <DesktopNav />}
                    <Outlet />
                  </>
                }
              >
                <Route path="/" element={<Navigate replace to="/welcome" />} />
                <Route index element={<Home />} />
                <Route path="confirmreset" element={<ResetConfirmation />} />
                {/* only keep this here to test styling UNTIL authentication bug is fixed; this is a private route */}
                <Route path="user/:username/:uid" element={<User />} />
                {/* route for shared links -- public*/}
                <Route
                  path="user/:username/:uid/:type/shared=true"
                  element={<User />}
                />
                <Route
                  path="user/:username/:uid/:type/:listName"
                  element={<UserList />}
                />
                <Route element={<PrivateRoutes />}>
                  <Route path="bookmarks" element={<Bookmarks />} />
                  <Route path="settings" element={<UserSettings />} />
                </Route>
                <Route path="user/:username/:uid/:type" element={<User />} />
                <Route path="listing" element={<ListingRestaurant />} />
                <Route path="changeusername" element={<ChangeUsername />} />
                <Route path="movies" element={<ListPage />} />
                <Route path="tvs" element={<ListPage />} />
                <Route path="reviews" element={<Reviews />} />
                <Route path="Signup" element={<SignUp />} />
                <Route path="sign-in" element={<SignIn />} />
                <Route path="reset" element={<ResetPassword />} />
                <Route path="forgot" element={<ForgotPasswordForm />} />
                <Route
                  path="MovieDetail/:docId"
                  element={<DetailPage detailType={MOVIE_TYPE} />}
                />
                <Route
                  path="TVDetail/:docId"
                  element={<DetailPage detailType={TV_TYPE} />}
                />
                <Route path="searchResults" element={<SearchResults />} />
                <Route path="test-location" element={<TestLocationPage />} />
                <Route path="test-movieSearch" element={<TestMovieSearch />} />
                <Route path="test-signin" element={<TestSignIn />} />
                <Route path="test-rs" element={<TestRestaurantSearch />} />
                <Route path="*" element={<Error />} />
              </Route>
            </Routes>
            <Footer />
          </Router>
          </ThemeProvider>
        </AuthProvider>
      </CarouselProvider>
    </ModalProvider>
  );
}

export default App;
