import React, { useState, useEffect } from 'react';
import SearchIcon from "@mui/icons-material/Search";
import LocationOnIcon from "@mui/icons-material/LocationOn"; // Import the location icon
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Select from "@mui/material/Select";
import '@fontsource/rubik/400.css';
import '@fontsource/rubik/500.css';
import AddressModal from '../AddressModal';
import { useSelector } from 'react-redux';
import { fetchAddressFromCoords } from '../../contexts/coordToAddress';

const StyledInputBox = styled(InputBase)(({ theme }) => ({
  fontFamily: 'Rubik',
  fontWeight: '400',
  color: '#BDBDBD',
  "& .MuiInputBase-input": {
    border: 'none',
    fontFamily: 'Rubik',
    fontWeight: '400',
    color: '#BDBDBD',
    padding: theme.spacing(1, 1, 1, 1),
    width: "auto",
  },
}));

const Search = styled("div")(({ theme }) => ({

  display: 'flex',
  alignItems: 'center',
  position: "relative",
  borderRadius: '16px',
  backgroundColor: '#FFFFFF',
  border: '1px solid #BDBDBD',
  "&:hover": {
    backgroundColor: '#FFFFFF',
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  zIndex: 1000,
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const LocationContainer = styled("div")(({ theme }) => ({
  fontFamily: 'Rubik',
  fontWeight: '500',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  cursor: 'pointer',
  borderRight: '1px solid #BDBDBD',
}));

const LocationSelect = styled(Select)(({ theme }) => ({
  fontFamily: 'Rubik',
  fontWeight: '400',
  minWidth: '100px',
  marginRight: theme.spacing(1),
  color: '#BDBDBD',
  "& .MuiSelect-root": {
    border: 'none', // Remove the border for the select
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  fontFamily: 'Rubik',
  fontWeight: '500',
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  right: 0,
  top: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: '#BDBDBD',
  cursor: 'pointer',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: '#BDBDBD',
  "& .MuiInputBase-input": {
    color: '#00171F',
    fontFamily: 'Rubik',
    textOverflow: 'ellipsis',
    fontWeight: '500',
    padding: theme.spacing(1, 3, 1, 1),
    paddingLeft: `calc(1em + ${theme.spacing(0)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: props => props.length || "20ch",
    },
  },
}));

function SearchBarWithLocation({ onQueryChange, onLocationChange, onSearch, length = '20ch', ...props }) {

  const [searchQuery, setSearchQuery] = useState('');
  const userLocation = useSelector(state => state.userLocation.location);
  const [addressPlaceholder, setAddressPlaceholder] = useState("Select your location");
  const [location, setLocation] = useState('');

  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    onQueryChange(value);
  };


  const handleLocationInputChange = (e) => {
    const value = e.target.value;
    setLocation(value);
    onLocationChange(value);  // This will inform the parent (`Listing`) about the change.
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState("");

  const handleLocationSelect = (selectedAddress) => {
    console.log("Setting selected address:", selectedAddress);
    setSelectedAddress(selectedAddress);
    onLocationChange(selectedAddress);
  };


  useEffect(() => {
    setSearchQuery(props.searchQuery);
  }, [props.searchQuery]);

  useEffect(() => {
    if (userLocation?.latitude && userLocation?.longitude) {
      const fetchAddress = async () => {
        const address = await fetchAddressFromCoords(userLocation.latitude, userLocation.longitude);
        setAddressPlaceholder(address);
      };

      fetchAddress();
    }
  }, [userLocation]);


  return (
    <Search {...props}>
      <LocationContainer onClick={() => setIsModalOpen(true)}>
        <div style={{ marginLeft: '0.5vw', marginRight: '0.5vw' }}>
          <LocationOnIcon />
        </div>

        <StyledInputBase
          placeholder={addressPlaceholder}
          inputProps={{ 'aria-label': 'location' }}
          value={selectedAddress}
          onChange={(e) => {
            console.log("Location Input Value:", e.target.value);
            setSelectedAddress(e.target.value);
            onLocationChange(e.target.value);
          }}
        />


      </LocationContainer>

      {/* <StyledInputBase
        length={length}
        placeholder="Search"
        inputProps={{ 'aria-label': 'search' }}
        value={searchQuery}
        onChange={handleSearchInputChange}
      />

      <SearchIconWrapper onClick={() => {
        onSearch && onSearch();
      }}>
        <SearchIcon />
      </SearchIconWrapper> */}

      <AddressModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleLocationSelect}
      />

    </Search>
  );


}

export default SearchBarWithLocation;
