// Import the necessary functions from Redux Toolkit and any other libraries you need
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { restaurantSearch, restaurantFilteredSearch } from '../firebase/fireBaseInit';
import { db, doc, collection, getDocs, getDoc } from '../firebase/fireBaseInit';

// Define initial state: 
const initialState = {
    restaurants: [],
    categories: [],
    selectedCategory: '', // Add this field to hold the selected category
    ratings: {},
    isLoading: false,
    hasErrors: false,
    errorMessage: ''
  };

//helper functions: 
function calculateDistance(lat1, lon1, lat2, lon2) {
    if (lat1 && lon1 && lat2 && lon2) {
      const R = 6371; // Radius of the Earth in kilometers
      const dLat = deg2rad(lat2 - lat1);
      const dLon = deg2rad(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distanceInKm = R * c;
      const distanceInMiles = distanceInKm * 0.621371; // Convert to miles
      return distanceInMiles.toFixed(2);
    }
    return null;
  }
  
  // Helper function to convert degrees to radians
  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }
  
  // Helper function to fetch category names by their IDs
  const fetchCategoryNamesByIds = async (categoryIds) => {
    try {
      const categoriesCollectionRef = collection(db, 'restaurant_categories');
      const categoryNames = [];
      for (const id of categoryIds) {
        const docRef = doc(categoriesCollectionRef, id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          categoryNames.push(docSnap.data().name);
        } else {
          console.log(`No such document in restaurant_categories! id: ${id}`);
        }
      }
      return categoryNames;
    } catch (error) {
      console.error("Error fetching category names:", error);
      return [];
    }
  };

  export const fetchRestaurants = createAsyncThunk(
    'restaurants/fetchRestaurants',
    async ({ searchQuery, location, filters, forceRegularSearch }, { getState }) => {
      const state = getState();
      const userLocation = state.userLocation.location;
      const { latitude, longitude } = userLocation;
  
      const { distance, categories, price } = filters;
      const radius = parseInt(distance.replace('mi', ''));
      const priceArray = Array(price.length).fill(1);
  
      let result;
      try {
        if (forceRegularSearch || !categories.length) {
          result = await restaurantSearch({
            searchbar: searchQuery,
            location: location,
            radius: 10, // You might want to make this dynamic or part of the filters
            limit: 20,
            sort_by: "best_match"
          });
        } else {
          result = await restaurantFilteredSearch({
            searchbar: searchQuery,
            location: location,
            radius: radius,
            limit: 20,
            sort_by: "best_match",
            categories,
            price: priceArray
          });
        }
        console.log("this is the result: ", result); 
        if (result.data && Array.isArray(result.data)) {
          const transformedData = await Promise.all(result.data.map(async restaurant => {
            const [categoryNames, ratingsSnapshot] = await Promise.all([
              fetchCategoryNamesByIds(restaurant.categories || []),
              getDocs(collection(db, "restaurants", restaurant.id, "ratings"))
            ]);
  
            const ratingsData = {};
            ratingsSnapshot.forEach(doc => {
              ratingsData[doc.id] = doc.data();
            });
  
            const restaurantLatitude = restaurant.latlong?._latitude;
            const restaurantLongitude = restaurant.latlong?._longitude;
            const distanceToRestaurant = calculateDistance(latitude, longitude, restaurantLatitude, restaurantLongitude);
  
            return {
              title: restaurant.name,
              description: restaurant.address,
              imageUrl: restaurant.cover_photo_url,
              distance: distanceToRestaurant,
              categories: categoryNames,
              docID: restaurant.id,
              ratings: ratingsData,
              priceRange: restaurant.price,
              type: restaurant.type,
            };
          }));
  
          return transformedData;
        }
      } catch (error) {
        throw error;
      }
    }
  );

  export const fetchRestaurantSearchResults = createAsyncThunk(
    'restaurants/fetchRestaurantSearchResults',
    async (searchQuery, { getState }) => {
      console.log("Dispatched fetchRestaurantSearchResults with query:", searchQuery); // Log when dispatched
  
      try {
        if (!searchQuery) {
          console.log("Search query is empty."); // Log if query is empty
          return [];
        }
  
        const state = getState();
        const userLocation = state.userLocation.location;
        const { latitude, longitude } = userLocation;
  
        console.log("User location:", { latitude, longitude }); // Log the user's location
  
        const result = await restaurantSearch({
          searchbar: searchQuery,
          location: { latitude, longitude },
          radius: 10,
          limit: 20,
          sort_by: "best_match"
        });
  
        console.log("Search results:", result); // Log the raw search results
  
        if (result.data && Array.isArray(result.data)) {
          const transformedData = await Promise.all(result.data.map(async restaurant => {
            const [categoryNames, ratingsSnapshot] = await Promise.all([
              fetchCategoryNamesByIds(restaurant.categories || []),
              getDocs(collection(db, "restaurants", restaurant.id, "ratings"))
            ]);
  
            console.log("Categories and Ratings fetched for:", restaurant.name); // Log for each restaurant
  
            const ratingsData = {};
            ratingsSnapshot.forEach(doc => {
              ratingsData[doc.id] = doc.data();
            });
  
            const restaurantLatitude = restaurant.latlong?._latitude;
            const restaurantLongitude = restaurant.latlong?._longitude;
            const distanceToRestaurant = calculateDistance(latitude, longitude, restaurantLatitude, restaurantLongitude);
  
            return {
              title: restaurant.name,
              description: restaurant.address,
              imageUrl: restaurant.cover_photo_url,
              distance: distanceToRestaurant,
              categories: categoryNames,
              docID: restaurant.id,
              ratings: ratingsData,
              priceRange: restaurant.price,
              type: restaurant.type,
            };
          }));
  
          console.log("Transformed search results:", transformedData); // Log the final transformed data
          return transformedData;
        }
      } catch (error) {
        console.error("Error in fetchRestaurantSearchResults:", error); // Log any errors
        throw error;
      }
    }
  );
  
  

// The slice
const restaurantsSlice = createSlice({
    name: 'restaurants',
    initialState,
    reducers: {
      // Define reducers to handle any other actions, such as updating filters
      setSelectedCategory: (state, action) => {
        state.selectedCategory = action.payload;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchRestaurants.pending, (state) => {
          state.isLoading = true;
          state.hasErrors = false;
        })
        .addCase(fetchRestaurants.fulfilled, (state, action) => {
          state.restaurants = action.payload;
          state.isLoading = false;
        })
        .addCase(fetchRestaurants.rejected, (state, action) => {
          state.isLoading = false;
          state.hasErrors = true;
          state.errorMessage = action.error.message;
        });
    },
  });

// Export the actions and reducer
export const {  } = restaurantsSlice.actions;
export default restaurantsSlice.reducer;
