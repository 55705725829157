import React, { useState, useEffect } from "react";
import {
  auth,
  createUserWithEmailAndPassword,
  migrateUser,
} from "../../firebase/fireBaseInit";
import "@fontsource/rubik/400.css";
import "@fontsource/rubik/500.css";
import "@fontsource/rubik/700.css";
import "./SignInSignUp.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  signUpPending,
  signUpFulfilled,
  signUpRejected,
} from "../../redux/userSlice";
import CircularProgress from "@mui/material/CircularProgress";
import { updateProfile } from "firebase/auth";

const SignUpForm = (props) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [isEmailExists, setIsEmailExists] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    switch (name) {
      case "email":
        setEmail(value);
        break;
      case "username":
        setUsername(value);
        break;
      case "password":
        setPassword(value);
        break;
      case "confirmPassword":
        setConfirmPassword(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const usernameRegex = /^[a-zA-Z0-9_]{4,20}$/;
    const passwordRegex = /^[a-zA-Z0-9!@#$%^&*]{8,}$/;

    setLoading(true);
    if (!emailRegex.test(email)) {
      alert("Please enter a valid email.");
      setLoading(false);
      return;
    }

    if (!usernameRegex.test(username)) {
      alert(
        "Username should be 4-20 characters and can include letters, numbers, and underscores."
      );
      setLoading(false);
      return;
    }

    if (!passwordRegex.test(password)) {
      alert(
        "Password must be at least 8 characters long and can include letters, numbers, and specific special characters (e.g., !, @, #, $)."
      );
      setLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      alert("Passwords not the same.");
      setLoading(false);
      return;
    }
    dispatch(signUpPending());
    const beforeUid = auth.currentUser?.uid;
    if (process.env.NODE_ENV === "development") {
      console.log("[SignUpForm] before uid=", auth.currentUser?.uid);
    }
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // User creation logic
        const user = userCredential.user;
        console.log("User created:", user);

        // update the user's profile with display name aka username
        return updateProfile(user, {
          displayName: username,
        })
          .then(() => {
            console.log("Display name updated successfully:", user.displayName);

            // Push a message to the data layer for GTM
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "signUpComplete",
              userId: user.uid,
              userEmail: user.email,
              username: user.displayName,
            });

            // Dispatch the signUpFulfilled action
            dispatch(
              signUpFulfilled({
                uid: user.uid,
                email: user.email,
                displayName: username, // username from local state
                photoURL: user.photoURL,
              })
            );
            setLoading(true);
            const afterUid = auth.currentUser?.uid;
            if (process.env.NODE_ENV === "development") {
              console.log("[SignUpForm] after uid=", auth.currentUser?.uid);
            }
            if (beforeUid !== afterUid) {
              // Try migrating user and catch any errors
              return migrateUser({ old_uid: beforeUid, new_uid: afterUid })
                .then(() => {
                  if (process.env.NODE_ENV === "development") {
                    console.log("User migration successful.");
                  }
                })
                .catch((error) => {
                  if (process.env.NODE_ENV === "development") {
                    console.error("Error migrating user: ", error);
                  }
                });
            }
          })
          .then(() => {
            setIsEmailExists(false);
            navigate("/");
          })
          .catch((error) => {
            if (error.code === "auth/email-already-in-use") {
              alert("Account Already Exists");
              dispatch(signUpRejected(error.message));
            } else if (error.code === "auth/weak-password") {
              alert(error.message.replace("Firebase: ", ""));
              dispatch(signUpRejected(error.message));
            } else {
              console.error("Error updating user: ", error);
              dispatch(signUpRejected(error.message));
            }
            setLoading(false); // Stop loading on error
          });
      })
      .catch((error) => {
        console.error("Error creating user: ", error);
        setIsEmailExists(true);
        dispatch(signUpRejected(error.message));
        setLoading(false); // Stop loading on error
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="form-container">
      <div className="form-wrapper sign-up">
        <form className="form" onSubmit={handleSubmit}>
          <h2 className="form-title">Sign Up</h2>
          <p className="form-subtitle">Welcome to Cribit!</p>
          <label className="form-input-label" htmlFor="email">
            Email
          </label>
          <input
            className="form-input short"
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={handleChange}
          />
          {isEmailExists && (
            <div className="text-red-600 text-rubik-sans text-sm mx-1">
              Email already exists.
            </div>
          )}

          <label className="form-input-label" htmlFor="username">
            Username
          </label>
          <input
            className="form-input short"
            type="text"
            id="username"
            name="username"
            value={username}
            onChange={handleChange}
          />

          <label className="form-input-label" htmlFor="password">
            Password
          </label>
          <input
            className="form-input short"
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={handleChange}
          />

          <label className="form-input-label" htmlFor="password">
            Confirm Password
          </label>
          <input
            className="form-input"
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            value={confirmPassword}
            onChange={handleChange}
          />
          <div className="button-wrapper">
            {loading ? (
              <CircularProgress style={{ color: "#FFA500" }} />
            ) : (
              <button className="button" type="submit">
                Sign Up
              </button>
            )}
          </div>
        </form>
        <div className="alt-account-wrapper">
          <p className="black-text">Already got an account?</p>
          <button
            className="button-text"
            type="button"
            onClick={() => {
              navigate("/sign-in");
            }}
          >
            Sign in here!
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignUpForm;
