import React, { useContext, useEffect, useState } from "react";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTrigger,
} from "../ui/drawer";
import { Dialog, DialogContent, DialogTrigger } from "../ui/dialog";
import { Button } from "../ui/button";
import MenuIcon from "@mui/icons-material/Menu";
import MobileLogo from "../../Pics/wordmark.svg";
import BigLogo from "../../Pics/logo.svg";
import XLogo from "../../logos/X.svg";
import InstagramLogo from "../../logos/Instagram.svg";
import DiscordLogo from "../../Pics/discord-dark.svg"; // Replace with the actual path to your Discord logo
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setIsNavShown } from "src/redux/onboardingSlice";
import Feedback from "../Feedback";
import { AuthContext } from "../authContext";
import { getAuth } from "firebase/auth";

const MobileNav = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentUser } = auth || {};
  const { displayName, uid } = currentUser || {};
  const [openFeedback, setOpenFeedback] = useState(false);
  const authContext = useContext(AuthContext);
  const isAnonymous = authContext && authContext.isAnonymous;

  const handleClick = (url) => {
    dispatch(setIsNavShown(false));
    navigate(url);
  };

  const handleOpenEmail = () => {
    const emailAddress = "hello@cribit.xyz";
    window.location.href = `mailto:${emailAddress}`;
  };

  return (
    <>
      <div className="flex flex-row w-full justify-between items-center p-3 bg-orange-100 overflow-auto">
        <img
          src={MobileLogo}
          width="28"
          height="40"
          onClick={() => handleClick("/")}
        />
        <Drawer direction={"right"}>
          <DrawerTrigger>
            <MenuIcon fontSize="large" />
          </DrawerTrigger>
          <DrawerContent className="w-3/4 h-full ml-24 rounded-none bg-orange-50 border-none">
            <DrawerHeader className="p-6">
              <img src={BigLogo} className="w-[70px] h-[20px] m-auto" />
            </DrawerHeader>
            <div className="h-full flex flex-col justify-between">
              <div className="flex flex-col items-center">
                {isAnonymous ? (
                  <Button
                    variant="ghost"
                    className="text-black text-base font-rubik-sans font-medium my-4 w-fit"
                    onClick={() => handleClick("/sign-in")}
                  >
                    <DrawerClose>Sign in</DrawerClose>
                  </Button>
                ) : (
                  <Button
                    variant="ghost"
                    className="text-black text-base font-rubik-sans font-medium my-4 w-fit"
                    onClick={() =>
                      handleClick(`/user/${displayName}/${uid}/movies`)
                    }
                  >
                    <DrawerClose>{displayName}</DrawerClose>
                  </Button>
                )}
                <Button
                  variant="ghost"
                  className="text-black text-base font-rubik-sans font-medium my-4 w-fit"
                  onClick={() => handleClick("/")}
                >
                  <DrawerClose>Home</DrawerClose>
                </Button>
                <DrawerClose>
                  <Button
                    variant="ghost"
                    className="text-black text-base font-rubik-sans font-medium my-4 w-fit"
                    onClick={() => handleClick("/movies")}
                  >
                    <DrawerClose>Movie</DrawerClose>
                  </Button>
                </DrawerClose>
                <DrawerClose>
                  <Button
                    variant="ghost"
                    className="text-black text-base font-rubik-sans font-medium my-4 w-fit"
                    onClick={() => handleClick("/tvs")}
                  >
                    <DrawerClose>TV Show</DrawerClose>
                  </Button>
                </DrawerClose>
              </div>
              <div className="flex flex-col mb-8">
                <Button
                  variant="ghost"
                  onClick={() => setOpenFeedback(true)}
                  className="text-black text-base font-rubik-sans font-medium py-6"
                >
                  Share Feedback & Join us
                </Button>
                <Button
                  variant="ghost"
                  className="text-black text-base font-rubik-sans font-medium py-6"
                  onClick={handleOpenEmail}
                >
                  Contact Us
                </Button>
                <div className="flex justify-evenly pt-6 w-3/4 mx-auto">
                  <a
                    target="_blank"
                    rel="no referrer"
                    href="https://twitter.com/cribitxyz"
                  >
                    <img src={XLogo} className="w-[50px] h-[50px]" />
                  </a>
                  <a
                    target="_blank"
                    rel="no referrer"
                    href="https://www.instagram.com/cribitxyz/"
                  >
                    <img src={InstagramLogo} className="w-[50px] h-[50px]" />
                  </a>
                  <a
                    target="_blank"
                    rel="no referrer"
                    href="https://discord.gg/d7WkxkYEpK"
                  >
                    <img src={DiscordLogo} className="w-[50px] h-[50px]" />
                  </a>
                </div>
              </div>
            </div>
            <DrawerFooter></DrawerFooter>
          </DrawerContent>
        </Drawer>
      </div>
      <Dialog open={openFeedback} onOpenChange={() => setOpenFeedback(false)}>
        <DialogTrigger asChild>
          <DialogContent className="sm:max-w-[425px] bg-[#FFDFB1] rounded-2xl">
            <Feedback onClose={() => setOpenFeedback(false)} />
          </DialogContent>
        </DialogTrigger>
      </Dialog>
    </>
  );
};

export default MobileNav;
