import React, { useState, useEffect, useRef } from "react";
import CarouselCard from "./CarouselCard";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "../ui/button";
import { getTrendingMovies, getTrendingTVs } from "../../firebase/fireBaseInit";
import { doc, getDoc, db } from "../../firebase/fireBaseInit";
import { useNavigate } from "react-router-dom";
import "./Carousel.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function LeftArrow(props) {
  const { style, onClick } = props;
  const [backgroundColor, setBackgroundColor] = useState("#FFFFFF");

  const handleClick = (e) => {
    setBackgroundColor("#F78F20");
    setTimeout(() => {
      setBackgroundColor("#FFFFFF");
    }, 1000);
    onClick();
  };

  return (
    <div
      style={{
        ...style,
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        display: "flex",
        opacity: onClick ? 1 : 0,
        pointerEvents: onClick ? "auto" : "none",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        // boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
        fontSize: "20px",
        color: "#000000",
        fontWeight: "900",
        zIndex: "1",
        position: "absolute",
        top: "50%",
        left: 0,
        transform: "translateY(-50%)",
        transition: "background-color 0.3s ease, opacity 0.2s ease",
        backgroundColor: backgroundColor,
      }}
      onClick={handleClick}
    >
      <ArrowBackIcon fontSize="medium" />
    </div>
  );
}

function RightArrow(props) {
  const { style, onClick } = props;
  const [backgroundColor, setBackgroundColor] = useState("#FFFFFF");

  const handleClick = (e) => {
    setBackgroundColor("#F78F20");
    setTimeout(() => {
      setBackgroundColor("#FFFFFF");
    }, 500);
    onClick();
  };

  return (
    <div
      style={{
        ...style,
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        display: "flex",
        opacity: onClick ? 1 : 0,
        pointerEvents: onClick ? "auto" : "none",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        // boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
        fontSize: "20px",
        color: "#000000",
        fontWeight: "900",
        zIndex: "1",
        position: "absolute",
        top: "50%",
        right: 0,
        transform: "translateY(-50%)",
        transition: "background-color 0.3s ease, opacity 0.2s ease",
        backgroundColor: backgroundColor,
      }}
      onClick={handleClick}
    >
      <ArrowForwardIcon fontSize="medium" />
    </div>
  );
}

const Carousel = ({ type }) => {
  const navigate = useNavigate();
  const [movieCarouselData, setMovieCarouselData] = useState();
  const [tvCarouselData, setTvCarouselData] = useState();
  const [cards, setCards] = useState([]);
  const slider = useRef();

  const settings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow: 5.7,
    slidesToScroll: 4,
    touchThreshold: 20,
    draggable: true,
    lazyLoad: false,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,
    responsive: [
      {
        breakpoint: 1900,
        settings: {
          slidesToShow: 4.7,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3.2,
          slidesToScroll: 2,
          lazyLoad: false,
          nextArrow: <></>,
          prevArrow: <></>,
        },
      },
    ],
  };

  const handleViewMore = () => {
    type === "movies" ? navigate("/movies") : navigate("/tvs");
  };

  const handleCardClick = (docID) => {
    type === "movies"
      ? navigate(`/MovieDetail/${docID}`)
      : navigate(`/TVDetail/${docID}`);
  };

  const fetchRatingsByDocId = async (docId) => {
    try {
      const avgRatingDocRef =
        type == "movies"
        ? doc(db, "movies", docId, "ratings", "Average")
        : doc(db, "TVs", docId, "ratings", "Average");

      const avgRatingDocSnap = await getDoc(avgRatingDocRef);
      if (!avgRatingDocSnap.exists()) return null;
      const averageRating = avgRatingDocSnap.data().average_rating;
      return averageRating ? parseFloat(averageRating.toFixed(2)) : null;
    } catch (error) {
      console.error("Error fetching average rating for docId:", docId, error);
      return null;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      let cachedData;
      let parsedData;
      if (type === "movies" && movieCarouselData?.length > 0) {
        setCards(movieCarouselData);
        return;
      } else if (type === "tvs" && tvCarouselData?.length > 0) {
        setCards(tvCarouselData);
        return;
      }

      cachedData =
        type === "movies"
        ? localStorage.getItem("carouselMoviesData")
        : localStorage.getItem("carouselTVsData");

      if (
        cachedData &&
        Date.now() - JSON.parse(cachedData).timestamp < 3600000
      ) {
        parsedData = JSON.parse(cachedData).data;
        setCards(parsedData);
        if (type === "movies") {
          setMovieCarouselData(parsedData);
        } else {
          setTvCarouselData(parsedData);
        }
        return;
      }

      try {
        let result;

        if (type === "movies") {
          result = await getTrendingMovies({ timeWindow: "week" });
        } else if (type === "tvs") {
          result = await getTrendingTVs({ timeWindow: "week" });
        } else {
          return null;
        }

        if (result.data && Array.isArray(result.data)) {
          const docsWithRatings = await Promise.all(
            result.data.map(async (card) => {
              const averageRating = await fetchRatingsByDocId(card.id);
              return {
                ...card,
                averageRating,
                title: card.name,
                imageUrl: card.cover_photo_url,
                id: card.id,
                seasons: card.number_of_seasons,
                duration: card.duration,
                year: card.start_date
                  ? new Date(card.release_date || card.start_date).getFullYear()
                  : "N/A",
              };
            })
          );

          setCards(docsWithRatings);
          type === "movies"
            ? setMovieCarouselData(docsWithRatings)
            : setTvCarouselData(docsWithRatings);

          const localStorageKey =
            type === "movies" ? "moviesCarouselData" : "tvsCarouselData";

          localStorage.setItem(
            localStorageKey,
            JSON.stringify({ timestamp: Date.now(), data: docsWithRatings })
          );
        } else {
          console.error("Unexpected data format from search:", result.data);
        }
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    };

    fetchData();
  }, [type, movieCarouselData, tvCarouselData]);
  // THIS SETS THE TOTAL LISTINGS
  const visibleCards = cards.slice(0, 20);
  const visibleExtraCards = cards.slice(20, 40);

  return (
    <div
      style={{
        width: "100%",
        overflowX: "hidden",
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "0px",
          marginTop: "0px",
          marginRight: "2vw",
          padding: "10px",
          position: "relative",
          zIndex: "10",
        }}
        className="bg-orange-100 p-5 "
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <h2 className="carousel-header">
            {type === "movies" ? (
              <span>Movies</span>
            ) : (
              <span>TV Shows</span>
            )}
          </h2>
        </div>
        <Button variant="ghost">
          <div className="view-more" onClick={handleViewMore}>
            see more
          </div>
        </Button>
      </div>
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <Slider key={cards.length} ref={slider} {...settings}>
            {visibleCards.map((card, index) => (
              <div key={index}>
                <CarouselCard
                  {...card}
                  onClick={() => handleCardClick(card.id)}
                />
              </div>
            ))}
          </Slider>
        </div>
        <div>
          <Slider ref={slider} {...settings}>
            {visibleExtraCards.map((card, index) => (
              <div key={index}>
                <CarouselCard
                  {...card}
                  onClick={() => handleCardClick(card.id)}
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
