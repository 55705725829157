import React, { useState } from 'react';
import '@fontsource/rubik/400.css';
import '@fontsource/rubik/500.css';
import '@fontsource/rubik/700.css';
import './SignInSignUp.scss';
import { useNavigate } from 'react-router-dom';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';


const ResetConfirmation = (props) => {
    const navigate = useNavigate(); 

    const handleClick = async (e) => {
        e.preventDefault();
        navigate("/forgot");
      }

    return (
        <div className="form-container">
          <div className="form-wrapper">
            <box className="form reset">
                <h2 className="form-title reset">Password Reset Link sent!</h2>
                <p className="form-input-label reset">Please check your Email and follow the instructions.</p>
                <p className="form-input-label reset">The link will expire in 24 hours.</p>
                <div className="button-wrapper checkmark">
                    <CheckCircleOutlineRoundedIcon style={{ height: '100px', width: '100px', color: '#FFB54C' }} />
                </div>
                <div className="alt-account-wrapper">
                    <p className="black-text resend">
                        Didn't receive the code?
                    </p>
                    <button onClick={handleClick} type="button" className="button-text resend">
                        Click to resend code.
                    </button>
                </div>
            </box>
          </div>
        </div>
    );
}

export default ResetConfirmation;