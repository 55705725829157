import React from "react";
import StarFull from "../../logos/StarFull.png";

const RatingBar = ({ avgRating }) => {
  const avgPercent = Math.round(avgRating * 10);
  const progressBar = avgPercent + 5;

  return (
    <div className="flex items-center justify-center w-full my-4 text-orange-500">
      <div className="flex h-[5px] relative bg-slate-50 rounded items-center mobile:w-full tablet:w-1/2">
        <div
          className="flex items-center justify-end h-full rounded bg-gradient-to-r from-orange-500 to-orange-300"
          style={{ width: `${progressBar}%` }}
        >
          <img
            src={StarFull}
            alt="Full Star"
            style={{
              width: "50px",
              height: "50px",
              position: "absolute",
              left: `calc(${progressBar}% - 25px)`,
            }}
          />
        </div>
      </div>
      <div className="text-xl font-bold ml-4"> {avgPercent}% </div>
    </div>
  );
};

export default RatingBar;
