import React, { useState, useContext, useEffect } from "react";
import { addBookmark, deleteBookmark } from "src/firebase/fireBaseInit";
import { Stack, Button } from "@mui/material/";
import IconButton from "@mui/material/IconButton";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { AuthContext } from "src/components/authContext";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { MOVIE_TYPE } from "src/const";
import {
  Dialog,
  DialogContent,
  DialogContent2,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import LikePopup from "../LikePopup";
import { useSelector } from "react-redux";
import { DialogDescription, DialogTrigger } from "@radix-ui/react-dialog";
import "@fontsource/rubik/400.css";
import "@fontsource/rubik/500.css";
import { useNavigate } from "react-router-dom";
import SignInForm from "../forms/SignInForm";

const SignInDialog = ({
  isSignInDialogOpen,
  setIsSignInDialogOpen,
  seenStyle,
  handleSaveClick,
  isSaved,
  iconSize,
  bp,
}) => {
  return (
    <Dialog
      className="sign-in-dialog-wrapper"
      open={isSignInDialogOpen}
      onOpenChange={setIsSignInDialogOpen}
    >
      <DialogTrigger className="">
        <IconButton // BOOKMARK/SAVE
          disableRipple
          aria-label="save"
          sx={seenStyle}
          onClick={handleSaveClick}
        >
          <BookmarkIcon
            sx={{
              color: isSaved ? "rgb(241, 170, 40)" : "rgb(83, 83, 83)",
              height: iconSize === bp ? "16px" : "1em",
              width: iconSize === bp ? "16px" : "1em",
            }}
          />
        </IconButton>
      </DialogTrigger>
      <DialogContent className={iconSize === bp ? "rounded-2xl p-0 pb-6 border-orange-500 border-4" : "rounded-2xl p-0 border-orange-500 border-4"}>
        <SignInForm isModal={true} />
      </DialogContent>
    </Dialog>
  );
};

const UserActionButtons = ({
  type,
  id,
  title,
  comment,
  setUserDescription,
  listingDetail = false,
  setListingDetailState = null,
}) => {
  const [isSaved, setIsSaved] = useState(false);
  const [isSeen, setIsSeen] = useState(false);
  const [isLiked, setIsLiked] = useState(null);
  const [isLoved, setIsLoved] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [likeState, setLikeState] = useState(false);
  const [dislikeState, setDislikeState] = useState(false);
  const [loveState, setLoveState] = useState(false);
  const [seenState, setSeenState] = useState(false);
  const [isWhySubmissionOpen, setIsWhySubmissionOpen] = useState(false);
  const [isSignInDialogOpen, setIsSignInDialogOpen] = useState(false);
  const authContext = useContext(AuthContext);
  const isAnonymous = authContext && authContext.isAnonymous;
  const navigate = useNavigate();
  const { pageNumber } = useSelector((state) => state.onboarding);
  const [mediaType, setMediaType] = useState(MOVIE_TYPE);

  if (process.env.NODE_ENV === "development") {
    // console.log(`[UserActionButtons] rendered with type=${type}, id=${id}, title=${title}`)
  }

  useEffect(() => {
    if (type && type.toLowerCase().includes("tv")) setMediaType("tv");
  }, [type]);

  useEffect(() => {
    setIsLoved(false);
    setIsLiked(null);
    setIsSeen(false);
    setIsSaved(false);
  }, [pageNumber]);

  const uid = useSelector((state) => state.user.user.uid);
  const listingId = id;

  const isMobile = window.innerWidth <= 767;
  const isTablet = window.innerWidth >= 768;
  const bp = "small";
  const iconSize = listingDetail ? "medium" : isMobile ? "small" : "large";
  const iconsizeMobile = "32px";
  const iconsizeMobilePopup = "42px";
  const iconsizeDesktop = "48px";

  const handleLikeClick = () => {
    if (!isLiked) {
      setOpenPopup(true);
      setLikeState(true);
      setDislikeState(false);
      setLoveState(false);
      setIsLoved(false);
      if (listingDetail) {
        setListingDetailState("liked");
      }
    }
    setIsLiked(isLiked === true ? null : true);
  };

  const handleDislikeClick = () => {
    if (isLiked || isLiked === null) {
      setOpenPopup(true);
      setDislikeState(true);
      setLikeState(false);
      setLoveState(false);
      setIsLoved(false);
      if (listingDetail) {
        setListingDetailState("disliked");
      }
    }
    setIsLiked(isLiked === false ? null : false);
  };

  const handleLoveClick = () => {
    if (!isLoved) {
      setOpenPopup(true);
      setLoveState(true);
      setDislikeState(false);
      setIsLiked(null);
      if (listingDetail) {
        setListingDetailState("loved");
      }
    }
    setIsLoved(isLoved === false ? true : false);
  };

  const handleSeenClick = () => {
    if (isSeen) {
      // cancel seen
      deleteBookmark({ type: mediaType, id: id, listType: "seen" })
    } else {
      // add seen
      addBookmark({ type: mediaType, id: id, op: "seen" })
      setOpenPopup(true);
      setSeenState(true);
    }
    setIsSeen(!isSeen);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
    setLikeState(false);
    setDislikeState(false);
    // setIsWhySubmissionOpen(true);
  };

  const handleWhySubmit = (comment, like, dislike) => {
    if (setUserDescription) {
      setUserDescription(comment);
      console.log("submitted this comment: ", comment);
    }
    console.log("submitted this listingId: ", listingId);
    const userId = uid;
    console.log("submitted this UID: ", uid);
    const type = MOVIE_TYPE;
    console.log("submitted this type: ", type);

    let op;
    if (likeState === true) {
      // Check the state variable, not the setter function
      op = "like";
    } else if (dislikeState === true) {
      // Assuming you want to check if it's disliked
      op = "dislike";
    } else if (loveState === true) {
      op = "favorite";
    }

    console.log("submitted this op: ", op);

    // GTM - Push the event to the dataLayer after reaction submission
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'reactionSubmit',
      'userId': uid,
    });

    addBookmark({ type: mediaType, id: id, op: op, comment: comment })
      .then((result) => {
        if (result.data) {
          console.log("Bookmark updated successfully.");
          setOpenPopup(false); // Close the popup on success
        } else {
          console.error("Failed to update bookmark.");
          // TODO: make sure the button state is consistent with the server on failure
        }
      })
      .catch((error) => {
        // Check if error is out-of-range error
        if (error.code === "out-of-range") {
          alert("You can have a max of 5 favorites. Remove some from your profile.");
        }
        console.error("Error updating bookmark:", error);
      });
    setIsWhySubmissionOpen(true);
  };

  const handleSaveClick = () => {
    if (isAnonymous) {
      if (process.env.NODE_ENV === "development") {
        console.log(
          "[UserActionButtons] User is not signed in on save click. uid=",
          uid
        );
      }
      setIsSignInDialogOpen(true);
      return; // user not allowed to save to watchlist if anonymous
    }
    if (isSaved) {
      deleteBookmark({ type: mediaType, id: id, listType: "wishlist"})
    }
    else {
      addBookmark({ type: mediaType, id: id, op: "wishlist"})
        .then((result) => {
          if (result.data) {
          console.log("[UserActionButtons] Bookmark updated successfully. op=wishlist.");
            // GTM - Push the event to the dataLayer after successful wishlist
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
            'event': 'watchlist',
            'userId': uid,
            });
          } else {
            console.error("Failed to update bookmark.");
            // TODO: make sure the button state is consistent with the server on failure
          }
        })
        .catch((error) => {
          console.error("Error updating bookmark:", error);
        });
    }

    setIsSaved(!isSaved);
  };

  const iconButtonStyle = {
    borderRadius: "50%",
    backgroundColor: "white",
    padding: "8px",
    // margin: (isLiked !== null || !isSeen) ? "0 3px" : iconSize === bp ? "0 .5px" : "0 4px", // mvp
    margin: listingDetail ? "0 6px" : "0 2px",
    minHeight: iconsizeMobilePopup,
    width: iconSize === bp ? iconsizeMobilePopup : iconsizeDesktop,
    height: iconSize === bp ? iconsizeMobilePopup : iconsizeDesktop,
    boxShadow:
      iconSize === bp
        ? "0 0 10px rgba(0, 0, 0, 0.3), inset 0 0 5px rgba(0, 0, 0, 0.2)"
        : "0 0 10px rgba(0, 0, 0, 0.3)",
    cursor: "pointer",
    transition: "background-color 0.2s",
    "&:hover": {
      boxShadow:
        "0 0 10px rgba(0, 0, 0, 0.3), inset 0 0 5px rgba(0, 0, 0, 0.2)", // Inset box-shadow on hover
      backgroundColor: iconSize === bp ? "" : "rgb(230, 230, 230)",
    },
  };

  const seenStyle = {
    borderRadius: "50%",
    backgroundColor: "white",
    padding: "0 ",
    minWidth: iconsizeMobile,
    width: iconSize === bp ? iconsizeMobile : iconsizeDesktop,
    height: iconSize === bp ? iconsizeMobile : iconsizeDesktop,
    // margin: (isLiked !== null || !isSeen) ? "0 3px" : iconSize === bp ? "0 .5px" : "0 4px",
    margin: listingDetail ? "0 6px" : iconSize === bp ? "0 2px" : "0 4px",
    color: isSeen === false ? "rgb(83, 83, 83)" : "#FF9500",
    fontFamily: "Rubik",
    fontSize: iconSize === bp ? "8px" : "small",
    fontWeight: "500",
    boxShadow:
      iconSize === bp
        ? "0 0 10px rgba(0, 0, 0, 0.3), inset 0 0 5px rgba(0, 0, 0, 0.2)"
        : "0 0 10px rgba(0, 0, 0, 0.3)",
    cursor: "pointer",
    transition: "background-color 0.2s",
    "&:hover": {
      boxShadow:
        "0 0 10px rgba(0, 0, 0, 0.3), inset 0 0 5px rgba(0, 0, 0, 0.2)",
      backgroundColor: iconSize === bp ? "" : "rgb(230, 230, 230)",
    },
  };

  return (
    <div>
      {/* Why like/dislike popup dialog */}
      <Dialog
        open={openPopup}
        onOpenChange={setOpenPopup}
        onClose={handleClosePopup}
      >
        <DialogContent2
          className="bg-orange-100 h-3/7 rounded-3xl content-center min-w-0 min-h-0"
          style={{ fontFamily: "Rubik" }}
        >
          <DialogTitle
            className="text-brown-600 items-start text-xl font-bold m-0 tracking-wide"
            style={{ lineHeight: "1.5rem" }}
          >
            How did you like:
            <span style={{ color: "darkOrange" }}> {title}?</span>
          </DialogTitle>
          <DialogDescription>
            <div className="flex justify-evenly items-center">
              <IconButton
                disableRipple
                aria-label="like"
                sx={iconButtonStyle}
                onClick={handleLikeClick}
              >
                <ThumbUpIcon
                  //   fontSize={iconSize === bp ? "small" : "md"}
                  sx={{
                    color: isLiked ? "rgb(89, 212, 89)" : "rgb(83, 83, 83)",
                    height: iconSize === bp ? "20px" : "1em",
                    width: iconSize === bp ? "20px" : "1em",
                  }}
                />
              </IconButton>
              <IconButton
                disableRipple
                aria-label="dislike"
                sx={iconButtonStyle}
                onClick={handleDislikeClick}
              >
                <ThumbDownIcon
                  //   fontSize={iconSize === bp ? "small" : "md"}
                  sx={{
                    color:
                      isLiked === false
                        ? "rgb(255, 108, 108)"
                        : "rgb(83, 83, 83)",
                    height: iconSize === bp ? "20px" : "1em",
                    width: iconSize === bp ? "20px" : "1em",
                  }}
                />
              </IconButton>
              <IconButton
                disableRipple
                aria-label="favorite"
                sx={iconButtonStyle}
                onClick={handleLoveClick}
              >
                <FavoriteIcon
                  //   fontSize={iconSize === bp ? "small" : "md"}
                  sx={{
                    color: isLoved === true ? "#FF9500" : "rgb(83, 83, 83)",
                    height: iconSize === bp ? "20px" : "1em",
                    width: iconSize === bp ? "20px" : "1em",
                  }}
                />
              </IconButton>
            </div>
          </DialogDescription>
          <LikePopup onClose={handleClosePopup} onSubmit={handleWhySubmit} />
        </DialogContent2>
      </Dialog>

      <Stack
        direction="row"
        width="100%"
        justifyContent="center"
        // justifyContent="space-evenly"
        // alignItems="center"
        // spacing={0}
        marginBottom={listingDetail ? "" : iconSize === bp ? "5px" : "10px"}
      >
        {!isAnonymous ? (
          <IconButton // BOOKMARK/SAVE
            disableRipple
            aria-label="save"
            sx={seenStyle}
            onClick={handleSaveClick}
          >
            <BookmarkIcon
              // fontSize={iconSize === bp ? "small" : "md"}
              sx={{
                color: isSaved ? "rgb(241, 170, 40)" : "rgb(83, 83, 83)",
                height: iconSize === bp ? "16px" : "1em",
                width: iconSize === bp ? "16px" : "1em",
              }}
            />
          </IconButton>
        ) : (
          <SignInDialog
            isSignInDialogOpen={isSignInDialogOpen}
            setIsSignInDialogOpen={setIsSignInDialogOpen}
            seenStyle={seenStyle}
            handleSaveClick={handleSaveClick}
            isSaved={isSaved}
            iconSize={iconSize}
            bp={bp}
          />
        )}
        <IconButton
          disableRipple
          aria-label="mark as seen"
          variant="text"
          sx={seenStyle}
          onClick={handleSeenClick}
        >
          {isLoved ? (
            <FavoriteIcon
              //   fontSize={iconSize === bp ? "small" : "md"}
              sx={{
                color: isLoved === true ? "#FF9500" : "rgb(83, 83, 83)",
                height: iconSize === bp ? "20px" : "1em",
                width: iconSize === bp ? "20px" : "1em",
              }}
            />
          ) : isLiked ? (
            <ThumbUpIcon
              //   fontSize={iconSize === bp ? "small" : "md"}
              sx={{
                color: isLiked ? "rgb(89, 212, 89)" : "rgb(83, 83, 83)",
                height: iconSize === bp ? "20px" : "1em",
                width: iconSize === bp ? "20px" : "1em",
              }}
            />
          ) : !isLiked && isLiked !== null ? (
            <ThumbDownIcon
              sx={{
                color:
                  isLiked === false ? "rgb(255, 108, 108)" : "rgb(83, 83, 83)", // Adjust color as needed
                height: iconSize === bp ? "16px" : "1em", // Adjust size as needed
                width: iconSize === bp ? "16px" : "1em", // Adjust size as needed
              }}
            />
          ) : (
            <Button variant="text" sx={{...seenStyle, margin: ""}}>
              SEEN
            </Button>
          )}
        </IconButton>
      </Stack>
    </div>
  );
};

export default UserActionButtons;
