import { createSlice } from '@reduxjs/toolkit';

const userLocationSlice = createSlice({
  name: 'userLocation',
  initialState: {
    location: null,
    error: null,
  },
  reducers: {
    setUserLocation: (state, action) => {
      state.location = action.payload;
      state.error = null;
    },
    fetchUserLocationError: (state, action) => {
      state.error = action.payload;
    },
    updateUserLocation: (state, action) => {
        state.location = action.payload;
        state.error = null;
      },
  },
});


export const fetchUserLocation = () => (dispatch, getState) => {
  const state = getState();
  if (state.userLocation.location) {
    return;
  }

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        dispatch(setUserLocation({ latitude, longitude }));
      },
      (error) => {
        console.error("Error retrieving user location:", error);
        dispatch(fetchUserLocationError(error.message));
      }
    );
  } else {
    dispatch(fetchUserLocationError('Geolocation is not supported by this browser.'));
  }
};

export const { setUserLocation, fetchUserLocationError, updateUserLocation } = userLocationSlice.actions;

export default userLocationSlice.reducer;