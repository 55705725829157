import React from "react";
import SignInForm from "../components/forms/SignInForm";
import "../components/forms/SignInSignUp.scss";

const SignIn = () => {
  return (
    <div className="form-container">
      <SignInForm isModal={false} />
    </div>
  );
};

export default SignIn;
