import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getBookmarkContent } from "../firebase/fireBaseInit";

const initialState = {
  bookmarks: [],
  subfolders: [],
};

export const bookmarkSlice = createSlice({
  name: "bookmarks",
  initialState,
  reducers: {
    setBookmarks: (state, action) => {
      if (!state.bookmarks.includes(action.payload)) {
        state.bookmarks = [...state.bookmarks, action.payload];
      }
    },
    setSubfolders: (state, action) => {
      state.subfolders = action.payload;
      console.log(state.subfolders);
    },
  },
});
export const fetchBookmarks = createAsyncThunk(
  "bookmarks/fetchBookmarks",
  async (user, { dispatch }) => {
    try {
      if (user) {
        const response = await getBookmarkContent({
          uid: user.uid,
          listName: "/",
        });

        if (response && response.data && response.data.subfolders) {
          const subfolders = response.data.subfolders;
          const subfolderPromises = subfolders.map(async (folderName) => {
            const docResponse = await getBookmarkContent({
              uid: user.uid,
              listName: "/like_tvs",
            });

            if (docResponse && docResponse.data && docResponse.data.docs) {
              const titles = docResponse.data.docs;

              if (titles.length > 0) {
                titles.forEach((title) => {
                  if (title && title.id) {
                    dispatch(setBookmarks(title.id));
                  }
                });
              }
            }
          });
          await Promise.all(subfolderPromises);
        }
      }
    } catch (error) {
      console.error("Error fetching bookmarks:", error);
    }
  }
);

export const { setBookmarks, setSubfolders } = bookmarkSlice.actions;
export default bookmarkSlice.reducer;
