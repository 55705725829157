async function fetchAddressFromCoords(latitude, longitude) {
    const apiKey = 'AIzaSyAXLk5gykIEfzjbl_3cuoBlYBaTM4Q92D8'; // Replace with your actual API key
    try {
      const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`);
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        return data.results[0].formatted_address;  // Return the first result
      }
      return null;
    } catch (error) {
      console.error("Error fetching address from coordinates:", error);
      return null;
    }
  }
  
  export { fetchAddressFromCoords };
  