import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import RestaurantListing from '../components/listing/RestaurantListing';
import '@fontsource/rubik/400.css';
import '@fontsource/rubik/500.css';
import FoodFilter from '../components/listing/FoodFilter';
import { Grid, Typography, Box } from '@mui/material';
import SearchBarWithLocation from '../components/searchBar/SearchBarWithLocation';
import { CircularProgress } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { getLatLngFromAddress } from '../contexts/addressToCoord';
import { updateUserLocation } from '../redux/userLocationSlice';
import { fetchRestaurants } from '../redux/restaurantSlice';
import { fetchBookmarks } from '../redux/bookmarksSlice';
import { AuthContext } from '../components/authContext';

function debounce(func, delay) {
  let inDebounce;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
}

function Listing() {
  const dispatch = useDispatch();
  const { cards, categories, ratings, isLoading } = useSelector(state => state.restaurants);
  const [filters, setFilters] = React.useState({
    categories: [],
    distance: '2mi',
    price: '',
  });
  const [hasAppliedFilters, setHasAppliedFilters] = useState(false);
  const [searchQuery, setSearchQuery] = useState('restaurants');
  const navigate = useNavigate();
  const userLocation = useSelector(state => state.userLocation.location);
  const latitude = userLocation?.latitude;
  const longitude = userLocation?.longitude;
  const [searchLocation, setSearchLocation] = useState(`${latitude},${longitude}`);
  const [locationChangeFromSearchBar, setLocationChangeFromSearchBar] = useState(false);
  const [forceRegularSearch, setForceRegularSearch] = useState(false);

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const user = useContext(AuthContext);

  useEffect(() => {
    dispatch(fetchBookmarks(user));
  }, [dispatch]);

  const debouncedSetSearchQuery = useCallback(
    debounce((value) => setSearchQuery(value), 300),
    []
  );

  const handleCardClick = (docID) => {
    navigate(`/listing/${docID}`);
  };

  const handleFilterChange = (filter) => {
    setFilters(prevFilters => {
      return {
        ...prevFilters,
        [filter.type]: filter.value
      };
    });
    setHasAppliedFilters(true);
  };


  const handleCategoryChange = (category) => {
    setFilters(prevFilters => {
      return { ...prevFilters, categories: [category] };
    });
    setSearchQuery(category); 
    setHasAppliedFilters(true);
  };

  const handleSortChange = (sortValue) => {
    console.log("Sorting changed to:", sortValue);
  }

  useEffect(() => {
    if (latitude && longitude) {
      setSearchLocation(`${latitude},${longitude}`);
    }
  }, [latitude, longitude]);

  useEffect(() => {
    if (longitude && latitude && !locationChangeFromSearchBar) {
      const locationToUse = userLocation?.latitude ? `${userLocation.latitude},${userLocation.longitude}` : searchLocation;
      dispatch(fetchRestaurants({ searchQuery, location: locationToUse, filters, forceRegularSearch }));
    }
    // Reset the flag after fetching
    if(locationChangeFromSearchBar) {
      setLocationChangeFromSearchBar(false);
    }
  }, [dispatch, searchQuery, userLocation, searchLocation, filters, forceRegularSearch, locationChangeFromSearchBar]);
  

  return (
    <div style={{ padding: '0', margin: '0', backgroundColor: '#F7F7F7', minHeight: '100vh' }}>
      <Grid container spacing={0} style={{ marginLeft: '0', marginRight: '0', marginTop: '0' }}>
        <Grid item xs={12} md={3} style={{ paddingTop: '54px' }}>
          <FoodFilter
            onFilterChange={handleFilterChange}
            onCategoryChange={handleCategoryChange}
            onSortChange={handleSortChange}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <Box display="flex" flexDirection="column" alignItems="flex-start" mb={2}>
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <Typography
                variant="h5"
                style={{
                  color: '#F57903',
                  marginBottom: '2vh',
                  marginTop: '10vh',
                  marginLeft: '1vw',
                  fontFamily: 'Rubik',
                  fontWeight: '500',
                  flex: '0.5',
                }}
              >
                Restaurants near me
              </Typography>
              <SearchBarWithLocation
                length="30ch"
                style={{ marginTop: '9vh', marginBottom: '2vh', flex: '0.5' }}
                onQueryChange={debouncedSetSearchQuery}

                onLocationChange={async (address) => {
                  console.log("this is the address passed in: ", address);
                  try {
                    const { latitude, longitude } = await getLatLngFromAddress(address);
                    console.log(`Dispatching updateUserLocation with payload:`, { latitude, longitude });
                    dispatch(updateUserLocation({ latitude, longitude }));
                    setLocationChangeFromSearchBar(true);
                    // Dispatch the thunk with new location
                    dispatch(fetchRestaurants({
                      searchQuery,
                      location: `${latitude},${longitude}`,
                      filters,
                      forceRegularSearch
                    }));
                  } catch (error) {
                    console.error("Failed to geocode address:", error);
                  }
                }}
                
                onSearch={() => {
                  setForceRegularSearch(true);  
                  setHasAppliedFilters(false);   
                  dispatch(fetchRestaurants({
                    searchQuery,
                    location: searchLocation, 
                    filters,
                    forceRegularSearch
                  }));
                }}
                
              />
            </div>
            {isLoading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="calc(100vh - 50px)"
                width="100%"
              >
                <CircularProgress style={{ color: '#FFA500' }} />
              </Box>
            ) : (
              <RestaurantListing onCardClick={handleCardClick} />
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default Listing;