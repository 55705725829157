import React, { Component, useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db, doc, getDoc, getDocs, collection } from '../firebase/fireBaseInit';
import { Box, Button, IconButton, Typography, Grid, Divider, Dialog, DialogTitle } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import '@fontsource/rubik/400.css';
import '@fontsource/rubik/500.css';
import '@fontsource/rubik/700.css';
import placeholder from '../Pics/placeholder.png';
import { functions, auth, updateUser } from '../firebase/fireBaseInit';
import { AuthContext } from '../components/authContext';
import { getAuth } from "firebase/auth";

const StyledForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'center',
  // alignItems: 'center',
  height: '50vh',
  marginTop: '2vh',
  marginLeft: '1vw',
  background: 'transparent',
});

const StyledContainer = styled('div')({
  width: '50vw',
  background: 'white',
  padding: '40px',
  borderRadius: '12px',
  border: '3px',
  borderStyle: 'solid',
  borderColor: 'transparent',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const StyledFormGroup = styled('div')({
  marginBottom: '40px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  color: 'rgba(0, 0, 0, 0.80)',
  fontFamily: 'Rubik',
  fontSize: '18px',
  fontWeight: '400',
  lineHeight: '27.59765625px',
  '& label': {
    marginBottom: '1vh',
  },
});

const StyledRadioGroup = styled('div')({
  display: 'flex',
});

const StyledRadioOption = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  marginRight: '20px',
  '& input[type="radio"]': {
    appearance: 'none',
    border: '2px solid rgba(84, 84, 84, 1)',
    borderRadius: '70%',
    width: '18px',
    height: '18px',
    marginRight: '8px',
    transition: 'background-color 0.3s',
    '&:checked': {
      backgroundColor: 'black',
    },
  },
  '& span': {
    fontSize: '16px',
    color: 'Black',
  },
}));

const StyledInput = styled('input')(({ value }) => ({
  marginBottom: '10px',
  width: '100%',
  height: '30px',
  borderRadius: '20px',
  border: '2px',
  borderStyle: 'solid',
  borderColor: '#E0E0E0',
  padding: '8px',
  fontSize: '16px',
  background: 'white',
  color: value ? 'black' : 'grey',
  fontFamily: 'Rubik'
}));


const StyledButton = styled('button')({
  width: '120px',
  height: '40px',
  background: '#FFB54C',
  padding: '2px',
  fontFamily: 'Rubik',
  fontSize: '18px',
  fontWeight: '500',
  borderRadius: '20px',
  border: 'none',
  color: 'black',
  alignSelf: 'flex-end',
  cursor: 'pointer',
});

const UserSetting = () => {

  const [gender, setGender] = useState('');
  const [newUsername, setUsername] = useState('');
  const [age, setAge] = useState('');
  const authContext = useContext(AuthContext);
  const userEmail = authContext.email;
  const uid = authContext.uid;

  const [openFeedback, setOpenFeedback] = useState(false);

    const handleFeedbackClick = () => {
        setOpenFeedback(true);
    };

    const handleCloseFeedback = () => {
        setOpenFeedback(false);
    };

  const handleSubmit = (e) => {
    console.log('handling submit')
    e.preventDefault();

    if (!gender || !age) {
      // This is just an example. You might want to introduce a new state variable
      // for formErrors and use setFormErrors to manage its state.
      const formErrors = {
        gender: !gender ? "Please select a gender" : "",
        age: !age ? "Please select an age range" : "",
      };
      console.log(formErrors);
      return;
    }

    console.log('The form was submitted with the following data:');

    // Prepare the data to be sent to the backend
    const userData = {
      gender: gender,
      username: newUsername,
      age: age,
      // email: userEmail
    };
    console.log('printing useremail', userData.userEmail);
    // Call the updateUser function
    updateUser(userData)
      .then(result => {
        console.log(result);
        console.log("sign up!"); 
      })
      .catch(error => {
        console.error('Error updating user: ', error);
      });
  }

  const handleNameChange = (newName) => {
    setUsername(newName)
  }

  const handleGenderChange = (selectedGender) => {
    setGender(selectedGender)
  }

  const handleAgeChange = (selectedAge) => {
    setAge(selectedAge)
  }

  useEffect(() => {
    const fetchUsername = async () => {
      const userRef = doc(db, 'users', uid);
      const userSnap = await getDoc(userRef);

      // userSnap.forEach((doc) => {
      //   console.log('username:', doc.data())
      // })
      if (userSnap.exists()) {
        console.log('user info:', userSnap.data());
        setUsername(userSnap.data().username);
        // console.log(userSnap);
      } else {
          console.log("Document does not exist!");
      }
      
    };
    fetchUsername();

  }, [uid]);
  
  
  return (
  <div style={{background: '#F8F8F8', width: '100vw', height: '100vh'}}>
    <Grid container spacing={2} sx={{ paddingTop: '15vh', marginBottom: '30vh' }}>
      <Grid item xs={2}>
        <div style={{width: '100%'}}>
          {/* <div style={{marginLeft: '8vw'}}>
          <img src={placeholder} alt="avatar" style={{ width: '7vw', height: '7vw' }} />
          </div> */}
          <div style={{ textAlign: 'center', color: '#00171F', fontFamily: 'Rubik', fontWeight: '500', fontSize: '22px', marginTop: '2vh'}}>
              {newUsername}
          </div>
          <div style={{ textAlign: 'center', color: '#00171F', fontFamily: 'Rubik', fontWeight: '400', fontSize: '16px', marginTop: '2vh'}}>
              {userEmail}
          </div>
        </div>
      </Grid>
      <Grid item xs={1}>
        <Divider orientation="vertical" sx={{ borderRightWidth: 2 }} />
      </Grid>
      <Grid item xs={9}>
        <div style={{flexDirection: 'column'}}>
          <span style={{color: '#00171F', fontFamily: 'Rubik', fontWeight: '500', fontSize: '20px', marginLeft: '1vw', marginTop: '12.5vh'}}>
            Profile
          </span>
          <StyledForm onSubmit={handleSubmit}>
          <StyledContainer>
            <StyledFormGroup>
              <div style={{display: 'flex'}}>
                <label htmlFor="username" style={{ fontSize: '16px', marginRight: '0.5vw', display: 'flex', alignItems: 'center', color: 'rgba(0, 0, 0, 0.80)', fontFamily: 'Rubik', fontWeight: '500', textAlign: 'left' }}>
                  Name
                </label>
                {/* <input id="username" name="username" onChange={() => handleNameChange(newUsername)} style={{ width: '100%', height: '60px', background: 'rgba(238, 238, 238, 0)', borderRadius: '10px', border: '2px', borderStyle: 'solid', borderColor: '#9E9E9E', boxSizing: 'border-box', fontSize: '16px', fontFamily: 'Rubik', fontWeight: '400' }} /> */}
                <input id="username" name="username" onChange={(e) => handleNameChange(e.target.value)} style={{ width: '100%', height: '60px', background: 'rgba(238, 238, 238, 0)', borderRadius: '10px', border: '2px', borderStyle: 'solid', borderColor: '#9E9E9E', boxSizing: 'border-box', fontSize: '16px', fontFamily: 'Rubik', fontWeight: '400' }}/>
              </div>
            </StyledFormGroup>
            {/* <StyledFormGroup>
                <div style={{display: 'flex'}}>
                  <label htmlFor="pic" style={{ fontSize: '16px', marginRight: '1vw', display: 'flex', alignItems: 'center', color: 'rgba(0, 0, 0, 0.80)', fontFamily: 'Rubik', fontWeight: '500', textAlign: 'left' }}>
                    Profile Picture
                  </label>
                  <img src={placeholder} alt="avatar" style={{ width: '7vw', height: '7vw' }} />
                </div>
            </StyledFormGroup> */}
            <StyledFormGroup>
              <StyledRadioGroup>
                <label htmlFor="gender" style={{fontSize: '16px', marginRight: '0.5vw', fontWeight: '500'}}>Gender</label>
                <StyledRadioOption onClick={() => handleGenderChange('Male')}>
                  <input type="radio" id="gender" checked={gender === 'Male'} readOnly />
                  <span>Male</span>
                </StyledRadioOption>
                <StyledRadioOption onClick={() => handleGenderChange('Female')}>
                  <input type="radio" id="gender" checked={gender === 'Female'} readOnly />
                  <span>Female</span>
                </StyledRadioOption>
                <StyledRadioOption onClick={() => handleGenderChange('Other')}>
                  <input type="radio" id="gender" checked={gender === 'Other'} readOnly />
                  <span>Other</span>
                </StyledRadioOption>
              </StyledRadioGroup>
            </StyledFormGroup>
            <StyledFormGroup>
              <StyledRadioGroup>
                <span htmlFor="age" style={{fontSize: '16px', marginRight: '0.5vw', fontWeight: '500'}}>Age</span>
                <StyledRadioOption onClick={() => handleAgeChange('12-17')}>
                  <input type="radio" id="age" checked={age === '12-17'} readOnly />
                  <span>12-17</span>
                </StyledRadioOption>
                <StyledRadioOption onClick={() => handleAgeChange('18-34')}>
                  <input type="radio" id="age" checked={age === '18-34'} readOnly />
                  <span>18-34</span>
                </StyledRadioOption>
                <StyledRadioOption onClick={() => handleAgeChange('35-54')}>
                  <input type="radio" id="age" checked={age === '35-54'} readOnly />
                  <span>35-54</span>
                </StyledRadioOption>
                <StyledRadioOption onClick={() => handleAgeChange('54+')}>
                  <input type="radio" id="age" checked={age === '54+'} readOnly />
                  <span>54+</span>
                </StyledRadioOption>
              </StyledRadioGroup>
            </StyledFormGroup>
            <StyledButton type="submit" onClick={handleFeedbackClick} style={{cursor: 'pointer'}}>Save</StyledButton>
          </StyledContainer>
          </StyledForm>
        </div>
        <div style={{flexDirection: 'column', marginTop: '10vh'}}>
          <span style={{color: '#00171F', fontFamily: 'Rubik', fontWeight: '500', fontSize: '20px', marginLeft: '1vw'}}>
            Account
          </span>
        </div>
        <div>
          <Button component={Link} to="/forgot" style={{justifyContent: "flex-start", fontFamily: 'Rubik', fontWeight: '500', marginTop: '2vh', marginLeft: '1vw', width: '55vw', height: '5vh', color: '#00171F', textTransform: 'capitalize', cursor: 'pointer', background: 'white', borderRadius: '12px', border: '3px', borderStyle: 'solid', borderColor: 'transparent', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',}}>
            Change Password
          </Button>
        </div>
      </Grid>
      <Dialog 
      open={openFeedback} 
      onClose={handleCloseFeedback} 
      PaperProps={{ 
          style: { 
              borderRadius: '20px', 
              maxWidth: '80%', 
              width: '500px'  // Set your preferred width here
          } 
      }}>
        <DialogTitle style={{ fontFamily: 'Rubik', fontWeight: '500', color: 'orange' }}>Changes Saved!</DialogTitle>
      </Dialog>
    </Grid>
  </div>
  )

}

export default UserSetting;