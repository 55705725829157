import { createTheme } from '@mui/material';

const cribitTheme = createTheme({
    palette: {
      primary: {
        main: '#FFA500',
        contrastText: '#000',
      },
      simplyblack: {
        main: '#000',
      },
    },
    typography: {
        button: {
          textTransform: 'none'
        }
      }
  });

export default cribitTheme;