import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { db, collection, onSnapshot } from "../firebase/fireBaseInit";
import { updateMovieRatings } from "../redux/moviesSlice";
import { updateTVRatings } from "../redux/tvSlice";
import { TYPE_DEFINITIONS, MOVIE_TYPE } from "src/const";

const useRatingsListener = (listingIds, listingType) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      console.log(
        `[useRatingsListener] listingIds: ${JSON.stringify(
          listingIds
        )}, listingType: ${listingType}`
      );
    }

    if (!listingIds || listingIds.length === 0) return;

    const unsubscribers = listingIds.map((listingId) => {
      if (listingId === undefined) {
        return () => {};
      }
      if (!listingType || !(listingType in TYPE_DEFINITIONS)) {
        throw new Error(
          `[useRatingsListener] Invalid listingType: ${listingType}`
        );
      }
      const collectionName = TYPE_DEFINITIONS[listingType].collectionName;
      const ratingsCollectionRef = collection(
        db,
        collectionName,
        listingId,
        "ratings"
      );
      return onSnapshot(ratingsCollectionRef, (doc) => {
        const ratingsData = {};
        doc.forEach((doc) => {
          ratingsData[doc.id] = doc.data();
        });
        if (process.env.NODE_ENV === "development") {
          console.log(
            `[useRatingsListener] new ratingsData: ${JSON.stringify(
              ratingsData
            )}`
          );
        }
        const action =
          listingType === MOVIE_TYPE ? updateMovieRatings : updateTVRatings;
        dispatch(action({ listingId, ratings: ratingsData }));
      });
    });

    return () => {
      console.log(`[useRatingsListener] unmounting`);
      unsubscribers.forEach((unsub) => unsub());
    };
  }, [JSON.stringify(listingIds), listingType]);
};

export default useRatingsListener;
