import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../Pics/logo.svg";
import Mouth from "../Pics/Mouth.png";
import introSlide1 from "../Pics/Intro1.png";
import introSlide2 from "../Pics/Intro2.png";
import introSlide3 from "../Pics/Intro3.png";
import { Button } from "src/components/ui/button";
import {
  Carousel,
  CarouselContent,
  CarouselDots,
  CarouselItem,
} from "../components/ui/carousel";
import CloseIcon from "@mui/icons-material/Close";
import { OnboardingCard } from "src/components/Onboarding/Onboarding";
import { useDispatch, useSelector } from "react-redux";
import {
  addIntroRecommendation,
  fetchRecommendations,
  resetIntroRecommendations,
  setDidSubmit,
  setIsPaywallLoading,
  setIsSubmitDialogOpen,
  updateIntroRecommendations,
} from "src/redux/onboardingSlice";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { MOVIE_TYPE } from "src/const";

const Welcome = () => {
  const navigate = useNavigate();
  const [fadeOut, setFadeOut] = useState(false);

  const handleFadeOut = () => {
    setFadeOut(true);
    setTimeout(() => {
      navigate("/welcome/intro");
      setFadeOut(false);
    }, 1500);
  };

  const backgroundStyles = {
    transition: "opacity 1.5s ease-out",
    opacity: fadeOut ? 0 : 1,
  };

  return (
    <div>
      <Header />

      <div
        className="bg-orange-100 w-full flex justify-center h-full flex-col items-center text-white"
        style={backgroundStyles}
      >
        <div className="flex flex-col text-brown-600">
          <div className="text-[42px] font-semibold p-6 pt-0 leading-tight">
            YOUR <br />
            PERSONAL <br />
            CONTENT <br />
            WHISPERER
          </div>
          <div className="font-medium text-base px-6">
            Use AI to discover movies & shows tailored to your specific tastes
          </div>
          <div className="flex justify-center p-5">
            <img src={Mouth} alt="logo" className="w-75 h-auto" />
          </div>
          <div className="flex flex-col items-center justify-center w-full mb-32">
            <Button
              className="w-[240px] h-[46px] rounded-3xl bg-orange-500 text-xl text-brown-600 shadow-md shadow-slate-500 mb-4 leading-3 hover:bg-orange-350"
              onClick={handleFadeOut}
            >
              Get Started
            </Button>
            <Button
              className="w-[240px] h-[46px] rounded-3xl bg-brown-600 text-xl text-orange-50 shadow-md shadow-slate-500 mb-4"
              onClick={() => navigate("/sign-in")}
            >
              Sign In
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const WelcomeIntro = () => {
  const navigate = useNavigate();
  const [fade, setFade] = useState(false);

  useEffect(() => {
    setTimeout(() => setFade(true), [150]);
  }, []);

  const handleStartClick = () => {
    setFade(false);
    setTimeout(() => navigate("/welcome/intro/select"), 1000);
  };

  const introSlides = [
    {
      img: introSlide1,
      bgColor: "#3B1704",
      textColor: "orange-100",
      text: "Tell us your favorite picks and, in a few words, why.",
    },
    {
      img: introSlide2,
      bgColor: "#C3510A",
      textColor: "orange-100",
      text: "Our AI analyzes your reviews to match your tastes.",
    },
    {
      img: introSlide3,
      bgColor: "#FFC47E",
      textColor: "brown-600",
      text: "The more you share, the better our picks become.",
    },
  ];

  return (
    <div>
      <Header />
      <div
        className={`bg-orange-100 flex flex-col text-white ease-in transition-opacity duration-700 ${
          fade ? "opacity-100" : "opacity-0"
        }`}
      >
        <div className="text-[32px] text-brown-600 font-semibold px-8 py-4 leading-normal">
          How Do We Know What You'll Love?
        </div>
        <Carousel>
          <CarouselContent>
            {Array.from(introSlides).map((slide, index) => (
              <CarouselItem key={index} className="flex flex-col">
                <img src={slide.img} alt="logo" />
                <span
                  className={`text-${slide.textColor} font-rubik-sans text-xl font-medium p-8`}
                  style={{ backgroundColor: slide.bgColor }}
                >
                  {slide.text}
                </span>
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselDots className="m-4" />
        </Carousel>
        <div className="flex flex-row items-center justify-center mt-3">
          <Button
            className="w-[240px] h-[46px] rounded-3xl bg-orange-350 text-xl text-brown-600 font-semibold shadow-md shadow-slate-500 leading-3 hover:bg-orange-700"
            onClick={handleStartClick}
          >
            Try It Out
          </Button>
        </div>
      </div>
    </div>
  );
};

const SelectPage = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Header />
      <div className="w-full h-screen font-medium ">
        <span className="text-xl leading-normal px-4 text-brown-600">
          Select one of the following:
        </span>
        <div className="buttons-wrapper w-full h-3/4 flex flex-col items-center justify-evenly">
          <div
            className="rounded-full bg-orange-200 h-[200px] w-[200px] flex justify-center items-center text-[32px] text-brown-600 shadow-gradient-shadow hover:shadow-xxl hover:bg-gradient-br transition duration-1000 cursor-pointer"
            onClick={() => navigate("/welcome/intro/select/movie")}
          >
            MOVIES
          </div>
          <div
            className="rounded-full bg-orange-200 h-[200px] w-[200px] flex flex-col justify-center items-center text-[32px] text-brown-600 shadow-gradient-shadow hover:shadow-xxl hover:bg-gradient-br transition duration-1000 cursor-pointer"
            onClick={() => navigate("/welcome/intro/select/TV")}
          >
            <span className="flex items-center">TV</span>
            <span className="flex items-center">SHOWS</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const AddFavsPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [isError, setIsError] = useState(false);
  const { pageNumber, recommendationCards } = useSelector(
    (state) => state.onboarding
  );

  const dispatch = useDispatch();
  const { type } = useParams();

  useEffect(() => {
    const incomplete = recommendationCards?.some(
      (item) => item.id === "" || item.text === ""
    );
    setIsFormComplete(!incomplete);
  }, [recommendationCards]);

  const onboardingCardChange = (index, data) => {
    dispatch(updateIntroRecommendations({ index, data }));
  };

  const handleAddClick = () => {
    if (recommendationCards?.length < 3) {
      dispatch(addIntroRecommendation());
    }
  };

  const handleSubmit = async () => {
    if (isFormComplete) {
      // GTM - Push the event to the dataLayer after initial onboarding
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'favOnboarding',
      });
      navigate("/");
      dispatch(setIsPaywallLoading(true));
      setLoading(true);
      const transformedItems = recommendationCards.map((item) => ({
        id: item.id,
        comment: item.text,
      }));
      // return response from API promise
      const response = await dispatch(
        fetchRecommendations({
          favs: transformedItems,
          type,
          page: pageNumber,
          limit: 8,
        })
      );
      setLoading(false);
      dispatch(resetIntroRecommendations());
      // if error object exits on response, display an alert
      if (!!response?.error) {
        window.alert("There was an error. Please try again later.");
      } else {
        dispatch(setIsPaywallLoading(false));
        dispatch(setDidSubmit(true));
        dispatch(setIsSubmitDialogOpen(true));
        navigate("/");
      }
    } else {
      setIsError(true);
    }
  };

  return (
    <div>
      <Header />
      <div
        className="back-btn-wrapper flex flex-row ml-5"
        onClick={() => navigate(-1)}
      >
        <ArrowBackIcon />
        <div className="font-rubik-sans font-medium text-brown-600 ml-2">
          Back
        </div>
      </div>
      <div className="font-rubik-sans font-medium text-brown-600 text-xl leading-normal ml-3 mt-4">
        Tell us your{" "}
        <span className="text-orange-700">
          favorite {type === "TV" ? " show" : MOVIE_TYPE}(s)
        </span>
        , and in a few words,
        <span className="text-orange-700"> why </span>
        you like it:
      </div>

      <div className="onboarding-card-wrapper flex flex-col justify-center items-center mt-8 laptop:flex-row">
        {recommendationCards?.map((item, index) => (
          <OnboardingCard
            key={index}
            item={item}
            type={type}
            onChange={(data) => onboardingCardChange(index, data)}
          />
        ))}
      </div>
      <div className="flex flex-col items-center">
        {isError && (
          <div className="text-red-600 font-medium p-4">
            Submission requires title and text input. Please try again with a
            simple reason.
          </div>
        )}
        <Button
          className="bg-orange-350 w-3/5 h-full flex justify-center items-center rounded-full font-medium text-brown-600 text-xl my-4 hover:bg-orange-350 hover:text-brown-600"
          disabled={loading || recommendationCards.length === 3}
          onClick={handleAddClick}
        >
          Add another
        </Button>
        <Button
          disabled={loading}
          type="submit"
          onClick={handleSubmit}
          className="bg-brown-600 w-3/5 h-full flex justify-center items-center rounded-full font-medium text-orange-100 text-xl my-4"
        >
          <span>Finish</span>
        </Button>
      </div>
    </div>
  );
};

const Header = () => {
  const navigate = useNavigate();
  return (
    <div className="logo-wrapper bg-orange-100 px-2 py-4 flex justify-between">
      <img src={logo} alt="logo" className="w-[90px] h-[45px] ml-4" />
      <Button
        className="flex items-end bg-transparent border-none shadow-none text-[#472a00] font-bold underline underline-offset-2 py-0 px-4 hover:bg-transparent hover:scale-105 transition-transform duration-300"
        onClick={() => navigate("/")}
      >
        <CloseIcon fontSize="medium" />
      </Button>
    </div>
  );
};

const LandingPage = () => {
  const { intro, select, type } = useParams();

  return (
    <div className="page-wrapper bg-orange-100 font-rubik-sans pb-60">
      {intro && select && type ? (
        <AddFavsPage />
      ) : select ? (
        <SelectPage />
      ) : intro ? (
        <WelcomeIntro />
      ) : (
        <Welcome />
      )}
    </div>
  );
};

export default LandingPage;
