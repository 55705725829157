import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";

const db = getFirestore();

// This function fetches the docId using a given yelpId
export const getYelpIdToDocId = async (yelpId) => {
    try {
        const q = query(collection(db, "restaurants"), where("yelpId", "==", yelpId));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            // Assuming there's only one document with the yelpId, return the first doc's ID
            return querySnapshot.docs[0].id;
        } else {
            console.error(`No document found for yelpId: ${yelpId}`);
            return null;
        }
    } catch (error) {
        console.error("Error fetching docId for yelpId: ", yelpId, " Error: ", error);
        return null;
    }
};
