import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  signInWithPopup, 
  GoogleAuthProvider
} from "firebase/auth";
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  getDocs,
  query,
  orderBy,
  startAfter,
  limit,
  where,
  onSnapshot,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";
import { connectFirestoreEmulator } from "firebase/firestore";
import { connectAuthEmulator } from "firebase/auth";
import { connectFunctionsEmulator } from "firebase/functions";

var LOCAL = false;

if (process.env.NODE_ENV === "development") {
  LOCAL = true;
}

const firebaseConfig = {
  apiKey: "AIzaSyAXLk5gykIEfzjbl_3cuoBlYBaTM4Q92D8",
  authDomain: "cribit-da69f.firebaseapp.com",
  projectId: "cribit-da69f",
  storageBucket: "cribit-da69f.appspot.com",
  messagingSenderId: "892592795258",
  appId: "1:892592795258:web:0cbcde7cba12cfa390d5c3",
 // measurementId: "G-QRJ58NGR3Z",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);

if (LOCAL) {
  console.log("Connecting to the Firestore emulator");
  connectFirestoreEmulator(db, "localhost", 8080);
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFunctionsEmulator(functions, "localhost", 5001);
  console.log("Connected!");
}

// Initialize the callable functions
const updateUser = httpsCallable(functions, "updateUser");
const deleteUser = httpsCallable(functions, "deleteUser");
const restaurantSearch = httpsCallable(functions, "restaurantSearch");
const addReview = httpsCallable(functions, "addReview");
const deleteReview = httpsCallable(functions, "deleteReview");
const pressLikeOnReview = httpsCallable(functions, "pressLikeOnReview");
const updateReview = httpsCallable(functions, "updateReview");
const restaurantFilteredSearch = httpsCallable(
  functions,
  "restaurantFilteredSearch"
);
const movieSearch = httpsCallable(functions, "movieSearch");
const movieSearchLite = httpsCallable(functions, "movieSearchLite");
const getRecommendations = httpsCallable(functions, "getRecommendations", {timeout: 300000});
const getTrendingMovies = httpsCallable(functions, "getTrendingMovies");
const getTrendingTVs = httpsCallable(functions, "getTrendingTVs");
const addBookmark = httpsCallable(functions, "addBookmark");
const addBookmarkFolder = httpsCallable(functions, "addBookmarkFolder");
const getBookmarkContent = httpsCallable(functions, "getBookmarkContent");
const deleteBookmarkFolder = httpsCallable(functions, "deleteBookmarkFolder");
const deleteBookmark = httpsCallable(functions, "deleteBookmark");
const calculateDistance = httpsCallable(functions, "calculateDistance");
const addUserFeedback = httpsCallable(functions, "addUserFeedback");
const TVSearch = httpsCallable(functions, "TVSearch");
const TVSearchLite = httpsCallable(functions, "TVSearchLite");
const getTVsByGenre = httpsCallable(functions, "getTVsByGenre");
const getMoviesByGenre = httpsCallable(functions, "getMoviesByGenre");
const migrateUser = httpsCallable(functions, "migrateUser");

const logout = async () => {
  try {
    await signOut(auth);
    console.log("Logged out successfully.");
  } catch (error) {
    console.error("Error logging out:", error);
  }
};

const sendResetPasswordEmail = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    console.log("Password reset email sent successfully.");
  } catch (error) {
    console.error("Error sending password reset email:", error);
  }
};

export {
  auth,
  db,
  analytics,
  query,
  limit,
  startAfter,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  functions,
  updateUser,
  deleteUser,
  restaurantSearch,
  addReview,
  deleteReview,
  pressLikeOnReview,
  updateReview,
  doc,
  getDoc,
  collection,
  getDocs,
  onSnapshot,
  restaurantFilteredSearch,
  movieSearch,
  movieSearchLite,
  getRecommendations,
  getTrendingMovies,
  getTrendingTVs,
  logout,
  sendResetPasswordEmail,
  addBookmark,
  addBookmarkFolder,
  getBookmarkContent,
  calculateDistance,
  addUserFeedback,
  TVSearch,
  TVSearchLite,
  deleteBookmark,
  deleteBookmarkFolder,
  getTVsByGenre,
  getMoviesByGenre,
  where,
  orderBy,
  migrateUser,
  signInWithPopup, 
  GoogleAuthProvider,
};
