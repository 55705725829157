import React, { useEffect, useState } from "react";
import { Skeleton, Stack, Button, ClickAwayListener } from "@mui/material/";
// import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import { addBookmark } from "src/firebase/fireBaseInit";
import "@fontsource/rubik/400.css";
import "@fontsource/rubik/500.css";
import { useSelector } from "react-redux";
import "@fontsource/alata/400.css";
import "./CarouselCard.scss";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../ui/dialog";
import UserActionButtons from "./UserActionButtons";
import { MOVIE_TYPE } from "src/const";

const CarouselCard = ({
  id,
  onClick,
  imageUrl,
  cover_photo_url,
  name,
  title,
  // year,
  // seasons,
  // genres = [],
  averageRating,
  average_rating,
  recommend_reason,
  type,
  isLocked,
  isRefreshClicked,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isLiked, setIsLiked] = useState(null);
  const { onboardingType } = useSelector((state) => state.onboarding);
  const mediaType = type === "recommend" ? onboardingType : type;

  const navigate = useNavigate();

  useEffect(() => {
    setIsLiked(null);
  }, [isRefreshClicked]);

  // reroutes to listing detail if type is recommend
  const handleCardClick = () => {
    let path;
    if (onboardingType === MOVIE_TYPE) {
      path = `/MovieDetail/${id}`;
    } else if (onboardingType === "TV") {
      path = `/TVDetail/${id}`;
    } else {
      return null;
    }
    navigate(path);
  };

  const isMobile = window.innerWidth <= 767;
  const isTablet = window.innerWidth >= 768;
  const iconSize = isMobile ? "medium" : "large";

  // var dependencies
  const bp = "medium";

  const goStyle = {
    borderRadius: "50px",
    backgroundColor: "#FF9500",
    padding: "8px",
    width: "300px",
    height: iconSize === bp ? "30px" : "60px",
    color: "white",
    fontFamily: "Rubik",
    fontSize: iconSize === bp ? "medium" : "x-large",
    fontWeight: "500",
    boxShadow:
      iconSize === bp
        ? "0 0 10px rgba(0, 0, 0, 0.3), inset 0 0 5px rgba(0, 0, 0, 0.2)"
        : "0 0 10px rgba(0, 0, 0, 0.3)",
    cursor: "pointer",
    transition: "background-color 0.3s",
    "&:hover": {
      boxShadow:
        "0 0 10px rgba(0, 0, 0, 0.3), inset 0 0 5px rgba(0, 0, 0, 0.2)",
      backgroundColor: "#FFB54C",
    },
  };

  const labelStyle = {
    display: "inline-block",
    margin: "5px",
    padding: "5px 10px",
    borderRadius: "16px",
    backgroundColor: "#FFFFFF",
    color: "#00171F",
    fontFamily: "Rubik",
    fontWeight: "500",
    fontSize: "14px",
  };
  const avgPercent = averageRating
    ? Math.round(averageRating * 10)
    : Math.round(average_rating * 10);
  const progressBar = avgPercent + 5;

  // NOT FOR BETA --- maybe for mvp, not sure yet
  // const renderReason = () => {
  //   if (!recommend_reason) return "";

  //   const characterLimit = isMobile ? 75 : 150;
  //   const trimmedText = recommend_reason.slice(0, characterLimit);

  //   if (recommend_reason.length > characterLimit) {
  //     const lastSpaceIndex = trimmedText.lastIndexOf(" ");
  //     return trimmedText.slice(0, lastSpaceIndex) + "...";
  //   }

  //   return trimmedText;
  // };

  return (
    <div
      className={`${
        type === "recommend" && !isLocked
          ? "text-center mobile:m-1 laptop:m-2 rounded-t-2xl rounded-b-lg bg-white bg-opacity-55 mobile:h-[450px] tablet:h-[625px]"
          : "m-1"
      }`}
    >
      <div
        className={
          "img-rec-wrapper " +
          (type === "recommend"
            ? "m-0"
            : type !== "recommend" && isMobile
            ? "m-0"
            : "m-2")
        }
        onClick={
          type === "recommend" && !isLocked && !isTablet
            ? handleCardClick
            : null
        }
      >
        <ClickAwayListener
          onClickAway={() => setIsHovered(false)} // Set isHovered to false when tapped outside card
        >
          <div
            className="carousel-card-wrapper"
            onMouseEnter={
              type !== "recommend" ? () => setIsHovered(true) : null
            }
            onMouseLeave={
              type !== "recommend" ? () => setIsHovered(false) : null
            }
            // onClick={() => !isTablet && isLiked === null ? setIsHovered(!isHovered) : null} we will revisit this another time...
          >
            {imageUrl || cover_photo_url ? (
              <div>
                <img
                  src={imageUrl || cover_photo_url}
                  alt={title || name}
                  className={
                    isHovered || (type === "recommend" && isLocked)
                      ? "filter blur-md border-0 carousel-card"
                      : "unlocked carousel-card"
                  }
                  onClick={
                    type === "recommend" && !isLocked ? handleCardClick : null
                  }
                  style={{
                    cursor: type === "recommend" && !isLocked ? "pointer" : "",
                  }}
                />
              </div>
            ) : (
              <Skeleton variant="rectangular" width="100%" height="100%" />
            )}
            {/* hover-card */}
            <div
              className={`${
                type === "recommend" && isLocked
                  ? "filter blur-md border-0"
                  : "unlocked carousel-card"
              } hover-card ${
                (imageUrl && isHovered && type !== "recommend") ||
                (!isLocked && imageUrl && isHovered && type === "recommend")
                  ? "visible"
                  : ""
              }`}
            >
              {/* Bookmark, seen, like and dislike buttons  */}
              <UserActionButtons
                type={mediaType}
                id={id}
                title={type !== "recommend" ? title : name}
              />

              {/* GO button */}
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  aria-label="see details"
                  variant="text"
                  sx={goStyle}
                  onClick={onClick}
                >
                  GO
                </Button>
              </Stack>
            </div>

            {type !== "recommend" && avgPercent && (
              <div
                style={{
                  position: "absolute",
                  width: "111%",
                  bottom: "0",
                  alignItems: "center",
                  zIndex: "1",
                }}
              >
                {isTablet ? (
                  <div
                    className="percentage-desktop"
                    style={{
                      left: 6,
                      color:
                        avgPercent >= 85
                          ? "#E54D03"
                          : avgPercent >= 70 && avgPercent < 85
                          ? "#FF9500"
                          : avgPercent >= 50 && avgPercent < 70
                          ? "#FFDFB1"
                          : "#EEEEEE",
                      fontSize: avgPercent >= 85 ? "36px" : "24px",
                      position: "absolute",
                      bottom: avgPercent >= 85 ? "-10px" : "-5px",
                      fontWeight: "500",
                      zIndex: "999",
                    }}
                  >
                    {avgPercent < 1 || isNaN(avgPercent)
                      ? "N/A"
                      : `${avgPercent}%`}
                  </div>
                ) : (
                  <div
                    className="percentage-mobile"
                    style={{
                      left: 6,
                      color:
                        avgPercent >= 85
                          ? "#E54D03"
                          : avgPercent >= 70 && avgPercent < 85
                          ? "#FF9500"
                          : avgPercent >= 50 && avgPercent < 70
                          ? "#FFDFB1"
                          : "#EEEEEE",
                      fontSize: avgPercent >= 85 ? "18px" : "15px",
                      position: "absolute",
                      bottom: avgPercent >= 85 ? "-3px" : "-3px",
                      fontWeight: "500",
                      zIndex: "999",
                    }}
                  >
                    {avgPercent < 1 || isNaN(avgPercent)
                      ? "N/A"
                      : `${avgPercent}%`}
                  </div>
                )}
                {/* </div> */}
                {/* </div> */}
              </div>
            )}
          </div>
        </ClickAwayListener>
        {type === "recommend" && !isLocked && (
          <div className="no-scrollbar mx-2 my-4 tablet:px-4 text-left leading-normal h-28 tablet:h-32 overflow-auto mobile:text-xs tablet:text-sm laptop:text-base">
            {/* NOT FOR BETA */}
            {/* {renderReason()} */}
            {recommend_reason}
          </div>
        )}
      </div>

      {type === "recommend" && !isLocked && (
        <UserActionButtons
          type={onboardingType}
          id={id}
          title={type !== "recommend" ? title : name}
        />
      )}
    </div>
  );
};
export default CarouselCard;
