import { React, useState, useEffect, useContext } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Box, Typography, Grid, IconButton } from "@mui/material";
import { db, doc, getDoc, getDocs, collection } from "../firebase/fireBaseInit";
import "@fontsource/rubik/400.css";
import "@fontsource/rubik/500.css";
import "@fontsource/rubik/700.css";
import "@fontsource/alata/400.css";
import "@fontsource/abel/400.css";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import ShareClickButton from "../components/listing/ShareClickButton";
import IMDBLogo from "../logos/IMDB-L.png";
import LetterboxdLogo from "../logos/letterboxd-L.png";
import RottenTomatoesLogo from "../logos/tomatoes-L.png";
import RottenTomatoesAudienceLogo from "../logos/tomatoes_audience-L.png";
import StarRatingDisplayMovie from "../components/listing/StarRatingDisplayMovie";
import StarFull from "../logos/StarFull.png";
import { useModal } from "../components/listing/ModalContext";
import CrewList from "../components/listing/CrewList";
import BookmarkDropdown from "../components/listing/BookmarkDropdown";
import { AuthContext } from "../components/authContext";
import { fetchBookmarks, setBookmarks } from "../redux/bookmarksSlice";
import { useDispatch, useSelector } from "react-redux";
// import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../components/ui/dialog";
import { addBookmark } from "../firebase/fireBaseInit";
import LikePopup from "../components/LikePopup";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";

import "./DetailPage.scss";
import { ThumbUp } from "@mui/icons-material";
import RatingBar from "src/components/listing/RatingBar";
import UserActionButtons from "src/components/listing/UserActionButtons";
import { MOVIE_TYPE, TV_TYPE } from "src/const";

const DetailPage = ({ detailType }) => {
  const [movieDetail, setMovieDetail] = useState({});
  const [tvDetail, setTvDetail] = useState({});
  const { docId } = useParams();
  const [reviews, setReviews] = useState([]); // State for reviews
  const [imdbRating, setImdbRating] = useState(0);
  const [letterboxdRating, setLetterboxdRating] = useState(0);
  const [rtMeterRating, setRtMeterRating] = useState(0);
  const [rtAudienceRating, setRtAudienceRating] = useState(0);
  const [imdbReviews, setImdbReviews] = useState(0);
  const [letterboxdReviews, setLetterboxdReviews] = useState(0);
  const [rtMeterReviews, setRtMeterReviews] = useState(0);
  const [rtAudienceReviews, setRtAudienceReviews] = useState(0);
  const [averageRating, setAverageRating] = useState(0);
  const [totalReviews, setTotalReviews] = useState(0);
  const { showModal, hideModal, isModalOpen } = useModal();
  const [bookmarked, setBookmarked] = useState(false);
  const [genres, setGenres] = useState([]);
  const [year, setYear] = useState([]);
  const [seasons, setSeasons] = useState("");
  const [overview, setOverview] = useState("");
  const [duration, setDuration] = useState("");
  const [crew, setCrew] = useState("");
  const [awards, setAwards] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [imdbURL, setImdbURL] = useState("");
  const [rottenTomatoesURL, setRottenTomatoesURL] = useState("");
  const [letterboxdURL, setLetterboxdURL] = useState("");
  const [isInBookmarks, setIsInBookmarks] = useState(false);
  const [bookmarkClickCount, setBookmarkClickCount] = useState(0);
  const [thumbUpCount, setThumbUpCount] = useState(0);
  const [thumbDownCount, setThumbDownCount] = useState(0);
  const [openPopup, setOpenPopup] = useState(false);
  const [isWhySubmissionOpen, setIsWhySubmissionOpen] = useState(false);
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);
  const [totalLikeCount, setTotalLikeCount] = useState(0);
  const [listingDetailState, setListingDetailState] = useState("loved");

  const authContext = useContext(AuthContext);
  const isAnonymous = authContext && authContext.isAnonymous;

  const uid = useSelector((state) => state.user.user.uid);
  const navigate = useNavigate();

  const detail = detailType === MOVIE_TYPE ? movieDetail : tvDetail;

  useEffect(() => {
    const listingRef = doc(
      db,
      detail.type === "movies" ? "movies" : "tvs",
      docId
    );

    getDoc(listingRef).then((getSnapshot) => {
      if (getSnapshot.exists) {
        const data = getSnapshot.data();
        setTotalLikeCount(data?.likeCount);
      }
    });
  });

  const listingId = docId;

  const handleClosePopup = () => {
    setOpenPopup(false);
    // setIsWhySubmissionOpen(true);
  };

  const [userDescription, setUserDescription] = useState("");
  const [inputDescription, setInputDescription] = useState("");

  const handleWhySubmit = (newDescription, like, dislike) => {
    setUserDescription(newDescription);
    console.log("submitted this listingId: ", listingId);
    const userId = uid;
    console.log("submitted this UID: ", uid);
    const type = MOVIE_TYPE;
    console.log("submitted this type: ", type);
    console.log("submitted this comment: ", newDescription);

    let op;
    if (liked === true) {
      // Check the state variable, not the setter function
      op = "like";
    } else if (disliked === true) {
      // Assuming you want to check if it's disliked
      op = "dislike";
    }

    console.log("submitted this op: ", op);

    addBookmark({ type: type, id: docId, op: op, comment: userDescription })
      .then((result) => {
        if (result) {
          console.log("Bookmark updated successfully.");
          setOpenPopup(false); // Close the popup on success
        } else {
          console.error("Failed to update bookmark.");
        }
      })
      .catch((error) => {
        console.error("Error updating bookmark:", error);
      });
    setIsWhySubmissionOpen(true);
  };

  const bookmarks = useSelector((state) => state.bookmarks.bookmarks);
  const dispatch = useDispatch();

  const user = useContext(AuthContext);

  useEffect(() => {
    const isInBookmarks = bookmarks.includes(docId);
    setIsInBookmarks(isInBookmarks);
    dispatch(fetchBookmarks(user));
  }, [bookmarks, dispatch]);

  useEffect(() => {
    const fetchDetail = async () => {
      const docRef =
        detailType === MOVIE_TYPE
          ? doc(db, "movies", docId)
          : doc(db, "TVs", docId);
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        detailType === MOVIE_TYPE ? setMovieDetail(data) : setTvDetail(data);
        setGenres(data.genres || []);
        setAwards(data.AwardName || []);
        setOverview(data.overview || "");
        detailType === TV_TYPE && setSeasons(data?.number_of_seasons);

        const duration = data?.duration || 0;
        const hours = Math.floor(duration / 60);
        const minutes = duration % 60;
        setDuration({ hours, minutes });

        const releaseDate =
          detailType === MOVIE_TYPE
            ? data?.release_date || ""
            : data?.start_date || "";
        const year = releaseDate.split("-")[0];
        setYear(year);
      } else {
        console.log("Document does not exist!");
      }
    };

    fetchDetail();
  }, [docId]);

  // Fetch the reviews
  useEffect(() => {
    const fetchReviews = async () => {
      const reviewsRef = collection(db, "reviews");
      const reviewsSnapshot = await getDocs(reviewsRef);
      const reviewsList = reviewsSnapshot.docs
        .filter((doc) => doc.data().listing_id === docId)
        .map((doc) => doc.data());
      setReviews(reviewsList);
    };

    fetchReviews();
  }, [docId]);

  useEffect(() => {
    const fetchRatings = async () => {
      try {
        const ratingsRef =
          detailType === MOVIE_TYPE
            ? collection(doc(db, "movies", docId), "ratings")
            : collection(doc(db, "TVs", docId), "ratings");
        const ratingsSnapshot = await getDocs(ratingsRef);

        ratingsSnapshot.forEach((doc) => {
          const data = doc.data();
          console.log(data);
          switch (doc.id) {
            case "IMDB":
              if (data.average_rating) setImdbRating(data.average_rating);
              if (data.number_of_reviews)
                setImdbReviews(data.number_of_reviews);
              if (data.url) setImdbURL(data.url);
              break;
            case "Letterboxd":
              if (data.average_rating) setLetterboxdRating(data.average_rating);
              if (data.number_of_reviews)
                setLetterboxdReviews(data.number_of_reviews);
              if (data.url) setLetterboxdURL(data.url);
              break;
            case "RottenTomatoe":
              if (data.tomatometer) setRtMeterRating(data.tomatometer);
              if (
                data.number_of_tomatometer_reviews ||
                data.num_tomatometer_rating
              )
                setRtMeterReviews(
                  data.number_of_tomatometer_reviews ||
                    data.num_tomatometer_rating
                );
              if (data.audience_rating)
                setRtAudienceRating(data.audience_rating);
              if (data.number_of_audience_review || data.num_audience_rating)
                setRtAudienceReviews(
                  data.number_of_audience_review || data.num_audience_rating
                );
              if (data.url) setRottenTomatoesURL(data.url);
              break;
            case "Average":
              if (data.average_rating) setAverageRating(data.average_rating);
              if (data.total_reviews) setTotalReviews(data.total_reviews);
              break;
            default:
              console.warn(`Unexpected doc id: ${doc.id}`);
          }
        });
      } catch (error) {
        console.error("Error fetching ratings:", error); // Log any errors
      }
    };

    fetchRatings();
  }, [docId]);

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, [userDescription]);

  const handleShareClick = () => navigator.clipboard.writeText(window.location);

  function formatNumber(number) {
    if (number < 1000) {
      return number;
    } else if (number >= 1000 && number < 1e6) {
      return (number / 1000).toFixed(1) + "K";
    } else if (number >= 1e6 && number < 1e9) {
      return (number / 1e6).toFixed(1) + "M";
    } else if (number >= 1e9 && number < 1e12) {
      return (number / 1e9).toFixed(1) + "B";
    } else {
      return (number / 1e12).toFixed(1) + "T";
    }
  }

  const renderRating = (rating, scale, numberOfReviews) => {
    if (rating) {
      return (
        <>
          <div className="render-rating__wrapper">
            <div className="render-rating__rating">
              {scale === "%" ? `${rating}%` : rating}
            </div>
            {scale !== "%" && (
              <div className="render-rating__scale">{`/${scale}`}</div>
            )}
          </div>

          {numberOfReviews && (
            <div className="render-rating__reviews text-nowrap">
              {`${formatNumber(numberOfReviews)} ${
                numberOfReviews === 1 ? "rating" : "ratings"
              }`}
            </div>
          )}
        </>
      );
    }
    return <div className="render-rating__no-reviews">N/A</div>;
  };

  const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "400px",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "8px",
          }}
        >
          {children}
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    );
  };

  const trailerLink = detail.trailer_url;
  const embedTrailer = trailerLink
    ? trailerLink.replace(/\/watch\?v=/g, "/embed/")
    : null;
  const isTrailerLinkEmpty =
    !embedTrailer || embedTrailer === "https://www.youtube.com/embed/";

  const redirectToURL = (url) => {
    window.open(url, "_blank");
  };

  const handleThumbUpCount = () => {
    setThumbUpCount((prevCount) => prevCount + 1);
  };
  const getThumbUpColor = () => {
    return thumbUpCount % 2 === 1 ? "rgb(89, 212, 89)" : "rgb(83, 83, 83)";
  };
  const handleThumbDownCount = () => {
    setThumbDownCount((prevCount) => prevCount + 1);
  };
  const getThumbDownColor = () => {
    return thumbDownCount % 2 === 1 ? "rgb(255, 108, 108)" : "rgb(83, 83, 83)";
  };
  const handleBookmarkCount = () => {
    setBookmarkClickCount((prevCount) => prevCount + 1);
  };
  const getBookmarkColor = () => {
    return bookmarkClickCount % 2 === 1
      ? "rgb(241, 170, 40)"
      : "rgb(83, 83, 83)";
  };

  const avgPercent = Math.round(averageRating * 10);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="page-wrapper">
      {isTrailerLinkEmpty ? (
        <div className="detail-cover__web">
          <img
            src={detail.cover_photo_url || "default_image_url"}
            alt={detail.name || "Movie Title"}
          />
        </div>
      ) : (
        <div className="img-trailer__web">
          <iframe
            src={embedTrailer}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        </div>
      )}
      {isTrailerLinkEmpty ? (
        <div className="detail-cover__mobile">
          <img
            src={detail.cover_photo_url || "default_image_url"}
            alt={detail.name || "Movie Title"}
          />
        </div>
      ) : (
        <div className="img-trailer__mobile">
          <iframe
            src={embedTrailer}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        </div>
      )}
      <div className="detail-container">
        <div className="header-wrapper">
          <div className="title-wrapper">
            <div className="absolute left-1">
              <IconButton onClick={() => navigate(-1)}>
                <ArrowBackIos className="text-[#472A00]" />
              </IconButton>
            </div>
            <div className="flex flex-row items-center">
              <div className="title">{detail.name || "Movie Title"}</div>
            </div>
            <div className="info-wrapper">
              <div className="info-wrapper__duration">
                {detailType === MOVIE_TYPE ? (
                  <span x={console.log(year)}>
                    {year === "N/A" || year === "" ? "" : `${year} · `}
                    {duration.hours}h {duration.minutes}min
                  </span>
                ) : (
                  <span>
                    {year === "N/A" || year === "" ? "" : `${year} · `}
                    {seasons}{" "}
                    <span>{seasons === 1 ? "Season" : "Seasons"}</span>
                  </span>
                )}
              </div>
            </div>
            <div className="genres">
              <div>{detailType === MOVIE_TYPE ? "Movie" : "TV Show"}</div>
              {genres.map((genre) => (
                <div key={genre}>{genre}</div>
              ))}
            </div>
          </div>

          <Modal isOpen={isModalOpen} onClose={hideModal}>
            <h2>Select Folder</h2>
          </Modal>
        </div>
        <div className="mt-2">
          <div className="avg-rating" style={{ color: "#472A00" }}>
            Average Rating &#40;{formatNumber(totalReviews)}&#41;
          </div>

          <RatingBar avgRating={averageRating} />
        </div>
        {/* <Divider /> */}
        <div className="all-ratings-wrapper mb-2" style={{ color: "#472A00" }}>
          {/* IMDb */}
          <div
            className="imdb-lb-wrapper"
            onClick={() => redirectToURL(imdbURL)}
          >
            {imdbRating > 0 && (
              <div className="ratings__wrapper imdb">
                <div className="ratings__logo">
                  <img src={IMDBLogo} alt="IMDB" width="35px" />
                </div>
                <div className="ratings__score">
                  {renderRating(imdbRating, 10, imdbReviews)}
                </div>
              </div>
            )}
            {/* Letterboxd */}
            {detailType === MOVIE_TYPE && letterboxdRating > 0 && (
              <div
                className="ratings__wrapper letter"
                onClick={() => redirectToURL(letterboxdURL)}
              >
                <div className="ratings__logo">
                  <img src={LetterboxdLogo} alt="IMDB" width="30px" />
                </div>
                <div className="ratings__score">
                  {renderRating(letterboxdRating, 5, letterboxdReviews)}
                </div>
              </div>
            )}
          </div>
          {/*RottenTomatoes*/}
          <div className="rotten-wrapper">
            {rtMeterRating > 0 && (
              <div
                className="ratings__wrapper rotten"
                onClick={() => redirectToURL(rottenTomatoesURL)}
              >
                <div className="ratings__logo">
                  <img
                    src={RottenTomatoesLogo}
                    alt="Rotten Tomatoes Meter"
                    width="30px"
                  />
                </div>
                <div className="ratings__score">
                  {rtMeterReviews > 0
                    ? renderRating(rtMeterRating / 10, 10, rtMeterReviews)
                    : renderRating(rtMeterRating / 10, 10)}
                </div>
              </div>
            )}
            {rtAudienceRating > 0 && (
              <div
                className="ratings__wrapper audience"
                onClick={() => redirectToURL(rottenTomatoesURL)}
              >
                <div className="ratings__logo">
                  <img
                    src={RottenTomatoesAudienceLogo}
                    alt="Rotten Tomatoes Audience"
                    width="30px"
                  />
                </div>
                <div className="ratings__score">
                  {rtAudienceReviews > 0
                    ? renderRating(rtAudienceRating / 20, 5, rtAudienceReviews)
                    : renderRating(rtAudienceRating / 20, 5)}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="awards mobile:flex-col tablet:flex-row">
          {awards.map((award) => (
            <div key={award}>{award}</div>
          ))}
        </div>
        <div className="overview">{overview}</div>
        <CrewList
          directorIds={detail.director_ids || []}
          actorIds={detail.actor_ids || []}
        />
      </div>

      <div
        className="mt-5 mb-5 w-full h-full flex flex-col justify-center items-center p-4"
        style={{ backgroundColor: "rgba(71, 34, 0, 0.77)" }}
      >
        <UserActionButtons
          type={detailType}
          id={docId}
          title={detail.name}
          comment={userDescription}
          setUserDescription={setUserDescription}
          listingDetail={true}
          setListingDetailState={setListingDetailState}
        />
        {detail.likeCount > 0 && (
          <div className="flex items-center justify-center mt-1.5">
            <div style={{ marginRight: "5px" }}>
              <ThumbUpIcon
                className="mr-0.5"
                style={{
                  fill: "orange",
                  fontSize: "20px",
                  verticalAlign: "middle",
                }}
              />
            </div>{" "}
            <div className="font-semibold mt-0.5 text-sm tablet:text-base text-orange-100">
              <span className="text-orange-700">{detail.likeCount}</span> people
              liked this{" "}
              <span>{detailType === MOVIE_TYPE ? MOVIE_TYPE : "TV show"}</span>{" "}
              on Cribit
            </div>{" "}
          </div>
        )}
        {userDescription && (
          <div
            id="user-description"
            className="user-description"
            style={{
              marginTop: "1vh",
              marginBottom: "1vh",
              padding: "10px",
              borderRadius: "15px",
              backgroundColor: "#FAF1E4",
            }}
          >
            <Typography
              variant="body1"
              style={{
                paddingLeft: "1vw",
                fontSize: "14px",
                fontFamily: "Rubik",
                fontWeight: "500",
                color: "#F78F20",
              }}
            >
              you {listingDetailState} this listing because:
            </Typography>
            <Typography
              variant="body1"
              style={{
                paddingLeft: "1vw",
                fontSize: "14px",
                fontFamily: "Rubik",
                fontWeight: "400",
                fontStyle: "italic",
              }}
            >
              {userDescription}
            </Typography>
          </div>
        )}
      </div>

      {/* Why like/dislike popup dialog */}
      <Dialog
        open={openPopup}
        onOpenChange={setOpenPopup}
        onClose={handleClosePopup}
      >
        <DialogContent className="bg-orange-100 h-3/7 rounded-3xl text-center content-center ">
          <DialogTitle className="flex flex-col items-start text-xl font-semibold text-orange-700">
            Tell us a bit about why you {liked ? " like " : " dislike "}{" "}
            <span style={{ textDecoration: "underline" }}>
              {" "}
              {detail.name || "Movie Title"}{" "}
            </span>
          </DialogTitle>
          <LikePopup
            onClose={handleClosePopup}
            onSubmit={handleWhySubmit}
            description={userDescription}
          />
        </DialogContent>
      </Dialog>
      {/* why like/dislike submission dialog */}
      <Dialog
        open={isWhySubmissionOpen}
        onOpenChange={() => setIsWhySubmissionOpen(false)}
      >
        <DialogContent className="bg-orange-400 h-3/7 rounded-3xl text-center content-center ">
          <DialogHeader>
            <DialogTitle className="text-white text-4xl mb-4">
              Thanks for sharing!
            </DialogTitle>
            <DialogDescription className="text-white text-lg">
              Don't forget to click <RefreshIcon /> on your homepage
              recommendations to see what we got for you!
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
      {/* Watch Options Button */}
      <div className="flex flex-col justify-center items-center mt-5 mb-5 ">
        {" "}
        {detail.watch_provider && (
          <button
            className="bg-orange-500 text-white p-2 rounded-lg w-52 hover:bg-orange-700 ease-in duration-300"
            onClick={() => redirectToURL(detail.watch_provider)}
          >
            Watch Options
          </button>
        )}
      </div>
      <div className="reviews-section mx-20">
          {reviews.length > 0 && (
            <>
              {reviews.map((review, index) => (
                <Box
                  key={index}
                  mb={2}
                  p={2}
                  style={{ backgroundColor: "#FAF1E4", borderRadius: "15px" }}
                >
                  <Typography variant="body1">
                    Reviews by <strong>{review.username}</strong> - {review.tag}
                  </Typography>
                  <Typography variant="body2" style={{ fontStyle: "italic" }}>
                    {review.content}
                  </Typography>
                </Box>
              ))}
            </>
          )}
        </div>
    </div>
  );
};

export default DetailPage;
