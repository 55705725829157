import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import '@fontsource/rubik/400.css';
import '@fontsource/rubik/500.css';

export default function Error() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: '#F8F8F8',
      }}
    >
      <Typography variant="h1" style={{ color: '#FF9500', fontFamily: 'Rubik', fontWeight: '700' }}>
        404
      </Typography>
      <Typography variant="h6" style={{ color: '#6E6E6E', fontFamily: 'Rubik', fontWeight: '500' }}>
        Sorry, we could't find that page
      </Typography>
      <Button component={Link} to="/" variant="contained" style={{ textTransform: 'capitalize', fontFamily: 'Rubik', fontWeight: '500', color: '#00171F', marginTop: '3vh', backgroundColor: '#FFB54C', border: '2.5px', borderStyle: 'solid', borderColor: '#FFB54C', borderRadius: '24px'}}> Back to Home Page </Button>
    </Box>
  );
}