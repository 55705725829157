import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, CircularProgress } from "@mui/material";
import { useLocation } from "react-router-dom";
import Listings from "../components/listing/Listings";
import SearchRestaurantListing from "../components/listing/SearchRestaurantListing";
import { useDispatch, useSelector } from "react-redux";
import { streamMovieSearchResults, resetMovies } from "../redux/moviesSlice";
import { fetchTVSearchResults, resetTVs } from "../redux/tvSlice";
import { fetchRestaurantSearchResults } from "../redux/restaurantSlice";
import SearchBarListing from "src/components/searchBar/SearchBarListing";
import useRatingsListener from "../hooks/useRatingsListener";
import { MOVIE_TYPE, TV_TYPE } from "src/const";

const SearchResults = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const query = params.get("query");
  const category = params.get("category");
  const [loading, setLoading] = useState(false);
  const isTablet = window.innerWidth >= 768;

  const cards = useSelector((state) => {
    if (category === MOVIE_TYPE) {
      return state.moviesListing.movieCards || [];
    } else if (category === TV_TYPE) {
      return state.tvListing.tvCards || [];
    } else if (category === "Restaurant") {
      console.log("Restaurant data:", state.restaurants.restaurants);
      return state.restaurants || [];
    }
    return [];
  });

  useEffect(() => {
    setLoading(true);
    if (category === MOVIE_TYPE) {
      dispatch(resetMovies());
      dispatch(streamMovieSearchResults(query)).then(() => {
        setLoading(false);
      });
    } else if (category === TV_TYPE) {
      dispatch(resetTVs());
      dispatch(fetchTVSearchResults(query)).then(() => setLoading(false));
    } else if (category === "Restaurant") {
      dispatch(fetchRestaurantSearchResults(query)).then(() =>
        setLoading(false)
      );
    }
  }, [query]);

  useRatingsListener(cards.map(card => card.id), category);

  return (
    <div
      style={{
        padding: "0",
        margin: "0",
        backgroundColor: "#FFDFB1",
        minHeight: "100vh",
      }}
    >
      <Grid
        container
        spacing={0}
        style={{ marginLeft: "0", marginRight: "0", marginTop: "0", justifyContent: "center" }}
      >
        <Grid item xs={12} md={9}>
          <SearchBarListing query={query} category={category} />
          <Box
            display="flex"
            flexDirection="column"
            alignItems="start"
            mb={2}
          >
            <Typography
              variant="h5"
              style={{
                color: "#F57903",
                marginBottom: "1vh",
                marginTop: isTablet ? "4vh" : "1vh",
                marginLeft: "2vw",
                fontFamily: "Rubik",
                fontWeight: "500",
              }}
            >
              {cards.length}
              {category === MOVIE_TYPE && " Movie Matches"}
              {category === TV_TYPE && " TV Show Matches"}
              {category === "Restaurants" && " restaurant matches"}
            </Typography>

            {category === MOVIE_TYPE && (
              <Listings useSearchResultFormat={true} type="movies" />
            )}
            {category === TV_TYPE && (
              <Listings useSearchResultFormat={true} type="tvs" />
            )}
            {category === "Restaurants" && <SearchRestaurantListing />}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default SearchResults;
