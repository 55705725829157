import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../Pics/logo.svg";
import discordLogo from "../../Pics/discord-dark.svg"; // Replace with the actual path to your Discord logo
import { Button } from "../ui/button";
import { useAuthContext } from "../authContext";

const Navbar = () => {
  const user = useAuthContext();
  const navigate = useNavigate();
  const { displayName, uid, isAnonymous } = user || {};

  return (
    <div className="nav-wrapper bg-orange-100 flex flex-row items-center justify-between p-4">
      <div className="left-nav-wrapper flex flex-row items-center">
        <img src={logo} height="30" width="125" onClick={() => navigate("/")} alt="Logo" />
        <Button
          variant="ghost"
          className="text-base text-brown-600 font-rubik-sans ml-6"
          onClick={() => navigate("/movies")}
        >
          Movies
        </Button>
        <Button
          variant="ghost"
          className="text-base text-brown-600 font-rubik-sans ml-6"
          onClick={() => navigate("/tvs")}
        >
          TVs
        </Button>
        <Button
          variant="ghost"
          className="ml-6"
          onClick={() => window.open("https://discord.gg/d7WkxkYEpK", "_blank")}
        >
          <img src={discordLogo} height="20" width="20" alt="Discord" />
        </Button>
      </div>

      <div className="sign-in-btn">
        {isAnonymous ? (
          <Button
            variant="ghost"
            className="text-base text-brown-600 font-rubik-sans"
            onClick={() => navigate("/sign-in")}
          >
            Sign in
          </Button>
        ) : (
          <Button
            variant="ghost"
            className="text-black text-base font-rubik-sans font-medium my-4 w-fit"
            onClick={() => navigate(`/user/${displayName}/${uid}/movies`)}
          >
            {displayName}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Navbar;
