import React, { useState, useEffect } from 'react';
import '@fontsource/rubik/400.css';
import '@fontsource/rubik/500.css';
import '@fontsource/rubik/700.css';
import './SignInSignUp.scss';
import { useNavigate } from 'react-router-dom';
import { sendResetPasswordEmail } from '../../firebase/fireBaseInit';

const ForgotPasswordForm = (props) => {
  const navigate = useNavigate(); 
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const validateEmail = (email) => {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
  }

  const validatePassword = (password) => {
      return password.length >= 8;
  }

  const handleEmailChange = (e) => {
      setEmail(e.target.value);
      validateEmail(e.target.value) ? setEmailError('') : setEmailError('Invalid email format');
  }

  const handlePasswordChange = (e) => {
      setPassword(e.target.value);
      validatePassword(e.target.value) ? setPasswordError('') : setPasswordError('Password should be at least 8 characters');
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(emailError || !email) {
        alert('Please correct errors before submitting');
        return;
    }
    
    // Send the reset password email
    try {
        await sendResetPasswordEmail(email);
        alert('Reset password email sent successfully. Please check your inbox.');
        navigate("/confirmreset");  // <-- Navigate to the ResetConfirmation route
    } catch (error) {
        alert('Error sending reset password email. Please try again.');
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="form-container">
      <div className="form-wrapper">
        <form className="form" onSubmit={handleSubmit}>
          <div>
            <h2 className="form-title forgot-password">
            Forgot Password
            </h2>
            <label className="form-input-label" htmlFor="email">
              Email
            </label>
            <input className="form-input forgot-password" type="email" id="email" name="email" value={email} onChange={handleEmailChange}/>
          </div>
  
          <p className="enter-email">
            Please enter your email
          </p>

          <div className="button-wrapper">
            <button className="button" type="submit">
              Submit
            </button>
          </div>
        </form>
    
        <div className="alt-account-wrapper">
          <p className="black-text">
            Don't have an account?
          </p>
          <button className="button-text" onClick= { () => {navigate('/Signup')}}  type="button">
            Sign up here!
          </button>
        </div>
      </div>
    </div>
  );
}

export default ForgotPasswordForm;