import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TVSearchLite, movieSearchLite } from "src/firebase/fireBaseInit";
import {
  setMovies,
  setOnboardingType,
  setRecommendations,
  setSearchResults,
  setTVs,
} from "src/redux/onboardingSlice";
import { useDebounce } from "src/hooks/useDebounce";
import { Card, CardContent } from "../ui/card";
import SearchIcon from "@mui/icons-material/Search";
import {
  OnboardingFormDialog,
  PaywallAlertDialog,
  SubmitDialog,
  UnlockDialog,
} from "./Modals";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { MOVIE_TYPE } from "src/const";

export const SearchBar = ({ onboardingType, text, setText }) => {
  const { type } = useParams();
  return (
    <div className="flex">
      <SearchIcon className="mr-1" style={{ fill: "grey" }} />
      <input
        type="text"
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder={`Enter ${type || onboardingType} title`}
        className="focus:outline-none mobile:w-[80%] tablet:w-full"
      />
    </div>
  );
};

export const SearchResults = ({ isLoading, handleSearchResultClick }) => {
  const { searchResults } = useSelector((state) => state.onboarding);
  return (
    <div className="mobile:w-[80%] tablet:w-full bg-white flex flex-col rounded mt-8 h-[400px] overflow-y-scroll no-scrollbar">
      {isLoading && (
        <div className="flex justify-center mb-1">
          <CircularProgress style={{ color: "#FFA500" }} />
        </div>
      )}
      {searchResults?.map((result, id) => {
        return (
          <div
            key={id}
            onClick={() => handleSearchResultClick(result)}
            className="flex flex-row justify-between hover:bg-gray-300 border-b-2 border-orange-500 h-52 mobile:h-44"
          >
            <img
              src={result.cover_photo_url}
              alt={`${result.name} Cover Photo`}
              className="rounded-sm w-24 p-2"
            />
            <div className="flex flex-col">
              <span className="font-bold text-left mb-1">
                {result.name || result.title}
              </span>
              <span className="font-medium text-left mb-1">
                {result.release_date?.substring(0, 4) ||
                  result.start_date?.substring(0, 4)}
              </span>
              <span className="font-medium text-left mobile:overflow-hidden mobile:text-xs tablet:text-lg laptop:text-lg desktop:text-lg">
                {result.overview == ""
                  ? "No description available"
                  : result.overview?.substring(0, 100) + "..."}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const OnboardingCard = ({ item, onChange }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOnboardingFormOpen, setIsOnboardingFormOpen] = useState(false);
  const [text, setText] = useState("");
  const [recommenderText, setRecommenderText] = useState("");
  const { onboardingType, movies, tvs, recommendationCards } = useSelector(
    (state) => state.onboarding
  );

  const dispatch = useDispatch();
  const { type } = useParams();

  const debouncedSearchInput = useDebounce(text, 200);
  // debounced textarea input
  const debouncedRecommendedSearchInput = useDebounce(recommenderText, 200);

  useEffect(() => {
    if (type) dispatch(setOnboardingType(type));
  }, [type]);

  // used for textarea input - debounce redux function when input has been untyped for 500ms
  useEffect(() => {
    // check if onboarding type is movie to update movie array after debounce
    if (onboardingType === MOVIE_TYPE) {
      const obj = { ...item, text: recommenderText };
      const arr = [...movies];
      const recommendations = [...recommendationCards];
      arr[obj?.favID] = { ...obj };
      recommendations[obj?.favID] = { ...obj };
      dispatch(setMovies([...arr]));
      dispatch(setRecommendations([...recommendations]));
    } else {
      // repeat same as above for tv show
      const obj = { ...item, text: recommenderText };
      const arr = [...tvs];
      const recommendations = [...recommendationCards];
      arr[obj?.favID] = { ...obj };
      recommendations[obj?.favID] = { ...obj };
      dispatch(setTVs([...arr]));
      dispatch(setRecommendations([...recommendations]));
    }
  }, [debouncedRecommendedSearchInput]);

  useEffect(() => {
    const handleSearchInput = async () => {
      if (debouncedSearchInput) {
        let result;
        if (onboardingType === MOVIE_TYPE || type === MOVIE_TYPE) {
          setIsLoading(true);
          result = await movieSearchLite({
            searchbar: debouncedSearchInput || " ",
            page: 1,
          }).catch((err) => console.log(err));
        } else if (onboardingType === "TV" || type === "TV") {
          setIsLoading(true);
          result = await TVSearchLite({
            searchbar: debouncedSearchInput || " ",
            page: 1,
          }).catch((err) => console.log(err));
        }
        setIsLoading(false);
        dispatch(setSearchResults(result.data));
      }
    };

    handleSearchInput();
  }, [debouncedSearchInput]);

  useEffect(() => {
    if (!isOnboardingFormOpen) {
      dispatch(setSearchResults([]));
    }
  }, [isOnboardingFormOpen]);

  const handleSearchResultClick = (result) => {
    onChange({
      id: result.id,
      img: result.cover_photo_url,
      text: item.text,
      type: onboardingType,
    });
    dispatch(setSearchResults([]));
    setIsOnboardingFormOpen(false);
  };

  // New debounced recommender search (ignore updateFavs function)
  const handleTextChange = (event) => setRecommenderText(event.target.value);

  return (
    <Card className="w-[340px] h-[225px] flex bg-white bg-opacity-60 text-xs rounded-xl mb-6 border-none laptop:w-[250px] laptop:h-[500px] laptop:m-2">
      <CardContent className="flex flex-row  p-0 laptop:flex-col">
        <OnboardingFormDialog
          isOnboardingFormOpen={isOnboardingFormOpen}
          setIsOnboardingFormOpen={setIsOnboardingFormOpen}
          onboardingType={onboardingType}
          handleSearchResultClick={handleSearchResultClick}
          item={item}
          isLoading={isLoading}
          text={text}
          setText={setText}
        />
        <textarea
          value={recommenderText}
          onChange={handleTextChange}
          placeholder="Tell us why:"
          rows={8}
          className="font-bold placeholder-gray-900 border-none bg-white bg-opacity-0 p-4 resize-none focus:outline-none laptop:w-full"
        />
      </CardContent>
    </Card>
  );
};

const Onboarding = () => {
  const [isPaywallAlertOpen, setIsPaywallAlertOpen] = useState(false);
  const { didSubmit, onboardingType, movieRecommendations, tvRecommendations } =
    useSelector((state) => state.onboarding);

  const isUnlocked =
    onboardingType === MOVIE_TYPE
      ? !!(movieRecommendations?.length > 0)
      : !!(tvRecommendations?.length > 0);

  return (
    <div>
      {didSubmit ? (
        <div>
          <SubmitDialog />
        </div>
      ) : (
        !isUnlocked && (
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20 ">
            {/* ---- NOT FOR BETA ---- */}
            {/* <PaywallAlertDialog
              isPaywallAlertOpen={isPaywallAlertOpen}
              setIsPaywallAlertOpen={setIsPaywallAlertOpen}
            /> */}

            {/* ---only for beta---- */}
            <UnlockDialog closeModal={() => setIsPaywallAlertOpen(false)} />
          </div>
        )
      )}
    </div>
  );
};

export default Onboarding;
