import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  setIsSubmitDialogOpen,
  setDidSubmit,
  fetchRecommendations,
  setIsNavShown,
  updateIntroRecommendations,
  addIntroRecommendation,
  setOnboardingType,
  resetIntroRecommendations,
  setIsPaywallLoading,
} from "src/redux/onboardingSlice";
import { Button } from "src/components/ui/button";
import LockIcon from "@mui/icons-material/Lock";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/ui/dialog";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "src/components/ui/alert-dialog";
import { OnboardingCard, SearchBar, SearchResults } from "./Onboarding";
import RefreshIcon from "@mui/icons-material/Refresh";
import { CircularProgress } from "@mui/material";
import { MOVIE_TYPE } from "src/const";

export const WhyDialog = ({ isSubmissionOpen, setIsSubmissionOpen }) => {
  return (
    <Dialog
      open={isSubmissionOpen}
      onOpenChange={() => setIsSubmissionOpen(false)}
    >
      <DialogContent className="bg-orange-400 h-2/5 rounded-3xl text-center content-center ">
        <DialogHeader>
          <DialogTitle className="text-white text-4xl mb-4">
            Thanks for sharing!
          </DialogTitle>
          <DialogDescription className="text-white text-lg">
            Don't forget to click <RefreshIcon /> on your homepage
            recommendations to see what we got for you!
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export const SubmitDialog = () => {
  const dispatch = useDispatch();
  const { isSubmitDialogOpen } = useSelector((state) => state.onboarding);

  const handleDialogOpenChange = () => {
    dispatch(setIsSubmitDialogOpen(true));
    dispatch(setDidSubmit(false));
    dispatch(setIsNavShown(true));
  };

  return (
    <Dialog open={isSubmitDialogOpen} onOpenChange={handleDialogOpenChange}>
      <DialogContent className="bg-orange-400 p-12 rounded-3xl text-center content-center ">
        <DialogHeader>
          <DialogTitle className="text-white text-2xl mb-4">
            Thanks for sharing!
          </DialogTitle>
          <DialogDescription className="text-white text-xl font-semibold">
            Your recommendations are now unlocked!
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export const OnboardingFormDialog = ({
  isOnboardingFormOpen,
  setIsOnboardingFormOpen,
  onboardingType,
  handleSearchResultClick,
  item,
  isLoading,
  text,
  setText,
}) => {
  return (
    <Dialog
      className="onboarding-form-dialog-wrapper"
      open={isOnboardingFormOpen}
      onOpenChange={setIsOnboardingFormOpen}
    >
      <DialogTrigger className="laptop:w-full">
        <img
          src={item?.img}
          alt="paywall-placeholder"
          className="rounded-xl h-[225px] w-[150px] laptop:h-auto laptop:w-full"
        />
      </DialogTrigger>
      <DialogContent className="border-orange-400 border-4 item-center rounded-2xl w-4/5">
        <DialogHeader>
          <DialogTitle>
            <SearchBar
              onboardingType={onboardingType}
              text={text}
              setText={setText}
            />
          </DialogTitle>
          <div>
            <SearchResults
              isLoading={isLoading}
              handleSearchResultClick={handleSearchResultClick}
            />
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export const UnlockDialog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isUnlockDialogOpen, setIsUnlockDialogOpen] = useState(false);
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState();
  const {
    movies,
    tvs,
    pageNumber,
    onboardingType,
    recommendationCards,
    isPaywallLoading,
  } = useSelector((state) => state.onboarding);
  const { type } = useParams();

  useEffect(() => {
    if (type) dispatch(setOnboardingType(type));
  }, [type]);

  useEffect(() => {
    dispatch(setIsNavShown(!isUnlockDialogOpen));
  }, [isUnlockDialogOpen]);

  useEffect(() => {
    let updatedItems;
    if (onboardingType === MOVIE_TYPE) {
      updatedItems = movies;
    } else {
      updatedItems = tvs;
    }
    setItems(updatedItems);
  }, [onboardingType, movies, tvs]);

  useEffect(() => {
    recommendationCards.map((item) => console.log(item));
    const incomplete = recommendationCards?.some(
      (item) => item.id === "" || item.text === ""
    );
    setIsFormComplete(!incomplete);
  }, [recommendationCards]);

  const onboardingCardChange = (index, data) => {
    dispatch(updateIntroRecommendations({ index, data }));
  };

  const handleAddClick = () => {
    if (recommendationCards?.length < 3) {
      dispatch(addIntroRecommendation());
    }
  };

  const handleSubmit = async () => {
    if (isFormComplete) {
      // GTM - Push the event to the dataLayer after initial onboarding
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'favOnboarding',
        //'userId': uid,
      });
      setIsUnlockDialogOpen(false);
      setLoading(true);
      dispatch(setIsPaywallLoading(true));
      const transformedItems = recommendationCards.map((item) => ({
        id: item.id,
        comment: item.text,
      }));
      // return response from API promise
      const response = await dispatch(
        fetchRecommendations({
          favs: transformedItems,
          type: onboardingType,
          page: pageNumber,
          limit: 8,
        })
      );
      setLoading(false);
      dispatch(setIsPaywallLoading(false));
      dispatch(resetIntroRecommendations());
      // if error object exits on response, display an alert
      if (!!response?.error) {
        window.alert("There was an error. Please try again later.");
      } else {
        dispatch(setDidSubmit(true));
        dispatch(setIsSubmitDialogOpen(true));
        navigate("/");
      }
    } else {
      setIsError(true);
    }
  };

  return (
    <Dialog open={isUnlockDialogOpen} onOpenChange={setIsUnlockDialogOpen}>
      <DialogTrigger
        disabled={isPaywallLoading}
        className="relative flex overflow-hidden mt-20 items-center justify-center border-2 border-none rounded-2xl text-white px-1 py-2 bg-gradient-to-b from-orange-300 to-orange-700 mobile:w-28 mobile:text-lg laptop:w-32 laptop:text-xl"
      >
        <span
          className="shine absolute bottom-0 left-0 w-full h-1/2"
          style={{ animation: "shine 2s infinite", animationName: "shine" }}
        ></span>
        {loading || isPaywallLoading ? (
          <CircularProgress style={{ color: "#FFFFFF" }} />
        ) : (
          <>
            <span>Start Here</span>
            <style jsx>{`
              .shine::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(
                  to right,
                  rgba(255, 255, 255, 0),
                  rgba(255, 255, 255, 0.5),
                  rgba(255, 255, 255, 0)
                );
              }
              @keyframes shine {
                0% {
                  transform: translateX(-100%) rotate(-45deg);
                }
                100% {
                  transform: translateX(100%) rotate(-45deg);
                }
              }
            `}</style>
          </>
        )}

        
      </DialogTrigger>
      <DialogContent className="h-full overflow-scroll bg-orange-150 flex flex-col pt-8 min-w-[100%]">
        <DialogHeader>
          <DialogTitle className="text-left font-rubik-sans font-medium text-brown-600 text-xl leading-normal ml-3 mt-4">
            Tell us your{" "}
            <span className="text-orange-700">
              favorite {onboardingType === "TV" ? " show" : MOVIE_TYPE}(s)
            </span>
            , and in a few words,
            <span className="text-orange-700"> why </span>
            you like it:
          </DialogTitle>
        </DialogHeader>
        <DialogFooter>
          <div className="flex flex-col items-center justify-around h-full laptop:flex-row">
            {recommendationCards?.map((item, index) => (
              <OnboardingCard
                key={index}
                item={item}
                onChange={(data) => onboardingCardChange(index, data)}
              />
            ))}
          </div>
        </DialogFooter>
        <div className="flex flex-col items-center">
          {isError && (
            <div className="text-red-600 font-medium p-4">
              Submission requires title and text input. Please try again with a
              simple reason.
            </div>
          )}
          <Button
            className="bg-orange-350 w-[250px] h-full flex justify-center items-center rounded-full font-medium font-rubik-sans text-brown-600 text-xl my-4 hover:bg-orange-350 hover:text-brown-600"
            disabled={loading || recommendationCards.length === 3}
            onClick={handleAddClick}
          >
            Add another
          </Button>
          <Button
            disabled={loading}
            type="submit"
            onClick={handleSubmit}
            className="bg-brown-600 w-[250px] h-full flex justify-center items-center rounded-full font-medium font-rubik-sans text-orange-100 text-xl my-4"
          >
            {loading || isPaywallLoading ? (
              <CircularProgress size="2rem" style={{ color: "#FFFFFF" }} />
            ) : (
              <span>Finish</span>
            )}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export const PaywallAlertDialog = ({
  isPaywallAlertOpen,
  setIsPaywallAlertOpen,
}) => {
  const navigate = useNavigate();

  return (
    <AlertDialog open={isPaywallAlertOpen} onOpenChange={setIsPaywallAlertOpen}>
      <AlertDialogTrigger className="flex mt-20 items-center justify-center border-2 border-none rounded-xl text-white px-2.5 py-2 bg-gradient-to-b from-orange-300 to-orange-700">
        UNLOCK <LockIcon className="ml-2" />
      </AlertDialogTrigger>
      <AlertDialogContent className="flex flex-col items-center bg-orange-100 rounded-2xl">
        <AlertDialogHeader>
          <AlertDialogTitle>
            You don't have access to this module.
          </AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel className="rounded-3xl">Cancel</AlertDialogCancel>
          <AlertDialogAction
            onClick={() => navigate("/sign-in")}
            className="bg-orange-400 rounded-3xl mb-1"
          >
            Sign In
          </AlertDialogAction>
          <UnlockDialog closeModal={() => setIsPaywallAlertOpen(false)} />
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
