import React, { useState, useEffect } from 'react';
import { auth, createUserWithEmailAndPassword } from '../../firebase/fireBaseInit';
import '@fontsource/rubik/400.css';
import '@fontsource/rubik/500.css';
import '@fontsource/rubik/700.css';


const ResetPassword = (props) => {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    switch (name) {
      case 'email':
        setEmail(value);
        break;
      case 'username':
        setUsername(value);
        break;
      case 'password':
        setPassword(value);
        break;
      default:
        break;
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const usernameRegex = /^[a-zA-Z0-9_]{8,20}$/;
    const passwordRegex = /^[a-zA-Z0-9!@#$%^&*]{8,}$/;

    if (!emailRegex.test(email)) {
      alert("Please enter a valid email.");
      return;
    }

    if (!usernameRegex.test(username)) {
      alert("Username should be 8-20 characters and can include letters, numbers, and underscores.");
      return;
    }

    if (!passwordRegex.test(password)) {
      alert("Password must be at least 8 characters long and can include letters, numbers, and specific special characters (e.g., !, @, #, $).");
      return;
    }
  
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="form-container">
      <div className="form-wrapper">
        <form className="form" onSubmit={handleSubmit}>
          <h2 className="form-title forgot-password">
            Reset Password
          </h2>

          <label className="form-input-label" htmlFor="password">
            Password
          </label>
          <input
            className="form-input"
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={handleChange}
          />

          <label className="form-input-label" htmlFor="password">
            Confirm Password
          </label>
          <input
            className="form-input"
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={handleChange}
          />

          <div className="button-wrapper">
            <button className="button" type="submit">
              Reset Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;