import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  db,
  collection,
  getDocs,
  query,
  limit,
  startAfter,
  TVSearch,
  where,
} from "../firebase/fireBaseInit";

const PAGE_SIZE = 20;

export const fetchTVShowData = createAsyncThunk(
  "tvListing/fetchTVShowData",
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const { tvCategories, tvLastDoc } = getState().tvListing;
      let fetchedData = [];
      let tvLastDocument = null;

      const tvShowsCollection = collection(db, "TVs");
      let tvShowsQuery;

      if (getState().tvListing.tvCategories !== tvCategories || !tvLastDoc) {
        // Reset tvs if the category has changed or if starting a new fetch cycle
        dispatch(resetTVs());
      }

      if (tvCategories) {
        // Query to fetch TV shows based on category
        tvShowsQuery = tvLastDoc
          ? query(
              tvShowsCollection,
              where("genres", "array-contains", tvCategories),
              startAfter(tvLastDoc),
              limit(PAGE_SIZE)
            )
          : query(
              tvShowsCollection,
              where("genres", "array-contains", tvCategories),
              limit(PAGE_SIZE)
            );
      } else {
        // Query to fetch all TV shows
        tvShowsQuery = tvLastDoc
          ? query(tvShowsCollection, startAfter(tvLastDoc), limit(PAGE_SIZE))
          : query(tvShowsCollection, limit(PAGE_SIZE));
      }

      const querySnapshot = await getDocs(tvShowsQuery);

      fetchedData = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const data = doc.data();
          const ratingsCollectionRef = collection(db, "TVs", doc.id, "ratings");
          const ratingsSnapshot = await getDocs(ratingsCollectionRef);
          const ratingsData = {};
          ratingsSnapshot.forEach((ratingDoc) => {
            ratingsData[ratingDoc.id] = ratingDoc.data();
          });

          return {
            title: data.name,
            description: data.overview,
            imageUrl: data.cover_photo_url,
            channel: data.channel,
            tvCategories: data.genres || {},
            seasons: data.number_of_seasons,
            year: data.start_date ? data.start_date.split("-")[0] : "N/A",
            docID: doc.id,
            ratings: ratingsData || {},
            type: data.type,
          };
        })
      );

      if (fetchedData.length > 0) {
        tvLastDocument = querySnapshot.docs[querySnapshot.docs.length - 1];
      }

      return { tvCards: fetchedData, tvLastDoc: tvLastDocument };
    } catch (error) {
      console.error("Error fetching TV shows:", error);
      return rejectWithValue(error.message);
    }
  }
);

export const fetchTVSearchResults = createAsyncThunk(
  "tvListing/fetchTVSearchResults",
  async (query, { rejectWithValue, dispatch }) => {
    dispatch(resetTVs());
    try {
      if (!query) {
        return [];
      }
      const result = await TVSearch({ searchbar: query, page: 1 });
      if (!result.data || !Array.isArray(result.data)) {
        return [];
      }

      const transformedData = await Promise.all(
        result.data.map(async (tv) => {
          const ratingsCollectionRef = collection(db, "TVs", tv.id, "ratings");
          const ratingsSnapshot = await getDocs(ratingsCollectionRef);
          const ratingsData = {};
          ratingsSnapshot.forEach((doc) => {
            ratingsData[doc.id] = doc.data();
          });

          return {
            ...tv,
            ratings: ratingsData,
          };
        })
      );

      return transformedData;
    } catch (error) {
      console.error(`Error fetching TV shows: ${error.message}`);
      return rejectWithValue(error.message);
    }
  }
);

const tvSlice = createSlice({
  name: "tvListing",
  initialState: {
    tvCards: [],
    tvCategories: null,
    tvType: "",
    tvLastDoc: null,
    status: "idle",
    error: null,
    bookmarkedTVShows: [],
    filter: {
      sortBy: "Recommended",
    },
    tvShows: [],
    tvIsLoading: false,
  },
  reducers: {
    setTvCategories: (state, action) => {
      state.tvCategories = action.payload;
      state.tvCards = [];
      state.tvLastDoc = null;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    tvAdded: (state, action) => {
      state.tvShows.push(action.payload);
    },
    tvUpdated: (state, action) => {
      const { id, title, description } = action.payload;
      const existingTvShow = state.tvShows.find((tv) => tv.id === id);
      if (existingTvShow) {
        existingTvShow.title = title;
        existingTvShow.description = description;
      }
    },
    tvDeleted: (state, action) => {
      const id = action.payload;
      state.tvShows = state.tvShows.filter((tv) => tv.id !== id);
    },
    setLoading: (state, action) => {
      state.tvIsLoading = action.payload;
    },
    setTV: (state, action) => {
      state.tvCards = action.payload;
    },
    resetTVs: (state) => {
      state.tvCards = [];
      state.tvLastDoc = null;
    },
    updateTVRatings: (state, action) => {
      const { listingId, ratings } = action.payload;
      const tvShow = state.tvCards.find((tv) => tv.id === listingId);
      if (tvShow) {
        tvShow.ratings = ratings;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // Existing handlers for fetchTVShowData
      .addCase(fetchTVShowData.pending, (state) => {
        state.status = "loading";
        state.tvIsLoading = true;
      })
      .addCase(fetchTVShowData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.tvCards = state.tvCards.concat(action.payload.tvCards);
        state.tvLastDoc = action.payload.tvLastDoc;
        state.tvType = action.payload.tvType;
        state.tvIsLoading = false;
      })
      .addCase(fetchTVShowData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.tvIsLoading = false;
      })
      .addCase(fetchTVSearchResults.pending, (state) => {
        state.status = "loading";
        state.tvIsLoading = true;
      })
      .addCase(fetchTVSearchResults.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.tvCards = action.payload;
        state.tvIsLoading = false;
      })
      .addCase(fetchTVSearchResults.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  setTvCategories,
  setFilter,
  bookmarkTvShow,
  unbookmarkTvShow,
  tvAdded,
  tvUpdated,
  tvDeleted,
  setLoading,
  setTV,
  resetTVs,
  updateTVRatings,
} = tvSlice.actions;

export default tvSlice.reducer;
