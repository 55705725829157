import React, { useState, useEffect } from "react";
import Feedback from "./Feedback";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import logo from "../Pics/logo.svg";
import "@fontsource/rubik/400.css";
import "@fontsource/rubik/500.css";
import "./Footer.scss";
import tutorial1 from "../Pics/Tutorial1.png";
import tutorial2 from "../Pics/Tutorial2.png";
import tutorial3 from "../Pics/Tutorial3.png";
import tutorial4 from "../Pics/Tutorial4.png";
import CloseIcon from '@mui/icons-material/Close';

const Footer = () => {
  const [openFeedback, setOpenFeedback] = useState(false);
  const [openInstructions, setOpenInstructions] = useState(false);

  // useEffect(() => {
  //   window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

  //   return () => {
  //     window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
  //   };
  // }, []);

  // const handleBeforeInstallPrompt = (event) => {
  //   event.preventDefault();
  //   const installPrompt = event;
  //   // Show the "Add to Home Screen" button
  //   setInstallPrompt(installPrompt);
  // };

  // const handleAddToHomeScreenClick = () => {
  //   if (installPrompt) {
  //     // Show the browser's install prompt
  //     installPrompt.prompt();
  //     // Wait for the user to respond to the prompt
  //     installPrompt.userChoice.then((choiceResult) => {
  //       if (choiceResult.outcome === "accepted") {
  //         console.log("User accepted the A2HS prompt");
  //       } else {
  //         console.log("User dismissed the A2HS prompt");
  //       }
  //       // Reset the install prompt variable
  //       setInstallPrompt(null);
  //     });
  //   }
  // };

  // const [installPrompt, setInstallPrompt] = useState(null);

  const handleFeedbackClick = () => {
    setOpenFeedback(true);
  };

  const handleCloseFeedback = () => {
    setOpenFeedback(false);
  };

  const handleOpenInstructions = () => {
    setOpenInstructions(true);
  };

  const handleCloseInstructions = () => {
    setOpenInstructions(false);
  };

  const handleContactClick = () => {
    const emailAddress = "hello@cribit.xyz";
    window.location.href = `mailto:${emailAddress}`;
  }

  return (
    <div className="footer">
      <div className="footer__left-wrapper">
        <img src={logo} alt="logo" />
        <div className="footer__left-wrapper copyright">
          Copyright &copy; 2024 Cribit Inc.
        </div>
        <div className="footer__left-wrapper tmdb">
          Film data from TMDb
        </div>
      </div>

      <div className="footer__feedback web">
        <span onClick={handleFeedbackClick}>
          Share Your Feedback
        </span>
        <span>
          &#32;/&#32;
        </span>
        <button onClick={handleContactClick}>
          &#32;Contact Us: hello@cribit.xyz
        </button>
      </div>
      <div className="footer__feedback mobile">
        <span onClick={handleOpenInstructions}>
          Add to Home Screen
        </span>
        <span>
          &#32;/&#32;
        </span>
        <button onClick={handleContactClick}>
          &#32;Contact Us
        </button>
      </div>

      <Dialog
        open={openFeedback}
        onClose={handleCloseFeedback}
        PaperProps={{
          style: {
            borderRadius: "20px",
            maxWidth: "80%",
            width: "500px",
          },
        }}
      >
        <DialogTitle className="footer__feedback title">
          Share Feedback
        </DialogTitle>
        <DialogContent>
          <Feedback onClose={handleCloseFeedback} />
        </DialogContent>
      </Dialog>

      <Dialog
        fullScreen
        open={openInstructions}
        onClose={handleCloseInstructions}
        PaperProps={{
          style: {
            backgroundColor: "#FFDFB1",
          },
        }}
      >
        <IconButton
          onClick={handleCloseInstructions}
          style={{ position: 'absolute', right: '10px', top: '10px', color: '#9E9E9E', fontSize: '30px', zIndex: '1', cursor: 'pointer' }} 
        >
          <CloseIcon style={{ fontSize: '40px' }} />
        </IconButton>
        <DialogContent style={{ paddingTop: '60px', zIndex: '0' }}>
          <img src={tutorial1} alt="Step 1" style={{ width: '100%', marginBottom: '20px' }} />
          <img src={tutorial2} alt="Step 2" style={{ width: '100%', marginBottom: '20px' }} />
          <img src={tutorial3} alt="Step 3" style={{ width: '100%', marginBottom: '20px' }} />
          <img src={tutorial4} alt="Step 4" style={{ width: '100%' }} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Footer;
