import React, { useEffect, useState } from 'react';
import { movieSearch } from '../firebase/fireBaseInit';

const TestMovieSearch = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const startTime = Date.now();  // Capture the start time
      try {
        const result = await movieSearch({
          searchbar: 'movie',
          page: 1
        });

        const endTime = Date.now();  // Capture the end time
        console.log(`Time taken for movieSearch: ${endTime - startTime}ms`); // Log the duration


        setData(result.data);
      } catch (err) {
        setError(err);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <h1>Test Movie Search Results</h1>

      {error && (
        <div>
          <h2>Error</h2>
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </div>
      )}

      {data && (
        <div>
          <h2>Data</h2>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default TestMovieSearch;
