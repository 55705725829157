import React, { useEffect, useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import Filter from "src/components/listing/Filter";
import Listings from "src/components/listing/Listings";
import { useLocation } from "react-router-dom";
import SearchBarListing from "src/components/searchBar/SearchBarListing";

const ListPage = () => {
  const [type, setType] = useState();
  const location = useLocation();

  useEffect(() => {
    const currentURL = location.pathname;

    if (currentURL.includes("/movies")) setType("movies");
    if (currentURL.includes("/tvs")) setType("tvs");
  }, [location.pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-orange-100">
      <SearchBarListing />
      <Grid container spacing={3} className="flex justify-center">
        <Grid item xs={12} md={9}>
          <Box display="flex" flexDirection="column" alignItems="start" mb={2}>
            {type ? <Listings type={type} /> : <div>No Existing Listings</div>}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default ListPage;
