import React, { useState, useEffect, useRef } from 'react';
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { Collapse, Paper, List, ListItem, Typography } from "@mui/material";
import LocationAutocomplete from './LocationAutocomplete';

const ModalContainer = styled("div")(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0,0,0,0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

const ModalContent = styled("div")(({ theme }) => ({
  backgroundColor: 'white',
  padding: '10px 20px',
  borderRadius: '8px',
  width: '40%',
  display: 'flex',
  flexDirection: 'column',
  // gap: '10px',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: '100%',
  padding: '4px 8px',
  fontFamily: 'Rubik',
  fontWeight: 'bold',
  borderRadius: '4px 4px 0 0',
  border: '1px solid #FF9500',
  borderBottom: 'none',
  '& .MuiInputBase-input': {
      fontFamily: 'Rubik',
      fontWeight: '500',
      fontSize: '20px',
  },
  marginBottom: '2vh'
}));

const DropdownPaper = styled(Paper)(({ theme }) => ({
  borderTop: 'none',
  borderBottomLeftRadius: '4px',
  borderBottomRightRadius: '4px',
  border: '1px solid #FF9500',
  borderTop: 'none',
}));

const AddressModal = ({ isOpen, onClose, onSubmit, initialValue }) => {
    const modalRef = useRef(null);

    const handleAddressSelect = (selectedAddress) => {
        console.log("Inside AddressModal:", selectedAddress);
        onSubmit(selectedAddress);
        onClose();
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]);

    if (!isOpen) return null;

    return (
      <ModalContainer>
          <ModalContent ref={modalRef}>
              <h2 style={{ fontFamily: 'Rubik', fontWeight: '500', alignSelf:'flex-start', fontSize: '20px' }}>Edit Address</h2>
              <LocationAutocomplete 
                  onPlaceSelected={handleAddressSelect}
                  style={{
                      background: 'transparent',
                      border: '1px solid #FF9500',
                      outline: 'none',
                      fontFamily: 'Rubik',
                      fontWeight: '500',
                      fontSize: '20px',
                      borderRadius: '4px',
                      padding: '4px 8px',
                      width: '90%',
                      marginBottom: '2vh',
                      marginRight: 'auto'
                  }}
              />
          </ModalContent>
      </ModalContainer>
  );
};

export default AddressModal;
