import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout, updateUser } from "../firebase/fireBaseInit";
import { AuthContext } from "../components/authContext";
import {
  fetchDislikeTvs,
  fetchLikeTvs,
  fetchList,
  fetchSeenTvs,
  fetchWishlistTvs,
} from "../redux/listsSlice";
import { updateUserInfo } from "src/redux/userSlice";
import { ArrowBackIos } from "@mui/icons-material";
import ReplyIcon from "@mui/icons-material/Reply";
import EditIcon from "@mui/icons-material/Edit";
import { Button } from "src/components/ui/button";
import ListRow from "../components/listing/ListRow";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { getAuth, updateProfile } from "firebase/auth";
import {
  setMovieRecommendations,
  setTvRecommendations,
} from "src/redux/onboardingSlice";

const User = () => {
  const [editUsername, setEditUsername] = useState(false);
  const [sharedLink, setSharedLink] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);

  const { username } = useSelector((state) => state.user.user);
  const {
    like_tvs,
    dislike_tvs,
    wishlist_tvs,
    seen_tvs,
    seen_movies,
    wishlist_movies,
    like_movies,
    dislike_movies,
    favorite_tvs,
    favorite_movies,
  } = useSelector((state) => state.lists);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = getAuth();
  const { currentUser } = auth || {};
  const { displayName } = currentUser || {};
  const { uid, type } = useParams();
  const user = useContext(AuthContext);
  const wishlist = type === "tvs" ? wishlist_tvs : wishlist_movies;
  const like = type === "tvs" ? like_tvs : like_movies;
  const dislike = type === "tvs" ? dislike_tvs : dislike_movies;
  const seen = type === "tvs" ? seen_tvs : seen_movies;
  const favorites = type === "tvs" ? favorite_tvs : favorite_movies;

  // checks if it's a shared link
  useEffect(() => {
    const url = window.location.pathname;
    if (url.includes("/shared=true")) setSharedLink(true);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      // tvs
      await dispatch(fetchList({ user: uid, listName: "like_tvs" }));
      await dispatch(fetchList({ user: uid, listName: "dislike_tvs" }));
      await dispatch(fetchList({ user: uid, listName: "wishlist_tvs" }));
      await dispatch(fetchList({ user: uid, listName: "seen_tvs" }));
      await dispatch(fetchList({ user: uid, listName: "favorite_tvs" }));

      // movies
      await dispatch(fetchList({ user: uid, listName: "like_movies" }));
      await dispatch(fetchList({ user: uid, listName: "dislike_movies" }));
      await dispatch(fetchList({ user: uid, listName: "wishlist_movies" }));
      await dispatch(fetchList({ user: uid, listName: "seen_movies" }));
      await dispatch(fetchList({ user: uid, listName: "favorite_movies" }));
    };
    fetchData();
  }, []);

  const handleInputChange = (event) => {
    dispatch(updateUserInfo({ username: event.target.value }));
  };

  const saveNewUsername = () => {
    updateProfile(user, {
      displayName: username,
    })
      .then(() => {
        console.log("Display name updated successfully:", username);
        navigate(`/user/${encodeURIComponent(username)}/${user.uid}`);
      })
      .catch((error) => {
        console.error("Error updating display name:", error);
      });
  };

  const handleShareLink = () => {
    const currentURL = new URL(window.location.href);
    const modifiedURL = currentURL + "/shared=true";

    navigator.clipboard
      .writeText(!sharedLink ? modifiedURL : currentURL)
      .then(() => {
        setAlertOpen(true);
      })
      .catch(() => {
        alert("Failed to copy link to clipboard, please try again.");
      });
  };

  const listTypeHandler = (contentType) => {
    let path = `/user/${encodeURIComponent(username)}/${uid}/${contentType}`;
    if (sharedLink) {
      path = `/user/${encodeURIComponent(
        displayName
      )}/${uid}/${contentType}/shared=true`;
    }
    navigate(path);
  };

  const logoutHandler = () => {
    logout();
    dispatch(setMovieRecommendations([]));
    dispatch(setTvRecommendations([]));
    navigate("/");
  };

  return (
    <div className="bg-orange-100 h-full flex flex-col">
      <div className="user-header-wrapper p-4">
        <div className="flex flex-row items-center justify-between mx-2 py-2">
          {/* BACK BUTTON LOGIC HERE */}
          <ArrowBackIos onClick={() => navigate("/")} />
          {editUsername ? (
            <div className="flex justify-center items-center">
              <input
                type="text"
                value={username}
                onChange={handleInputChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    saveNewUsername();
                  }
                }}
                autoFocus
                className="h-8 w-28 border-2 border-orange-500 rounded-sm"
              />
              <Button
                className="bg-orange-500 text-[10px] ml-2 py-3.5 px-2.5 rounded-sm h-0"
                onClick={saveNewUsername}
              >
                SAVE
              </Button>
            </div>
          ) : (
            <div className="flex justify-center items-center">
              <span className="font-rubik-sans font-medium">
                {displayName}'s Lists
              </span>{" "}
              {!sharedLink && (
                <EditIcon
                  onClick={() => setEditUsername(true)}
                  fontSize="x-small"
                  className="ml-2 mb-1"
                />
              )}
            </div>
          )}

          <ReplyIcon
            className="transform scale-x-[-1]"
            onClick={handleShareLink}
          />
        </div>
        <div className="flex flex-row items-center justify-center">
          <Button
            onClick={() => listTypeHandler("movies")}
            className={`h-0 px-3 py-2.5 mr-2.5 text-xs rounded-2xl bg-gray-300 text-black transform ${
              type === "movies"
                ? "bg-orange-700 text-white scale-110 uppercase"
                : "scale-100"
            } hover:bg-orange-700 transition duration-300 mobile:text-xs mobile:px-3 mobile:mr-2 tablet:px-5 tablet:py-3 laptop:text-base`}
          >
            MOVIES
          </Button>
          <Button
            onClick={() => listTypeHandler("tvs")}
            className={`h-0 px-3 py-2.5 text-xs rounded-2xl bg-gray-300 text-black transform ${
              type === "tvs"
                ? "bg-orange-700 text-white scale-110 uppercase "
                : // : "mobile:hover:bg-orange-700 mobile:hover:text-white"
                  "scale-100"
            } hover:bg-orange-700 transition duration-300 mobile:text-xs mobile:px-3 tablet:px-5 tablet:py-3 laptop:text-base`}
          >
            TV SHOWS
          </Button>
        </div>
      </div>

      <div className="lists-wrapper bg-orange-100">
        <>
          <ListRow listName="Watch List" list={wishlist} layout="row" />
          <ListRow listName="Likes" list={like} layout="row" />
          <ListRow listName="Dislikes" list={dislike} layout="row" />
          <ListRow listName="Seen" list={seen} layout="row" />
          <ListRow listName="Favorites" list={favorites} layout="row" />
        </>
      </div>
      <Snackbar
        open={alertOpen}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={4000}
        onClose={() => setAlertOpen(false)}
      >
        <Alert
          onClose={null}
          sx={{
            width: "100%",
            borderRadius: "16px",
            backgroundColor: "#FF9800",
            color: "white",
          }}
          icon={false}
        >
          Link has been copied to clipboard!
        </Alert>
      </Snackbar>

      <Button
        className="flex mt-auto mb-4 text-brown-600 underline"
        onClick={logoutHandler}
        variant="ghost"
      >
        Log out
      </Button>
    </div>
  );
};

export default User;
