import { configureStore } from "@reduxjs/toolkit";
import tvSlice from "./tvSlice";
import moviesReducer from "./moviesSlice";
import userLocationReducer from "./userLocationSlice";
import userReducer from "./userSlice";
import restaurantsReducer from "./restaurantSlice";
import folderReducer from "./foldersSlice";
import bookmarkReducer from "./bookmarksSlice";
import listsReducer from "./listsSlice";
import onboardingReducer from "./onboardingSlice";

export const store = configureStore({
  reducer: {
    moviesListing: moviesReducer,
    userLocation: userLocationReducer,
    tvListing: tvSlice,
    user: userReducer,
    restaurants: restaurantsReducer,
    userFolders: folderReducer,
    bookmarks: bookmarkReducer,
    lists: listsReducer,
    onboarding: onboardingReducer,
  },
  middleware: (getDefaultMiddleware) =>
    process.env.NODE_ENV !== "production"
      ? getDefaultMiddleware({
          serializableCheck: false,
        }).concat()
      : getDefaultMiddleware(),
});
