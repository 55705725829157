import React, { useState, useEffect } from "react";
import "@fontsource/rubik/400.css";
import "@fontsource/rubik/500.css";
import "@fontsource/rubik/700.css";
import "./SignInSignUp.scss";
import { getAuth, updateProfile } from "firebase/auth";

const ChangeUsername = (props) => {
  const auth = getAuth();
  const { currentUser } = auth || {};
  const [username, setUsername] = useState("");

  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    switch (name) {
      case "username":
        setUsername(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const usernameRegex = /^[a-zA-Z0-9_]{8,20}$/;

    if (!usernameRegex.test(username)) {
      alert(
        "Username should be 8-20 characters and can include letters, numbers, and underscores."
      );
      return;
    }
    try {
      await updateProfile(currentUser, {
        displayName: username,
      });
      console.log("Display name updated successfully:", username);
      alert("Username updated successfully!");
    } catch (error) {
      console.error("Error updating display name:", error);
      alert("Failed to update username. Please try again.");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="form-container">
      <div className="form-wrapper">
        <form className="form" onSubmit={handleSubmit}>
          <h2 className="form-title forgot-password">Change Username</h2>

          <label className="form-input-label" htmlFor="username">
            New Username
          </label>
          <input
            className="form-input forgot-password"
            type="username"
            id="username"
            name="username"
            value={username}
            onChange={handleChange}
          />

          <div className="button-wrapper">
            <button className="button" type="submit">
              Confirm
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangeUsername;
