import React, { useContext, useState, useEffect } from 'react';
import LocationContext from '../contexts/LocationContext';

function TestLocationPage() {
  const location = useContext(LocationContext);
  const [city, setCity] = useState(null);

  useEffect(() => {
    if (location) {
      fetch(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${location.latitude}&longitude=${location.longitude}&localityLanguage=en`)
        .then(response => response.json())
        .then(data => setCity(data.locality));
    }
  }, [location]);

  return (
    <div style={{ padding: '20px' }}>
      <h1>Test Location Page</h1>
      {location ? (
        <div>
          <p><strong>Latitude:</strong> {location.latitude}</p>
          <p><strong>Longitude:</strong> {location.longitude}</p>
          <p><strong>City:</strong> {city}</p>
          <p><strong>Workflow Test 2</strong></p>
        </div>
      ) : (
        <p>Loading location or location not available...</p>
      )}
    </div>
  );
}

export default TestLocationPage;