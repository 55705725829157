import React, { useState } from "react";
import { Button, TextField, Typography, Box, Slider, FormHelperText } from "@mui/material";
import StarFull from "../logos/StarFull.png";
import StarHalf from "../logos/StarHalf.png";
import StarEmpty from "../logos/StarEmpty.png";
import "@fontsource/rubik/400.css";
import "@fontsource/rubik/500.css";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { addUserFeedback } from "../firebase/fireBaseInit";
import { ThemeProvider, styled } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";
import { db } from "../firebase/fireBaseInit";

const LikePopup = ({ onClose, onSubmit, description }) => {
  const [rating, setRating] = useState(10);
  // const [hoverRating, setHoverRating] = useState(0);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [featureRequest, setFeatureRequest] = useState("");
  const [listingCheck, setListingCheck] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [inputDescription, setInputDescription] = useState(description);
  const [isError, setIsError] = useState(false);


  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async () => {
    if (!inputDescription) {
      setIsError(true);
      return;
    }
    onSubmit(inputDescription);
    onClose();
  };

  return (
    <div>
      <Typography
        variant="subtitle1"
        style={{
          color: "#472A00",
          fontSize: "18px",
          fontWeight: "bold",
          letterSpacing: ".02rem",
          lineHeight: "1.5rem",
          marginBottom: "10px",
          fontFamily: 'Rubik'
        }}
      >
        Tell us <span style={{ color: "darkOrange" }}>why</span> to receive
        better recommendations from us:
      </Typography>
      <TextField
        multiline
        rows={4}
        variant="outlined"
        value={inputDescription}
        onChange={(e) => setInputDescription(e.target.value)}
        fullWidth
        inputProps={{ style: { fontFamily: "Rubik", fontWeight: "400" } }}
        style={{
          marginTop: "5px",
          marginBottom: "10px",
          cursor: "text",
          backgroundColor: "white",
          borderRadius: '20px'
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "lightgrey",
              borderRadius: '20px'
            },
            "&:hover fieldset": {
              borderColor: "grey",
              borderRadius: '20px'
            },
            "&.Mui-focused fieldset": {
              borderColor: "darkerGrey",
              borderRadius: '20px'
            },
          },
          "& .Mui-focused": {
            outlineColor: "darkerGrey",
            borderRadius: '20px'
          },
          cursor: "text",
        }}
      />
      {isError && (
        <FormHelperText style={{ fontFamily: 'Rubik', fontSize: '14px', color: '#FF2020', fontWeight: '500', lineHeight: '1rem', marginBottom: '20px' }}>
          Submission requires text input. Try again with a simple reason.
        </FormHelperText>
      )}
      <Box display="flex" justifyContent="center">
        {isLoading ? (
          <CircularProgress style={{ color: "#FFA500" }} />
        ) : (
          <Button
            variant="contained"
            style={{
              backgroundColor: "orange",
              width: "150px",
              color: "black",
              borderRadius: "20px",
              fontWeight: "500",
              fontSize: "0.9rem",
              fontFamily: "Rubik",
              boxShadow: "none",
              transition: "box-shadow 0.5s",
            }}
            onMouseEnter={(e) => {
              e.target.style.boxShadow = "0px 3px 10px rgba(0, 0, 0, 0.2)"; // Add box shadow on hover
            }}
            onMouseLeave={(e) => {
              e.target.style.boxShadow = "none"; // Remove box shadow when not hovering
            }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        )}
      </Box>
      <Box display="flex" justifyContent="center">
        <Button
          variant="contained"
          style={{
            backgroundColor: "transparent",
            color: "#5E5E5E",
            borderRadius: "20px",
            fontWeight: "500",
            fontSize: "0.9rem",
            fontFamily: "Rubik",
            textDecoration: "underline",
            boxShadow: "none",
          }}
          onClick={() => {
            onClose();
          }}
        >
          Skip
        </Button>
      </Box>
    </div>
  );
};

export default LikePopup;
