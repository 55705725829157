import React, { useState, useEffect } from 'react';
import ListingCard from './ListingCard';
import '@fontsource/rubik/400.css';
import '@fontsource/rubik/500.css';
import { collection, doc, getDocs, getDoc, db, query, where } from '../../firebase/fireBaseInit';
import { getYelpIdToDocId } from '../../hooks/utility';
import { useSelector } from 'react-redux';

// Function to calculate distance between two sets of latitude and longitude
function calculateDistance(lat1, lon1, lat2, lon2) {
  if (lat1 && lon1 && lat2 && lon2) {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distanceInKm = R * c;
    const distanceInMiles = distanceInKm * 0.621371; // Convert to miles
    return distanceInMiles.toFixed(2);
  }
  return null;
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

const SearchRestaurantListing = ({ cards, onCardClick, location }) => {
  const [enhancedCards, setEnhancedCards] = useState([]);
  const userLocation = useSelector(state => state.userLocation.location);
  const latitude = userLocation?.latitude;
  const longitude = userLocation?.longitude;

  const fetchCategoryNamesByIds = async (categoryIds) => {
    try {
      const categoriesCollectionRef = collection(db, 'restaurant_categories');
      const categoryNames = [];
      for (const id of categoryIds) {
        const docRef = doc(categoriesCollectionRef, id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          categoryNames.push(docSnap.data().name);
        } else {
          console.error(`No such document in restaurant_categories! id: ${id}`);
        }
      }
      return categoryNames;
    } catch (error) {
      console.error("Error fetching category names:", error);
      return [];
    }
  };

  const fetchDataByDocID = async (docID, location) => {
    try {
      console.log("Fetching ratings, price, and distance for docID:", docID); // Log the docID being processed

      if (!docID) throw new Error("docID is undefined"); // Check if docID is undefined

      // Convert docID to actual docId using the getYelpIdToDocId function
      const yelpId = docID; // Assuming docID is the Yelp ID
      const actualDocId = await getYelpIdToDocId(yelpId);

      // Now, use the actualDocId to fetch the ratings and price
      const docRef = doc(db, 'restaurants', actualDocId);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        console.log("No document found for docID:", docID);
        return { ratings: {}, price: null, distance: null };
      }

      const data = docSnap.data();
      const ratingsRef = collection(db, 'restaurants', actualDocId, 'ratings');
      const ratingsSnapshot = await getDocs(ratingsRef);

      // Fetch ratings
      const ratingsData = {};
      ratingsSnapshot.forEach(doc => {
        ratingsData[doc.id] = doc.data();
      });

      // Fetch price
      const price = data.price; // Assuming 'price' is the field name in Firestore

      // Extract latitude and longitude from the location object
      // Extract latitude and longitude from the location object
        const queryString = location.search;
        const searchParams = new URLSearchParams(queryString);
        const userLatitude = parseFloat(searchParams.get('lat'));
        const userLongitude = parseFloat(searchParams.get('lng'));


      // Calculate distance
      const restaurantLatitude = data.latlong?._lat;
      const restaurantLongitude = data.latlong?._long;
      const distance = calculateDistance(latitude, longitude, restaurantLatitude, restaurantLongitude);

      return { ratings: ratingsData, price: price, distance: distance };
    } catch (error) {
      console.error("Error fetching ratings, price, and distance for docID:", docID, "Error:", error);
      return { ratings: {}, price: null, distance: null };
    }
  };

  useEffect(() => {
    const processCards = async () => {
      const processedCards = await Promise.all(cards.map(async card => {
        const docID = card.docID; // Get docID from the card
        if (!docID) {
          console.warn('Skipping card without docID:', card);
          return card; // or return null, and then filter out null values before setting state
        }
        const cardCategoryNames = card.categories
          ? await fetchCategoryNamesByIds(card.categories)
          : [];
        const { ratings, price, distance } = await fetchDataByDocID(docID, location); // Use location here
        return {
          ...card,
          categoryNames: cardCategoryNames,
          ratings: ratings,
          priceRange: price, // Assuming 'priceRange' is the prop name in ListingCard
          distance: distance,
        };
      }));
      setEnhancedCards(processedCards);
    };
    if (cards && cards.length > 0) processCards();
  }, [cards, location]); // Include location in the dependency array

  return (
    <div style={{ width: '100%', overflow: 'hidden', position: 'relative' }}>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', }}>
        {enhancedCards.map((card, index) => (
          <div
            className="listing-card"
            key={index}
            style={{ margin: '8px' }}
          >
            <ListingCard
              docId={card.docID}
              title={card.title}
              imageUrl={card.imageUrl}
              distance={card.distance}
              ratings={card.ratings}
              categories={card.categoryNames}
              onTitleClick={onCardClick}
              type={card.type}
              priceRange={card.priceRange}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchRestaurantListing;
