import React, { useState, useEffect } from 'react';
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import {Button} from '../ui/button';
import { Collapse, Paper, List, ListItem, Box, Typography, Chip, Select, MenuItem } from "@mui/material";
import { useContext } from 'react';
import LocationContext from '../../contexts/LocationContext';
import { useNavigate } from 'react-router-dom';
import { MOVIE_TYPE, TV_TYPE } from 'src/const';

const CenteredSearch = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "0px",
  backgroundColor: '#FFDFB1'
}));

const SearchContainer = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "30px",
  backgroundColor: 'white',
  borderColor: "#FFDFB1",
  borderWidth: "1px",
  borderStyle: "solid",
  marginLeft: "7.5%",
  marginRight: "7.5%",
  marginBottom: '2vh',
  width: "85%",
  maxWidth: 'auto',
  boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)'
}));

const Search = styled("div")(({ theme }) => ({
  borderRadius: "30px",
  backgroundColor: 'white',
  marginLeft: "0",
  marginRight: "0",
  width: "100%",
  height: '50px', // Set the height of the search bar
  display: "flex",
  flexDirection: "row", // Change to row layout
  alignItems: "center", // Vertically align items
}));

const SearchContent = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 2),
    paddingLeft: `calc(${theme.spacing(2)})`,  // Adjust this value to position the placeholder text
    paddingRight: theme.spacing(2),
    transition: theme.transitions.create('width'),
    width: '100%',
    textAlign: 'left',  
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      '&:focus': {
        width: '100%',
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 24px)',
      fontSize: '11px',

    }
  },
  flexGrow: 1,
}));


const DropdownPaper = styled(Paper)({
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  borderBottomLeftRadius: '30px',
  borderBottomRightRadius: '30px',
  marginTop: '-3px',
  borderColor: 'transparent'
});

const CustomSelect = styled(Select)(() => ({ 
    borderTopLeftRadius: '30px', // Rounded corners on the left side
    borderBottomLeftRadius: '30px', // Rounded corners on the left side
    backgroundColor: 'transparent', // Make the background transparent
    width: 'auto',  // Allows dropdown width to adjust dynamically
    minWidth: '100px', // Min width to ensure wide enough
    height: '50px', // Match the height of the search bar
    borderLeft: 'none', // Remove the border from the left side
    borderTop: 'none', // Remove the border from the top side
    borderBottom: 'none', // Remove the border from the bottom side
    borderRight: '1px solid #FFDFB1',
    "& .MuiSelect-root": {
      color: "#FF9500", // Font color to match the search bar border color
      width: '100%', // Adjust the width as needed
      height: '100%', // Match the height of the search bar
      display: 'flex',
      alignItems: 'center', // Vertically align the content
      justifyContent: 'center', // Horizontally center the content
      fontFamily: 'Rubik', // Adjust font family as needed
      fontWeight: '500', // Adjust font weight as needed
      fontSize: '18px', // Adjust font size as needed
      outline: 'none', // Remove the outline on focus
      textAlign: 'center', // Center-align the text horizontally
      paddingLeft: '8px', // Add left padding to shift the text to the right
    },
    "& .MuiSvgIcon-root": { color: "#FF9500" },
    "&:hover": {
      backgroundColor: 'transparent',
    },
}));
  

function SearchBarHero({ ...props }) {
  const [inputValue, setInputValue] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('Movies'); // Default category
  const userLocation = useContext(LocationContext);
  const [options, setOptions] = useState([]);
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const placeholderTexts = selectedCategory === 'Movies'
  ? ["Type movie titles here", "Try searching \"90s rom coms\"", "Try searching \"Best rated action movie\""]
  : ["Type TV show titles here", "Try searching \"Intriguing mystery shows to binge-watch\"", "Find your favorite TV shows"];

  const navigate = useNavigate();

  const handleInputChange = (event) => {
    window.scrollTo(0, 0);
    setInputValue(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();  // Prevent the default form submission behavior
    handleSearchClick();    // Call the search function
  };

  const handleSearchClick = () => {
    // if (inputValue && userLocation) {
    //   navigate(`/searchResults?query=${inputValue}&lat=${userLocation.latitude}&lng=${userLocation.longitude}&category=${selectedCategory}`);
    // removed location:
    if (inputValue) {
      const unifiedCategory = selectedCategory === 'Movies' ? MOVIE_TYPE : TV_TYPE; // TODO: reconsile selectedCategory to use MOVIE_TYPE and TV_TYPE
      navigate(`/searchResults?query=${inputValue}&category=${unifiedCategory}`);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setPlaceholderIndex(prevIndex => (prevIndex + 1) % placeholderTexts.length);
    }, 4000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <CenteredSearch>
      <SearchContainer >
        
        <Search {...props}>
          <SearchContent >
            {/* Category selection dropdown */}
            <CustomSelect
              value={selectedCategory}
              onChange={handleCategoryChange}
              style={{ fontFamily: 'Rubik', fontWeight: '500', fontSize: '15px', color: '#FF9500', }}
            >
              {/* <MenuItem value="Restaurant" 
              style={{ color: '#FF9500', fontWeight: '500', fontFamily: 'Rubik' }}>Restaurant</MenuItem> */}
              <MenuItem value="Movies" 
              style={{ color: '#FF9500', fontWeight: '500', fontFamily: 'Rubik' }}>Movies</MenuItem>
              <MenuItem value="TVs" 
              style={{ color: '#FF9500', fontWeight: '500', fontFamily: 'Rubik' }}>TVs</MenuItem>
            </CustomSelect>
            <form onSubmit={handleSubmit} style={{ margin: 0, display: 'flex', flexGrow: 1 }}>
              <StyledInputBase
                inputProps={{ "aria-label": "search", placeholder: placeholderTexts[placeholderIndex] }}
                value={inputValue}
                onChange={handleInputChange}
                style={{ fontFamily: 'Rubik', fontWeight: '500', fontSize: '15px' }}
              />
            <Button className="mt-0.5 mb-0.5"
              type="submit"
              variant="contained"
            ><SearchIcon style={{fill: '#BDBDBD', fontSize: '28'}} /></Button>
            

            </form>
          </SearchContent>
        </Search>
        <Collapse in={Boolean(inputValue && options.length)}>
          <DropdownPaper elevation={3}>
            <List>
              {options.map((option, index) => (
                <ListItem key={index} button onClick={() => setInputValue(option.name)}>
                  <Box display="flex" justifyContent="flex-start" alignItems="center">
                    <Box display="flex" alignItems="center">
                      <Typography variant="body1" sx={{ fontFamily: 'Rubik', fontWeight: '500', fontSize: '20px' }}>
                        {option.name}
                      </Typography>
                      <Chip label={option.category} size="small" sx={{ marginLeft: '8px', fontFamily: 'Rubik', fontWeight: '500' }} />
                    </Box>
                  </Box>
                </ListItem>
              ))}
            </List>
          </DropdownPaper>
        </Collapse>
      </SearchContainer>
    </CenteredSearch>
  );
}

export default SearchBarHero;
