import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CloseIcon from "@mui/icons-material/Close";
import { deleteBookmark } from "src/firebase/fireBaseInit";
import { fetchList } from "src/redux/listsSlice";
import { Button } from "src/components/ui/button";

const UserList = () => {
  const [list, setList] = useState([]);
  const { username } = useSelector((state) => state.user.user);
  const {
    like_tvs,
    like_movies,
    dislike_tvs,
    dislike_movies,
    wishlist_tvs,
    wishlist_movies,
    favorite_tvs,
    favorite_movies,
    seen_movies,
    seen_tvs,
  } = useSelector((state) => state.lists);
  const { type, listName, uid } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const listMappings = {
      tvs: {
        likes: like_tvs,
        dislikes: dislike_tvs,
        "watch list": wishlist_tvs,
        favorites: favorite_tvs,
        seen: seen_tvs,
      },
      movies: {
        likes: like_movies,
        dislikes: dislike_movies,
        "watch list": wishlist_movies,
        favorites: favorite_movies,
        seen: seen_movies,
      },
    };

    const fetchedList = listMappings[type]?.[listName] || [];

    setList(fetchedList);
  }, [
    listName,
    type,
    list,
    like_tvs,
    like_movies,
    dislike_tvs,
    dislike_movies,
    wishlist_tvs,
    wishlist_movies,
    favorite_tvs,
    favorite_movies,
    seen_movies,
    seen_tvs,
  ]);

  const handleCardClick = (id) => {
    let path;
    if (type === "movies") {
      path = `/MovieDetail/${id}`;
    } else if (type === "tvs") {
      path = `/TVDetail/${id}`;
    } else {
      return null;
    }
    navigate(path);
  };

  const handleDeleteBtn = async (card) => {
    const listType =
      listName === "watch list"
        ? "wishlist"
        : listName === "seen"
        ? "seen"
        : listName.slice(0, -1);
    const contentType = type.slice(0, -1);
    await deleteBookmark({
      type: contentType,
      id: card.id,
      listType,
      uid: uid,
    });

    await dispatch(
      fetchList({
        user: uid,
        listName: `${listType}_${type}`,
      })
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className={`pb-8 ${
        list?.length > 4 ? "h-auto" : "h-screen"
      } bg-orange-100`}
    >
      <div className="user-list-header flex items-center p-4 py-6 mx-2">
        <ArrowBackIosIcon onClick={() => navigate(-1)} />
        {username && (
          <div className="flex w-full justify-center font-semibold text-rubik-sans mr-6">
            {username}'s{" "}
            {listName == "watch list"
              ? "Watch"
              : listName.charAt(0).toUpperCase() + listName.slice(1)}{" "}
            List
          </div>
        )}
      </div>
      <div className="flex flex-wrap justify-start">
        {list &&
          list.map((card) => (
            <div className="flex flex-col w-1/2 tablet:w-1/4 lg:w-1/6 p-4">
              <div className="relative">
                <Button
                  onClick={() => handleDeleteBtn(card)}
                  className="absolute top-2 right-2 z-10 p-1"
                  variant="destructive"
                >
                  <CloseIcon />
                </Button>
                <img
                  className="w-full rounded-lg transition-transform transform hover:scale-105"
                  src={card.cover_photo_url || card.imageUrl}
                  alt="Card"
                  onClick={() => handleCardClick(card.id)}
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default UserList;
