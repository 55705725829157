import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchMovies } from "../../redux/moviesSlice";
import ListingCard from "./ListingCard";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { fetchTVShowData } from "src/redux/tvSlice";

const Listings = ({ useSearchResultFormat = false, type }) => {
  const [lastDocument, setLastDocument] = useState(null);
  const [cards, setCards] = useState();

  const { movieCards, movieIsLoading, movieCategories } = useSelector(
    (state) => state.moviesListing
  );
  const { tvCards, tvIsLoading, tvCategories } = useSelector(
    (state) => state.tvListing
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isTablet = window.innerWidth >= 768;

  useEffect(() => {
    if (!useSearchResultFormat) {
      if (type === "movies") setCards(movieCards);
      dispatch(fetchMovies({ movieCategories, lastDoc: null }));
      if (type === "tvs") setCards(tvCards);
      dispatch(fetchTVShowData({ tvCategories, lastDoc: null }));
    }
  }, [type, dispatch]);

  const handleScroll = useCallback(() => {
    const threshold = window.innerWidth >= 768 ? 100 : 50;
    const scrollPosition =
      window.scrollY ||
      document.documentElement.scrollTop ||
      document.body.scrollTop;
    if (
      window.innerHeight + scrollPosition + threshold <=
        document.documentElement.offsetHeight ||
      movieIsLoading ||
      tvIsLoading || useSearchResultFormat
    )
      return;
    if (type === "movies")
      dispatch(fetchMovies({ movieCategories: null, lastDoc: lastDocument }));
    if (type === "tvs")
      dispatch(fetchTVShowData({ tvCategories: null, lastDoc: lastDocument }));
  }, [dispatch, movieIsLoading, tvIsLoading, lastDocument, useSearchResultFormat]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    if (type === "movies") {
      if (movieCards.length && !lastDocument) {
        setLastDocument(movieCards[movieCards.length - 1].docID);

        // If all movieCards are rendered then disable scroll
        if (movieCards.length < 20) {
          window.removeEventListener("scroll", handleScroll);
        }
      }
    } else if (type === "tvs") {
      if (tvCards.length && !lastDocument) {
        setLastDocument(tvCards[tvCards.length - 1].docID);

        // If all tvCards are rendered then disable scroll
        if (tvCards.length < 20) {
          window.removeEventListener("scroll", handleScroll);
        }
      }
    }
  }, [movieCards, tvCards, lastDocument]);

  const handleCardClick = (id) => {
    let path;
    if (type === "movies") {
      path = `/MovieDetail/${id}`;
    } else if (type === "tvs") {
      path = `/TVDetail/${id}`;
    } else {
      return null;
    }
    navigate(path);
  };

  const renderCards = (cards, type) => {
    return (
      cards &&
      cards.length > 0 && (
        <div className="flex flex-wrap justify-center">
          {cards.map((card, index) => (
            <div className="flex flex-col w-1/2 tablet:w-full p-4 relative">
              <div className="mobile-card relative">
                <img
                  className="mobile-card w-full tablet:w-1/2 rounded-lg transition-transform transform hover:scale-105 tablet:hidden min-h-[460px] max-h-[460px] mobile:min-h-[230px] moble:max-h-[230px]"
                  src={card.cover_photo_url || card.imageUrl}
                  alt="Card"
                  onClick={() => handleCardClick(card.id ? card.id : card.docID)}
                />
              </div>
              <div className="mobile:hidden tablet:flex">
                <ListingCard
                  docID={card.docID || card.id}
                  title={card.title || card.name}
                  description={card.overview || card.description}
                  categories={
                    card.type === "movies"
                      ? card.movieCategories || card.genres
                      : card.tvCategories || card.genres
                  }
                  year={card.year ? card.year : "N/A"}
                  duration={card.duration}
                  imageUrl={card.cover_photo_url || card.imageUrl}
                  ratings={card.ratings}
                  type={card.type}
                  seasons={card.seasons || card.number_of_seasons}
                  useSearchResultFormat={useSearchResultFormat}
                />
              </div>
            </div>
          ))}
        </div>
      )
    );
  };

  const Loading = ({ isLoading }) => {
    return (
      isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          <CircularProgress style={{ color: "#FFA500" }} />
        </div>
      )
    );
  };

  return (
    <div style={{ width: "100%", overflow: "auto", position: "relative" }}>
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        {type === "movies" && renderCards(movieCards, "Movies")}
        {type === "tvs" && renderCards(tvCards, "TVs")}

        {type === "movies" && <Loading isLoading={movieIsLoading} />}
        {type === "tvs" && <Loading isLoading={tvIsLoading} />}
      </div>
    </div>
  );
};

export default Listings;
