import React from 'react';
import { Button, ButtonGroup, Box, Typography } from '@mui/material';
import '@fontsource/rubik/400.css';
import '@fontsource/rubik/500.css';

const Menu = (props) => {
    const [sortBy, setSortBy] = React.useState('Recommended');
    const [distance, setDistance] = React.useState('2mi');
    const [price, setPrice] = React.useState('');
    const [categories, setCategory] = React.useState('');
    const [showAdditionalCategories, setShowAdditionalCategories] = React.useState(false);


    const handleSortBy = (event) => {
        setSortBy(event.target.value);
        if (props.onSortChange) {
            props.onSortChange(event.target.value);
        }
    };

    const handleFilters = (type, value) => {
        if (type === 'distance') {
            setDistance(value);
        } else if (type === 'price') {
            setPrice(value);
        }

        if (props.onFilterChange) {
            props.onFilterChange({ type, value });
        }
    };

    const handleCategory = (value) => {
        console.log("Category clicked:", value);  // <-- Add this log
        setCategory(value); 
        if (props.onCategoryChange) {
            console.log("Triggering onCategoryChange with value:", value);  // <-- Add this log
            props.onCategoryChange(value);
        }
    };
    

    const categoryList = ['Chinese', 'Japanese', 'Italian', 'New American', 'Vietnamese', 'Vegetarian'];
    const additionalCategories = ['Mexican', 'Thai', 'Pizza', 'Burgers', 'Breakfast', 'Steakhouses', 'Seafood', 'Korean', 'Sandwiches', 'Sushi Bars'];

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 2,
                width: '292px',
                height: '100vh',
                overflowY: 'auto',
                padding: '50px 40px',
                backgroundColor: 'white',
            }}
        >

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 2,
                    width: '100%',
                }}
            >
                <Typography variant="h6" sx={{ alignSelf: 'flex-start', fontSize: '16px', fontFamily: 'Rubik', fontWeight: '500' }}>Distance</Typography>
                <ButtonGroup variant="outlined" color="primary" sx={{ width: '220px', fontSize: '13px', margin: 'auto', fontFamily: 'Rubik' }}>
                    <Button onClick={() => handleFilters('distance', '0.3mi')} 
                    sx={{ textTransform: 'lowercase', borderRadius: '20px', fontFamily: 'Rubik', fontWeight: '500', 
                    borderColor: distance === '0.3mi' ? '#FF9502' : '#E0E0E0', color: 'black', 
                    backgroundColor: distance === '0.3mi' ? '#FFF3E0' : 'transparent',
                    "&:hover": { backgroundColor: "#FFF3E0", borderColor: '#FF9502', borderWidth: '2px' }, }}>0.3mi</Button>
                    <Button onClick={() => handleFilters('distance', '1mi')} 
                    sx={{ textTransform: 'lowercase', borderRadius: '12px', fontFamily: 'Rubik', fontWeight: '500', 
                    borderColor: distance === '1mi' ? '#FF9502' : '#E0E0E0', color: 'black', 
                    backgroundColor: distance === '1mi' ? '#FFF3E0' : 'transparent',
                    "&:hover": { backgroundColor: "#FFF3E0", borderColor: '#FF9502', borderWidth: '2px' }, }}>1mi</Button>
                    <Button onClick={() => handleFilters('distance', '5mi')} 
                    sx={{ textTransform: 'lowercase', borderRadius: '12px', fontFamily: 'Rubik', fontWeight: '500', 
                    borderColor: distance === '5mi' ? '#FF9502' : '#E0E0E0', color: 'black', 
                    backgroundColor: distance === '5mi' ? '#FFF3E0' : 'transparent',
                    "&:hover": { backgroundColor: "#FFF3E0", borderColor: '#FF9502', borderWidth: '2px' }, }}>5mi</Button>
                    <Button onClick={() => handleFilters('distance', '20mi')} 
                    sx={{ textTransform: 'lowercase', borderRadius: '20px', fontFamily: 'Rubik', fontWeight: '500', 
                    borderColor: distance === '20mi' ? '#FF9502' : '#E0E0E0', color: 'black', 
                    backgroundColor: distance === '20mi' ? '#FFF3E0' : 'transparent',
                    "&:hover": { backgroundColor: "#FFF3E0", borderColor: '#FF9502', borderWidth: '2px' }, }}>20mi</Button>
                </ButtonGroup>
                <Typography variant="h6" sx={{ alignSelf: 'flex-start', fontSize: '16px', fontFamily: 'Rubik', fontWeight: '500' }}>Price</Typography>
                <ButtonGroup variant="outlined" color="primary" sx={{ width: '220px', fontSize: '13px', margin: 'auto', fontFamily: 'Rubik' }}>
                    <Button onClick={() => handleFilters('price', '$')} 
                    sx={{ borderRadius: '20px', fontFamily: 'Rubik', fontWeight: '500', 
                    borderColor: price === '$' ? '#FF9502' : '#E0E0E0', color: 'black', 
                    backgroundColor: price === '$' ? '#FFF3E0' : 'transparent',
                    "&:hover": { backgroundColor: "#FFF3E0", borderColor: '#FF9502', borderWidth: '2px' }, }}>$</Button>
                    <Button onClick={() => handleFilters('price', '$$')} 
                    sx={{ borderRadius: '12px', fontFamily: 'Rubik', fontWeight: '500', 
                    borderColor: price === '$$' ? '#FF9502' : '#E0E0E0', color: 'black', 
                    backgroundColor: price === '$$' ? '#FFF3E0' : 'transparent',
                    "&:hover": { backgroundColor: "#FFF3E0", borderColor: '#FF9502', borderWidth: '2px' }, }}>$$</Button>
                    <Button onClick={() => handleFilters('price', '$$$')} 
                    sx={{ borderRadius: '12px', fontFamily: 'Rubik', fontWeight: '500', 
                    borderColor: price === '$$$' ? '#FF9502' : '#E0E0E0', color: 'black', 
                    backgroundColor: price === '$$$' ? '#FFF3E0' : 'transparent',
                    "&:hover": { backgroundColor: "#FFF3E0", borderColor: '#FF9502', borderWidth: '2px' }, }}>$$$</Button>
                    <Button onClick={() => handleFilters('price', '$$$$')} 
                    sx={{ borderRadius: '20px', fontFamily: 'Rubik', fontWeight: '500', 
                    borderColor: price === '$$$$' ? '#FF9502' : '#E0E0E0', color: 'black', 
                    backgroundColor: price === '$$$$' ? '#FFF3E0' : 'transparent',
                    "&:hover": { backgroundColor: "#FFF3E0", borderColor: '#FF9502', borderWidth: '2px' }, }}>$$$$</Button>
                </ButtonGroup>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        gap: 2,
                        width: '100%',
                        marginTop: '0px',
                    }}
                >
                    <Typography variant="h6" sx={{ gridColumn: 'span 2', alignSelf: 'flex-start', fontSize: '16px', fontFamily: 'Rubik', fontWeight: '500' }}>Category</Typography>
                    {categoryList.map((cat, index) => (
                        <Button
                            key={index}
                            variant="outlined"
                            onClick={() => handleCategory(cat)}
                            sx={{
                                textTransform: 'capitalize',
                                height: '40px',
                                borderRadius: '20px',
                                borderWidth: '2.5px',
                                borderColor: categories === cat ? '#FF9502' : '#E0E0E0',
                                color: 'black',
                                backgroundColor: categories === cat ? '#FFF3E0' : 'transparent', 
                                fontSize: '13px',
                                fontFamily: 'Rubik',
                                fontWeight: '500',
                                "&:hover": {
                                  backgroundColor: "#FFF3E0",
                                  borderColor:"#FF9502",
                                  borderWidth: '3.5px',
                                },
                            }}
                        >
                            {cat}
                        </Button>
                    ))}
                    <Button
                        variant="text"
                        onClick={() => setShowAdditionalCategories(!showAdditionalCategories)}
                        sx={{
                            marginLeft: '80px',
                            marginRight: '-80px',
                            textTransform: 'capitalize',
                            height: '40px',
                            borderRadius: '20px',
                            borderWidth: '2.5px',
                            borderColor: 'transparent',
                            color: '#9E9E9E',
                            backgroundColor: 'transparent',
                            fontSize: '16px',
                            fontFamily: 'Rubik',
                            fontWeight: '500',
                            display: showAdditionalCategories ? 'none' : 'block',
                            "&:hover": {
                              backgroundColor: "#FFF3E0",
                            },
                        }}
                    >
                        Show All
                    </Button>
                    {showAdditionalCategories && additionalCategories.map((cat, index) => (
                        <Button
                            key={index}
                            variant="outlined"
                            onClick={() => handleCategory(cat)}
                            sx={{
                                textTransform: 'capitalize',
                                height: '40px',
                                borderRadius: '20px',
                                borderWidth: '2.5px',
                                borderColor: categories === cat ? '#FF9502' : '#E0E0E0',
                                color: 'black',
                                backgroundColor: categories === cat ? '#FFF3E0' : 'transparent',
                                fontSize: '13px',
                                fontFamily: 'Rubik',
                                fontWeight: '500',
                                "&:hover": {
                                  backgroundColor: "#FFF3E0",
                                  borderColor:"#FF9502",
                                  borderWidth: '3.5px',
                                },
                            }}
                        >
                            {cat}
                        </Button>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default Menu;
