import React, { useState, useEffect } from "react";
import {
  signInWithEmailAndPassword,
  auth,
  signInWithPopup,
  GoogleAuthProvider,
  migrateUser,
} from "../../firebase/fireBaseInit";
import { useNavigate } from "react-router-dom";
import "@fontsource/rubik/400.css";
import "@fontsource/rubik/500.css";
import "@fontsource/rubik/700.css";
import "./SignInSignUp.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  loginPending,
  loginFulfilled,
  loginRejected,
} from "../../redux/userSlice";
import { CircularProgress, Button as ButtonMUI, Divider } from "@mui/material";
import { doc, getDoc, db } from "../../firebase/fireBaseInit";
import { Google as GoogleIcon } from "@mui/icons-material";
import { FcGoogle } from "react-icons/fc";

const SignInForm = ({ isModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const validateEmail = (email) => {
    var re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    validateEmail(e.target.value)
      ? setEmailError("")
      : setEmailError("Invalid email format");
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    validatePassword(e.target.value)
      ? setPasswordError("")
      : setPasswordError("Password should be at least 8 characters");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (emailError || passwordError || !email || !password) {
      alert("Please check your email and password combination.");
      return;
    }

    setIsLoading(true); // Start loading
    // dispatch(loginPending()); // Dispatch the loginPending action when sign-in starts

    try {
      if (process.env.NODE_ENV === "development") {
        console.log("[SignInForm] Before uid: ", auth.currentUser?.uid);
      }
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      if (process.env.NODE_ENV === "development") {
        console.log("[SignInForm] After uid: ", auth.currentUser?.uid);
      }
      await processCredentialsOnSignIn(userCredential);
    } catch (error) {
      console.error("Error signing in:", error);
      dispatch(loginRejected(error.message)); // Dispatch the loginRejected action with the error message when sign-in fails
      setIsLoading(false);
      alert("Failed to sign in. Please check your email and password.");
    }
  };

  const handleGoogleSignin = async (e) => {
    e.preventDefault();
    const beforeUid = auth.currentUser?.uid;
    const provider = new GoogleAuthProvider();
    if (process.env.NODE_ENV === "development") {
      console.log("[SignInForm] Before uid: ", auth.currentUser?.uid);
    }

    try {
      const userCredential = await signInWithPopup(auth, provider);
      if (process.env.NODE_ENV === "development") {
        console.log(`[SignInForm] userCredential: ${userCredential}`);
      }
      // const cred = GoogleAuthProvider.credentialFromResult(result);
      // console.log(`IsNewUser ${result._tokenResponse.isNewUser}`);
      const isNewUser = userCredential._tokenResponse.isNewUser;
      if (isNewUser) {
        const afterUid = auth.currentUser?.uid;
        migrateUser({ old_uid: beforeUid, new_uid: afterUid })
          .then(() => {
            if (process.env.NODE_ENV === "development") {
              console.log(
                `User migration successful. beforeUid: ${beforeUid}, afterUid: ${afterUid}`
              );
            }
          })
          .catch((error) => {
            if (process.env.NODE_ENV === "development") {
              console.error("Error migrating user: ", error);
            }
          });
        try {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "signUpComplete",
            userId: userCredential.user.uid,
            userEmail: userCredential.user.email,
            username: userCredential.user.displayName,
          });
        } catch (error) {
          console.error("Error pushing to dataLayer:", error);
        }
      }
      processCredentialsOnSignIn(userCredential);
    } catch (error) {
      if (
        error.code === "auth/popup-closed-by-user" ||
        error.code === "auth/cancelled-popup-request"
      ) {
        setIsLoading(false);
        return;
      }
      console.error("Error signing in with google:", error);
      dispatch(loginRejected(error.message)); // Dispatch the loginRejected action with the error message when sign-in fails
      setIsLoading(false);
      alert(
        "Failed to sign in. Please try again or sign up via email and password."
      );
    }
  };

  useEffect(() => {
    if (!isModal) {
      window.scrollTo(0, 0);
    }
  }, []);

  async function processCredentialsOnSignIn(userCredential) {
    const user = userCredential.user;
    const userRef = doc(db, "users", user.uid);
    const userSnap = await getDoc(userRef);
    let username = null;

    if (userSnap.exists()) {
      username = userSnap.data().username;
    }

    // Push a message to the data layer for GTM
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "signInComplete",
      userId: user.uid,
      userEmail: user.email,
      username: user.displayName,
    });

    // Now dispatch the loginFulfilled action with the user data AND the fetched username
    dispatch(
      loginFulfilled({
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL,
      })
    );

    if (process.env.NODE_ENV === "development") {
      console.log("User signed in:", user);
      console.log("displayName", user.displayName);
    }

    setTimeout(() => {
      setIsLoading(false);
      navigate("/"); // Redirect to homepage after 2 seconds
    }, 500);
  }

  return (
    <div className={isModal ? "form-wrapper w-auto h-auto" : "form-wrapper"}>
      <form
        className={isModal ? "border-none p-6" : "form"}
        onSubmit={handleSubmit}
      >
        <div>
          <h2 className="form-title">Sign In</h2>
          <p className="form-subtitle">Welcome back!</p>
          <label className="form-input-label" htmlFor="email">
            Email
          </label>
          <input
            className="form-input"
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={handleEmailChange}
          />

          <label className="form-input-label" htmlFor="password">
            Password
          </label>
          <input
            className="form-input short"
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={handlePasswordChange}
          />
        </div>

        <div className="form-forgot-password-wrapper">
          <button
            className="form-forgot-password-button"
            onClick={() => {
              navigate("/forgot");
            }}
            type="button"
          >
            FORGOT PASSWORD?
          </button>
        </div>
        <div className="button-wrapper">
          {isLoading ? (
            <CircularProgress style={{ color: "#FFA500" }} />
          ) : (
            <button className="button" type="submit">
              Sign In
            </button>
          )}
        </div>
        <Divider sx={{ my: 2 }}>OR</Divider>
        <ButtonMUI
          variant="outlined"
          color="simplyblack"
          onClick={handleGoogleSignin}
          sx={{ display: "flex", margin: "0 auto", borderRadius: 8 }}
        >
          <FcGoogle size={28} style={{ marginRight: 8 }} /> Continue with Google
        </ButtonMUI>
      </form>

      <div
        className={isModal ? "alt-account-wrapper mt-0" : "alt-account-wrapper"}
      >
        <p className="black-text">Don't have an account?</p>
        <button
          className="button-text"
          type="button"
          onClick={() => {
            navigate("/Signup");
          }}
        >
          Sign up here!
        </button>
      </div>
    </div>
  );
};

export default SignInForm;
