export const MOVIE_TYPE = "movie";
export const TV_TYPE = "tv";
export const MOVIE_COLLECTION_NAME = "movies";
export const TV_COLLECTION_NAME = "TVs";

// type to collection name mapping
export const TYPE_DEFINITIONS = Object.freeze({
  [MOVIE_TYPE]: { collectionName: MOVIE_COLLECTION_NAME},
  [TV_TYPE]: { collectionName: TV_COLLECTION_NAME},
});
