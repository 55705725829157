import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addBookmarkFolder,
  getBookmarkContent,
} from "../firebase/fireBaseInit";

// Define the slice for user folders
const userFoldersSlice = createSlice({
  name: "userFolders",
  initialState: {
    folders: [], 

    folderDocs: [],
    folderDocCounts: {}, // Store document counts for each folder
  },
  reducers: {
    updateUserFolders: (state, action) => {
      state.folders = action.payload;
    },
    updateFolderDocCount: (state, action) => {
      const { folderName, count } = action.payload;
      state.folderDocCounts[folderName] = count;
    },
  
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFolderDocs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchFolderDocs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.folderDocs = action.payload;
      })
      .addCase(fetchFolderDocs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

// Create an asynchronous thunk for fetching user folders
export const fetchUserFolders = createAsyncThunk(
  "folders/fetchUserFolders",
  async (user, { dispatch }) => {
    try {
      if (user) {
        const response = await getBookmarkContent({
          uid: user.uid,
          listName: "like_tvs",
        });

        console.log(response.data);

        if (response && response.data && response.data.subfolders) {
          const subfolders = response.data.subfolders.map((folderPath) =>
            folderPath.slice(1)
          );
          // Dispatch an action to update the user folders in the Redux store
          dispatch(updateUserFolders(subfolders));

          // Fetch document counts for each folder
          subfolders.forEach(async (folderName) => {
            const docResponse = await getBookmarkContent({
              uid: user.uid,
              path: `/${folderName}`,
            });

            if (docResponse && docResponse.data && docResponse.data.docs) {
              const docCount = docResponse.data.docs.length;

              // Dispatch an action to update the folder document counts
              dispatch(updateFolderDocCount({ folderName, count: docCount }));
            }
          });
        }
      }
    } catch (error) {
      console.error("Error fetching folders:", error);
      throw error;
    }
  }
);

export const addFolder = createAsyncThunk(
  "folders/addFolder",
  async (newFolderName) => {
    if (newFolderName) {
      try {
        const path = "/";
        const result = await addBookmarkFolder({
          name: newFolderName,
          path: path,
        });

        if (result) {
          return newFolderName;
        }
      } catch (error) {
        console.error("Error adding folder:", error);
        throw error;
      }
    }
  }
);

export const fetchFolderDocs = createAsyncThunk(
  "folders/fetchFolderDocs",
  async ({ user, folderName }, { dispatch }) => {
    try {
      if (user && folderName) {
        const response = await getBookmarkContent({
          uid: user.uid,
          path: `/${folderName}`,
        });
        if (response && response.data && response.data.docs) {
          dispatch(
            updateFolderDocCount({
              folderName,
              count: response.data.docs.length,
            })
          );
          return response.data.docs;
        }
      }
    } catch (error) {
      console.error("Error fetching documents for folder:", error);
      throw error;
    }
  }
);

export const { updateUserFolders, updateFolderDocs, updateFolderDocCount } =
  userFoldersSlice.actions;

export default userFoldersSlice.reducer;
