import { getRecommendations } from "src/firebase/fireBaseInit";
import paywallplaceholder from "../Pics/paywallPlaceholder.svg";
import { MOVIE_TYPE, TV_TYPE } from "src/const";
const { createSlice, current, createAsyncThunk } = require("@reduxjs/toolkit");

const initialState = {
  movies: [],
  tvs: [],
  searchResults: [],
  movieRecommendations: [],
  tvRecommendations: [],
  pageNumber: 0,
  didSubmit: false,
  isSubmitDialogOpen: true,
  onboardingType: MOVIE_TYPE, 
  isNavShown: true,
  recommendationCards: [
    { favID: 0, id: "", img: paywallplaceholder, text: "" },
  ],
  isPaywallLoading: false,
};

export const fetchRecommendations = createAsyncThunk(
  "folders/fetchRecommendations",
  async (obj) => {
    try {
      const result = await getRecommendations(obj);
      return { ...result, onboardingType: obj.type };
    } catch (error) {
      console.error("Error getting recommendations:", error);
      throw error;
    }
  }
);

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    setRecommendations: (state, action) => {
      state.recommendationCards = [...action.payload];
    },
    setMovies: (state, action) => {
      state.movies = [...action.payload];
    },
    setTVs: (state, action) => {
      state.tvs = [...action.payload];
    },
    updateFavs: (state, action) => {
      const { index, data } = action.payload;
      if (data.type === MOVIE_TYPE) {
        const movieIndex = state.movies.findIndex(
          (movie) => movie.favID === index
        );
        if (movieIndex !== -1) {
          const arr = [...current(state.movies)];
          arr[movieIndex] = { favID: index, ...data };
          state.movies = [...arr];
        } else {
          console.error(`Movie with favID ${index} not found.`);
        }
      } else if (data.type === "TV") {
        const tvIndex = state.tvs.findIndex((tv) => tv.favID === index);
        if (tvIndex !== -1) {
          const arr = [...current(state.tvs)];
          arr[tvIndex] = { favID: index, ...data };
          state.tvs = [...arr];
        } else {
          console.error(`Movie with favID ${index} not found.`);
        }
      }
    },
    updateIntroRecommendations: (state, { payload }) => {
      const { index, data } = payload || {};
      const items = [...state.recommendationCards];
      items[index] = { favID: index, ...data };
      state.recommendationCards = [...items];
    },
    resetIntroRecommendations: (state) => {
      state.recommendationCards = [
        { favID: 0, id: "", img: paywallplaceholder, text: "" },
      ];
    },
    setSearchResults: (state, action) => {
      state.searchResults = action.payload;
    },
    setMovieRecommendations: (state, action) => {
      state.movieRecommendations = action.payload;
    },
    setTvRecommendations: (state, action) => {
      state.tvRecommendations = action.payload;
    },
    resetCards: (state, action) => {
      const { onboardingType } = action.payload;
      if (onboardingType === "movies") {
        state.movies = state.recommendationCards;
      } else {
        state.tvs = state.recommendationCards;
      }
    },
    handleRefreshClick: (state, action) => {
      state.pageNumber = action.payload;
    },
    setIsSubmitDialogOpen: (state, action) => {
      state.isSubmitDialogOpen = action.payload;
    },
    setDidSubmit: (state, { payload }) => {
      state.didSubmit = payload;
    },
    setOnboardingType: (state, { payload }) => {
      state.onboardingType = payload;
    },
    setIsNavShown: (state, action) => {
      state.isNavShown = action.payload;
    },
    addIntroRecommendation: (state) => {
      const introCardsLength = current(state.recommendationCards)?.length;
      state.recommendationCards = [
        ...state.recommendationCards,
        { favId: introCardsLength, id: "", img: paywallplaceholder, text: "" },
      ];
    },
    setIsPaywallLoading: (state, action) => {
      state.isPaywallLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchRecommendations.fulfilled,
      (state, { payload }) => {
        if (payload.data.length > 1) {
          if (payload?.onboardingType === MOVIE_TYPE) {
            state.movieRecommendations = payload.data;
          } else {
            state.tvRecommendations = payload.data;
          }
        } else {
          alert(
            "We encountered an error, please refresh the carousel again for more recommendations."
          );
        }
      }
    );
  },
});

export const {
  updateFavs,
  setSearchResults,
  setMovieRecommendations,
  setTvRecommendations,
  resetCards,
  handleRefreshClick,
  setIsSubmitDialogOpen,
  setDidSubmit,
  setOnboardingType,
  setMovies,
  setTVs,
  setIsNavShown,
  addIntroRecommendation,
  updateIntroRecommendations,
  setIsPaywallLoading,
  resetIntroRecommendations,
  setRecommendations,
} = onboardingSlice.actions;
export default onboardingSlice.reducer;
