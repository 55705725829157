import React, { useEffect, useState } from "react";
import { Button, TextField, Typography, Box, Slider } from "@mui/material";
import StarFull from "../logos/StarFull.png";
import StarHalf from "../logos/StarHalf.png";
import StarEmpty from "../logos/StarEmpty.png";
import "@fontsource/rubik/400.css";
import "@fontsource/rubik/500.css";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { addUserFeedback } from "../firebase/fireBaseInit";
import { ThemeProvider, styled } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";
import { db } from "../firebase/fireBaseInit";
import discordLogo from "../Pics/discord.svg";

const Feedback = ({ onClose }) => {
  const [rating, setRating] = useState(10);
  // const [hoverRating, setHoverRating] = useState(0);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [description, setDescription] = useState("");
  const [leadSource, setLeadSource] = useState("");
  const [listingFound, setListingFound] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const isTablet = window.innerWidth >= 768;

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async () => {
    if (!validateEmail(email)) {
      setEmailError("Must enter a valid email address!");
    } else {
      setEmailError("");
      setIsLoading(true);
      const timestamp = new Date().toISOString();

      try {
        // Call the addUserFeedback function
        console.log("Submitting feedback:", {
          description,
          email,
          leadSource,
          listingFound,
          rating,
          timestamp,
        });
        const feedbackId = await addUserFeedback({
          description: description,
          email: email,
          leadSource: leadSource,
          listingFound: listingFound,
          rating: rating,
          timestamp: timestamp,
        });
        console.log("Feedback submitted with ID:", feedbackId);
        setIsLoading(false);
        setIsSubmitted(true);
        // window.alert("Thank you for submitting your feedback!"); // TEMPORARY
        // onClose(); // Close the modal on successful submission
      } catch (error) {
        console.error("Error submitting feedback:", error);
        setIsLoading(false);
        window.alert("There was an error submitting feedback. Please try again later."); // TEMPORARY
        onClose(); // temporary; only close on successful submission
        // Optionally, set some state here to notify the user of the submission error
      }
    }
  };

  const handleFeedbackClick = (e) => {
    e.stopPropagation();
  };

  // const renderStar = (index) => {
  //     if (hoverRating >= index) {
  //         return <img src={StarFull} alt="star full" onMouseEnter={() => setHoverRating(index)} onClick={() => setRating(index)} style={{ cursor: 'pointer' }} />;
  //     } else if (hoverRating === index - 0.5) {
  //         return <img src={StarHalf} alt="star half" onMouseEnter={() => setHoverRating(index - 0.5)} onClick={() => setRating(index - 0.5)} style={{ cursor: 'pointer' }} />;
  //     } else {
  //         return <img src={StarEmpty} alt="star empty" onMouseEnter={() => setHoverRating(index)} onClick={() => setRating(index)} style={{ cursor: 'pointer' }} />;
  //     }
  // };

  const marks = [
    {
      value: 0,
      label: "0 Never",
    },
    {
      value: 10,
      label: "Very Likely 10",
    },
  ];

  function valuetext(value) {
    return `${value}`;
  }

  const sliderStyles = {
    color: "#FF9500",
    marginBottom: isTablet ? "35px" : "15px",
    "& .MuiSlider-track": {
      border: "none",
    },
    "& .MuiSlider-thumb": {
      height: 24,
      width: 24,
      backgroundColor: "#fff",
      border: "2px solid currentColor",
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },
      "&:before": {
        display: "none",
      },
    },
    "& .MuiSlider-valueLabel": {
      lineHeight: 1.2,
      fontSize: 14,
      background: "unset",
      padding: 0,
      width: 36,
      height: 28,
      backgroundColor: "#FFB54C",
      color: "#000000",
      fontWeight: "500",
      fontFamily: "Rubik",
    },
    '& .MuiSlider-markLabel[data-index="0"]': {
      fontSize: 14,
      fontWeight: "400",
      fontFamily: "Rubik",
      color: "#000000",
      transform: "translateX(0%)",
    },
    '& .MuiSlider-markLabel[data-index="1"]': {
      fontSize: 14,
      fontWeight: "400",
      fontFamily: "Rubik",
      color: "#000000",
      transform: "translateX(-100%)",
    },
  };

  return (
    <div onClick={handleFeedbackClick}>
    {isSubmitted ? (
      <>
      <Typography
        variant="h5"
        style={{
          textAlign: "center",
          marginTop: "20px",
          fontFamily: "Rubik",
          fontWeight: "500",
          backgroundColor: "#FFDFB1",
          padding: "10px",
          borderRadius: "8px",
        }}
      >
        Thanks for sharing your feedback!
      </Typography>
      <Typography
        variant="h6"
        style={{
          textAlign: "center",
          marginTop: "20px",
          fontFamily: "Rubik",
          fontWeight: "500",
        }}
      >
        Join our Discord community:
    </Typography>
    <Box display="flex" justifyContent="center" mt={2} onClick={() => window.open("https://discord.gg/pTESxW8P9u", "_blank")} style={{cursor: 'pointer'}}>
        <img src={discordLogo} alt="Discord" style={{ width: 80, height: 100, filter: 'brightness(0) saturate(100%) invert(63%) sepia(100%) saturate(267%) hue-rotate(345deg) brightness(100%) contrast(100%)' }} />
    </Box>
    </>
      ) : (
        <div>
          <div style={{ fontSize: "20px", fontWeight: "500", fontFamily: "Rubik", color: "#F57903"}}> 
            Share Feedback 
          </div>
          <Typography
            variant="subtitle1"
            style={{
              marginBottom: "10px",
              fontSize: "14px",
              fontWeight: "300",
              fontFamily: "Rubik",
            }}
          >
            Tell us about your experience with Cribit!
          </Typography>
          <Typography
            variant="subtitle1"
            style={{
              marginBottom: "10px",
              fontSize: "14px",
              fontWeight: "500",
              fontFamily: "Rubik",
            }}
          >
            Email<span style={{ color: "red" }}>*</span>
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!emailError}
            helperText={emailError}
            style={{ marginBottom: "10px" }}
            inputProps={{ style: { fontFamily: "Rubik", fontWeight: "400" } }}
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: "white",
                "& fieldset": {
                  borderColor: "lightgrey",
                },
                "& input": {
                  padding: "10px 14px",
                },
                "&:hover fieldset": {
                  borderColor: "grey",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "darkerGrey",
                },
              },
              "& .Mui-focused": {
                outlineColor: "darkerGrey",
              },
            }}
          />
          <Typography
            variant="subtitle1"
            style={{
              fontSize: "14px",
              fontWeight: "500",
              fontFamily: "Rubik",
              marginBottom: "0px",
              lineHeight: "1.3",
              marginTop: isTablet ? "20px" : "0px",
            }}
          >
            Did you discover any interesting movies or TV shows with Cribit?
            <span style={{ color: "red" }}>*</span>
          </Typography>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              defaultValue="top"
              value={listingFound}
              onChange={(e) => setListingFound(e.target.value)}
            >
              <FormControlLabel
                value="yes"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": { color: "#F57903" },
                      "& .MuiSvgIcon-root": { fontSize: "20px" },
                    }}
                  />
                }
                label={
                  <Typography
                    style={{
                      fontFamily: "Rubik",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    Yes
                  </Typography>
                }
              />
              <FormControlLabel
                value="no"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": { color: "#F57903" },
                      "& .MuiSvgIcon-root": { fontSize: "20px" },
                    }}
                  />
                }
                label={
                  <Typography
                    style={{
                      fontFamily: "Rubik",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    No
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
          <Typography
            variant="subtitle1"
            style={{
              marginTop: isTablet ? "20px" : "10px",
              marginBottom: isTablet ? "10px" : "0px",
              fontSize: "14px",
              fontWeight: "500",
              fontFamily: "Rubik",
              lineHeight: "1.3",
            }}
          >
            How likely are you to recommend Cribit to a friend or colleague?
            <span style={{ color: "red" }}>*</span>
          </Typography>
          <Slider
            aria-label="Always visible"
            defaultValue={10}
            getAriaValueText={valuetext}
            step={1}
            min={0}
            max={10}
            marks={marks}
            valueLabelDisplay="on"
            value={rating}
            sx={sliderStyles}
            onChange={(e) => setRating(e.target.value)}
          />

          <Typography
            variant="subtitle1"
            style={{
              marginBottom: "10px",
              fontSize: "14px",
              fontWeight: "500",
              fontFamily: "Rubik",
              lineHeight: "1.3",
            }}
          >
            What are the reasons behind the score you gave?
          </Typography>
          <TextField
            multiline
            rows={2}
            variant="outlined"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            inputProps={{ style: { fontFamily: "Rubik", fontWeight: "400" } }}
            style={{ marginBottom: isTablet ? "30px" : "10px", cursor: "text" }}
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: "white",
                "& fieldset": {
                  borderColor: "lightgrey",
                },
                "&:hover fieldset": {
                  borderColor: "grey",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "darkerGrey",
                },
                padding: "10px 14px",
                "& textarea": {
                  padding: "0",
                },
              },
              "& .Mui-focused": {
                outlineColor: "darkerGrey",
              },
              cursor: "text",
            }}
          />

          <Typography
            variant="subtitle1"
            style={{
              marginBottom: "10px",
              fontSize: "14px",
              fontWeight: "500",
              fontFamily: "Rubik",
            }}
          >
            How did you hear about us?
          </Typography>
          <TextField
            multiline
            rows={1}
            variant="outlined"
            value={leadSource}
            onChange={(e) => setLeadSource(e.target.value)}
            fullWidth
            style={{ marginBottom: isTablet ? "30px" : "10px", cursor: "text" }}
            inputProps={{ style: { fontFamily: "Rubik", fontWeight: "400" } }}
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: "white",
                "& fieldset": {
                  borderColor: "lightgrey",
                },
                "&:hover fieldset": {
                  borderColor: "grey",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "darkerGrey",
                },
                padding: "10px 14px",
                "& textarea": {
                  padding: "0",
                },
              },
              "& .Mui-focused": {
                outlineColor: "darkerGrey",
              },
              cursor: "text",
            }}
          />

          <Box display="flex" justifyContent="flex-end">
            {isLoading ? (
              <CircularProgress style={{ color: "#FFA500" }} />
            ) : (
              <Button
                variant="contained"
                style={{
                  backgroundColor: "orange",
                  color: "black",
                  borderRadius: "20px",
                  fontWeight: "500",
                  fontSize: "0.9rem",
                  fontFamily: "Rubik",
                }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            )}
          </Box>
        </div>
      )}
    </div>
  );
};

export default Feedback;
