import React from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { Button } from '@mui/material';
import { Google as GoogleIcon } from '@mui/icons-material';

const TestSignIn = () => {
  const provider = new GoogleAuthProvider();
  const auth = getAuth();

  const handleGoogleSignin = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        console.log('User signed in:', user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.error('Error during sign-in:', errorCode, errorMessage);
      });
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        startIcon={<GoogleIcon />}
        onClick={handleGoogleSignin}
      >
        Google Sign-In
      </Button>
      <h1>Test Movie Search Results</h1>
    </div>
  );
};

export default TestSignIn;
