import React, { createContext, useContext, useState } from 'react';

const CarouselContext = createContext();

export const useCarousel = () => {
  return useContext(CarouselContext);
};

export const CarouselProvider = ({ children }) => {
  const [carouselData, setCarouselData] = useState(null);

  return (
    <CarouselContext.Provider value={{ carouselData, setCarouselData }}>
      {children}
    </CarouselContext.Provider>
  );
};
