import React from "react";
import SearchBarHero from "../components/searchBar/SearchBarHero";
import { Box } from "@mui/material";
import "@fontsource/rubik/400.css";
import "@fontsource/rubik/500.css";
import Carousel from "src/components/listing/Carousel";
import RecommendationCarousel from "src/components/listing/RecommendationCarousel";

function Home() {
  // const navigate = useNavigate();

  // useEffect(() => {
  //   const isFirstVisit = localStorage.getItem('firstVisit') === null;

  //   if (isFirstVisit) {
  //     localStorage.setItem('firstVisit', 'false');
  //     navigate('/welcome');
  //   }

  // }, [navigate]);

  return (
    <div>
      <div>
        <SearchBarHero />
      </div>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: "#FFDFB1",
          paddingBottom: "5vh",
          // paddingTop: "5vh",
        }}
      >
        {/* <CarouselRestaurant /> */}
        <RecommendationCarousel />
        <Carousel type="movies" />
        <Carousel type="tvs" />
      </Box>
    </div>
  );
}

export default Home;
