import React from 'react';
import '@fontsource/rubik/400.css';
import '@fontsource/rubik/500.css';
import '@fontsource/rubik/700.css';
import StarFull from '../../logos/StarFull.png';
import StarHalf from '../../logos/StarHalf.png';
import StarEmpty from '../../logos/StarEmpty.png';

const StarRatingDisplayMovie = ({ rating, size = '32px' }) => {
  // Convert the rating (out of 10) to a percentage and round to two decimal places
  const percentageRating = (rating) ? Math.round(rating * 10 * 100) / 100 : 0;

  // Convert percentage rating to a 5-star scale
  const starRating = (percentageRating / 100) * 5;
  const fullStars = Math.floor(starRating);
  const hasHalfStar = starRating % 1 >= 0.5;
  const totalStars = 5;

  const renderStars = () => {
    let stars = [];
    for (let i = 0; i < totalStars; i++) {
      if (i < fullStars) {
        stars.push(<img src={StarFull} alt="Full Star" key={i} style={{ width: size, height: size }} />);
      } else if (i === fullStars && hasHalfStar) {
        stars.push(<img src={StarHalf} alt="Half Star" key={i} style={{ width: size, height: size }} />);
      } else {
        stars.push(<img src={StarEmpty} alt="Empty Star" key={i} style={{ width: size, height: size }} />);
      }
    }
    return stars;
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px', marginBottom: '0', color: '#E54D03', fontWeight: 'bold', fontFamily: 'Rubik' }}>
      {renderStars()}
      <span style={{ marginLeft: '4px' }}>{(rating && rating !== 0) ? `${percentageRating}%` : 'N/A'}</span>
    </div>
  );
};

export default StarRatingDisplayMovie;
