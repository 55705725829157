// geocoding.js

export async function getLatLngFromAddress(address) {
    console.log(`Geocoding address: ${address}`);
    // Use a geocoding service to convert the address to latitude and longitude.
    // For example, if you're using the Google Maps Geocoding API:

    const apiKey = "AIzaSyAXLk5gykIEfzjbl_3cuoBlYBaTM4Q92D8";
    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`);
    const data = await response.json();

    if (data.results && data.results.length > 0) {
        const location = data.results[0].geometry.location;
        return { latitude: location.lat, longitude: location.lng };
    } else {
        throw new Error("Unable to geocode address");
    }
}
